import React from "react";
import { FaStar } from "react-icons/fa";

export default function StarIcon(props) {
  return <FaStar size={props.size} {...props} />;
}

StarIcon.defaultProps = {
  size: 18,
};
