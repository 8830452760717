import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { RepresentativeInvoiceForm } from "@components/RepresentativeInvoice";

export default function RepresentativeInvoicesDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [representativeInvoice, setRepresentativeInvoice] = useState(populateRepresentativeInvoice());

  const representativeInvoiceId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/job-reimbursement-links/${representativeInvoiceId}/`)
      .then(({ data }) => {
        setLoading(false);
        setRepresentativeInvoice(populateRepresentativeInvoice(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!representativeInvoice) return null;

  return (
    <div>
      <PageTitle
        title={`${representativeInvoice.representative.full_name} on ${representativeInvoice.job.name ? representativeInvoice.job.name : 'Roofing Done Wright'}`}
        subtitle="Invoice"
      />
      <Wrapped>
				<p>
					<Link to="/representative-invoices"><strong>Reimbursements</strong></Link>
        </p>
        <RepresentativeInvoiceForm
          id={representativeInvoiceId}
          supplier={representativeInvoice.supplier}
          purpose={representativeInvoice.purpose}
          invoiceDate={representativeInvoice.invoiceDate}
          amountFormatted={representativeInvoice.amountFormatted}
          paid={representativeInvoice.paid}
          notes={representativeInvoice.notes}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateRepresentativeInvoice(d = {}) {
  return {
    representative: d.representative || "",
    job: d.job || "",
    supplier: d.supplier || "",
    purpose: d.purpose || "",
    invoiceDate: d.invoice_date || "",
    amountFormatted: d.amount_formatted || "",
    paid: d.paid,
    notes: d.notes || "",
  };
}
