import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Icon } from "semantic-ui-react";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { BasicTable } from "@components/shared";

export default function Bonuses({
  actionsDisabled,
  editing,
  representativeId,
  selected,
  onChange,
  fetchData,
	selectedBonuses,
	setSelectedBonuses,
	unpaidBonuses, 
	setUnpaidBonuses,
}) {
  const [loading, setLoading] = useState(false);

	useEffect(() => {
	  fetchBonuses();
	}, []);

  function fetchBonuses() {
    const req = {
      representative_id: representativeId,
      status: false,
    };

    setLoading(true);

    axios
      .post(`/retrieve-representative-bonuses/`, req)
      .then(({ data }) => {
				setUnpaidBonuses(data.data);
      })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }

  function onAddLinkToPaymentClick(bonusToAdd) {
		setSelectedBonuses(prevLinks => [...prevLinks, bonusToAdd]);
  }
	
	function onRemoveLinkToPaymentClick(bonusToRemove) {
	  setSelectedBonuses(prevLinks =>
	    prevLinks.filter(bonus => bonus !== bonusToRemove)
	  );
	}
	
  let columns = [
    {
      Header: "",
      width: 35,
      id: "inv",
      accessor: (d) => {
				const selected = selectedBonuses.includes(d.id);
 
        if (selected) {
            return (
              <Button
                icon="check"
                color="green"
                style={{ transform: "scale(0.7)" }}
                circular
                loading={loading}
                size="mini"
                title="Remove"
                onClick={() => onRemoveLinkToPaymentClick(d.id)}
              />
            );
        }

        if (!selected) {
          return (
            <Button
              icon="plus"
              style={{ transform: "scale(0.7)" }}
              circular
              basic
              loading={loading}
              size="mini"
              title="Add"
              onClick={() => onAddLinkToPaymentClick(d.id)}
            />
          );
        }

        return (
          <Form.Checkbox
            checked={selected.includes(d.id)}
            onChange={() => onChange(d.id, d)}
          />
        );
      },
    },
		{ Header: "Amount", accessor: "amount_formatted", width: 120 },
	  { Header: "Date", accessor: "date_formatted", width: 120 },
		{ Header: "Paid", 
	  accessor: (d) =>
	    d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
		id: "paid", width: 100
		},
	  { Header: "Notes", accessor: "notes", width: 120 },
  ];

  return (
    <div style={{ marginTop: "15px" }}>
      <BasicTable
        fetch={false}
        data={unpaidBonuses}
        loading={loading}
        columns={columns}
        entityName="Bonuses"
      />
    </div>
  );
}

Bonuses.defaultProps = {
  actionsDisabled: false,
  editing: false,
  selected: [],
};
