import React from "react";
import { Switch, Route } from "react-router-dom";

import { Wrapped } from "@components/Layouts";
import { PageTitle } from "@components/shared";

export default function ReportsIndex({ match, isAdmin }) {
  const QueryNav = () => (
    <PageTitle
      title="Queries"
      links={[
        { text: "My Queries", to: match.url },
        { text: "Create Query", to: `${match.url}/create` },
      ]}
    />
  );
  return (
    <React.Fragment>
      <Switch>
        <Route
          path={`${match.path}/:id`}
          render={(routeProps) => (
            <React.Fragment>
              <PageTitle title="Report" />

            </React.Fragment>
          )}
        />
      </Switch>
    </React.Fragment>
  );
}
