import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Wrapped } from "@components/Layouts";
import { Notes, PageTitle, ViewError } from "@components/shared";
import { RepresentativeBreakdown } from "@components/Representative";
import { ManagedJobTable } from "@components/Job";
import { ManagedPaymentTable } from "@components/AchPayment";
import { ManagedCommissionTable } from "@components/RepresentativePayment";
import { ManagedReimbursementTable } from "@components/RepresentativeInvoice";
import { ManagedDrawTable } from "@components/RepresentativeDraw";
import { getRoleInfo } from "@util/roleInfo";

export default function ManagedRepresentativesDetail({ isAdmin, match, history }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [representative, setRepresentative] = useState(populateRepresentative());

  const representativeId = match.params.id;
  const roleInfo = getRoleInfo(useSelector((state) => state));
	
	const managerId = useSelector((state) => state).representative.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/representatives/${representativeId}/`)
      .then(({ data }) => {
        setLoading(false);
        setRepresentative(populateRepresentative(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
	
	const manageAccessAllowed = representative.managerRepresentative && (representative.managerRepresentative.manager.id === managerId);
	
	if (!manageAccessAllowed) {
	  return <ViewError text="That is not allowed." />;
	}

	if (error) {
	  return <ViewError text={error || manageAccessAllowed} />;
	}




  if (!representative) return null;

  return (
    <div>
      <PageTitle
        title={representative.fullName}
        subtitle="Representative"
        links={[
          { text: "Detail", to: match.url },
					{ text: "Jobs", to: `${match.url}/jobs` },
					{ text: "Payments", to: `${match.url}/payments` },
					{ text: "Commissions", to: `${match.url}/commissions` },
					{ text: "Reimbursements", to: `${match.url}/reimbursements` },
					{ text: "Draws", to: `${match.url}/draws` },
        ]
          .filter((f) => (!isAdmin ? !f.admin : true))
          .map((l, i) => ({ ...l, key: i + 1 }))}
      />
			<Wrapped>
				<p>
					<Link to="/representatives"><strong>Representatives</strong></Link>
	      </p>
			</Wrapped>
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <Wrapped>
              <RepresentativeBreakdown representativeId={representativeId} />
            </Wrapped>
          </React.Fragment>
        </Route>
        <Route path={`${match.path}/jobs`}>
          <Wrapped>
            <ManagedJobTable representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/payments`}>
          <Wrapped>
            <ManagedPaymentTable representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route exact path={`${match.path}/commissions`}>
          <Wrapped>
            <ManagedCommissionTable representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/reimbursements`}>
          <Wrapped>
            <ManagedReimbursementTable representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/draws`}>
          <Wrapped>
            <ManagedDrawTable representativeId={representativeId} />
          </Wrapped>
        </Route>
      </Switch>
    </div>
  );
}

function populateRepresentative(d = {}) {
  return {
		managerLink: d.manager_representative || "",
		managedRepresentatives: d.managed_representatives,
		prefix: d.prefix || "",
    firstName: d.first_name || "",
    lastName: d.last_name || "",
    suffix: d.suffix || "",
    fullName: d.full_name || "",
		companyName: d.company_name || "",
    email: d.email || "",
    phone: d.phone || "",
    addressOne: d.address_1 || "",
    addressTwo: d.address_2 || "",
    city: d.city || "",
    state: d.state || "",
    overridePercentage: d.override_percentage,
    paidLeadPercentage: d.paid_lead_percentage,
    globalCommissionRatio: d.global_commission_ratio,
    manager: d.manager,
		managerRepresentative: d.manager_representative,
    managerPointsPercentage: d.manager_points_percentage,
    salary: d.salary,
    weeklySalaryFormatted: d.weekly_salary_formatted || "0",
    zipcode: d.zipcode || "",
    status: d.status,
    statusFormatted: d.status_formatted,
    notes: d.notes || "",
  };
}