import React, { useState } from "react";
import { Form, Icon } from "semantic-ui-react";
import { Button, FilterSelect, PaginatedTable } from "@components/shared";
import { size, device } from "@util/breakpoints";

import { statusOptions } from "./status";

export default function Table({ practiceId }) {
  const [activeFilter, setActiveFilter] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const columns = [
    {
      Header: "",
      id: "view",
      width: 50,
      accessor: (d) => <Button.View url={`/contractors/${d.id}`} />,
    },
    {
      Header: "Name",
      accessor: "name",
      id: "name",
      sortable: true,
      // width: 200,
    },
    {
      Header: "Email",
      accessor: "email",
      id: "email",
      sortable: false,
      // width: 200,
    },
    {
      Header: "Status",
      accessor: "status_formatted",
      id: "status_formatted",
      sortable: false,
    },
  ].map((m) => ({
    ...m,
    filterable: false,
  }));

  const additionalPostData = {
    status: JSON.parse(activeFilter),
    value: searchValue.length >= 2 ? searchValue : null,
  };

  return (
    <React.Fragment>
      <FilterSelect
        options={dropdownOptions}
        value={activeFilter}
        onChange={(e) => setActiveFilter(e)}
        entityName="Contractors"
      />
      <Form style={{ marginBottom: "1em" }}>
        <Form.Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search contractors with above filter setting..."
          width={window.innerWidth >= size.laptop ? 4 : 16}
        />
      </Form>
      <PaginatedTable
        columns={columns}
        additionalPostData={additionalPostData}
        entityName="Contractors"
        filter={activeFilter}
        filterOptions={filterOptions}
        keyName="data"
        refresh={refresh}
        url="/retrieve-contractors/"
      />
    </React.Fragment>
  );
}

Table.defaultProps = {};

const dropdownOptions = [
  { text: "All", value: JSON.stringify(statusOptions.map((s) => s.value)) },
  ...statusOptions,
];

const filterOptions = [{ name: "name", obj: "self" }];
