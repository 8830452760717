import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RepresentativeInvoiceForm, Table } from "@components/RepresentativeInvoice";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";
import { getRoleInfo } from "@util/roleInfo";

export default function RepresentativeInvoicesIndex({ history }) {
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <div>
      <Wrapped
        title="Reimbursement Requests"
        button={
          (roleInfo.admin_full || roleInfo.admin_partial) && (
            <Button.Toggle
              closedText="Add Reimbusrsment Request"
              onClick={() => setOpen(!isOpen)}
              open={isOpen}
            />
          )
        }
      >
        {isOpen ? <RepresentativeInvoiceForm onSuccess={() => setOpen(false)} /> : <Table />}
      </Wrapped>
    </div>
  );
}