import React, { useState } from "react";

import { Button, FilterSelect, PaginatedTable } from "@components/shared";
import { Icon } from "semantic-ui-react";

export default function RepresentativeTable() {
  const [activeFilter, setActiveFilter] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [updated, setUpdated] = useState(false);

  const columns = [
    {
      Header: "Representative",
      accessor: "representative.full_name",
      id: "representative_name",
      sortable: false,
    },
    {
      Header: "Job",
      accessor: d => d.job ? d.job.name : 'Roofing Done Wright',
      id: "job_name",
      sortable: false,
    },
    {
      Header: "Supplier",
      accessor: "supplier",
      id: "supplier",
      sortable: false,
    },
    {
      Header: "Purpose",
      accessor: "purpose",
      id: "purpose",
      sortable: false,
    },
    {
      Header: "Request Date",
      accessor: "invoice_date_formatted",
      id: "invoice_date_formatted",
      sortable: true,
      width: 120,
    },
    {
      Header: "Amount",
      accessor: "amount_formatted",
      id: "amount_formatted",
      sortable: false,
      width: 115,
    },
    {
      Header: "Paid",
      accessor: (d) =>
        d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
      id: "paid",
      sortable: false,
      width: 90,
    },
    {
      Header: "Job Name/Notes",
      accessor: "notes",
      id: "notes",
      sortable: false,
    },
  ].map((m) => ({
    ...m,
    sortable: false,
    filterable: false,
  }));

  const additionalPostData = {
    status: JSON.parse(activeFilter),
  };

  return (
    <React.Fragment>
      <FilterSelect
        options={statusOptions}
        value={activeFilter}
        onChange={(e) => setActiveFilter(e)}
        entityName="Status"
      />
      <PaginatedTable
        columns={columns}
        additionalPostData={additionalPostData}
        entityName="Reimbursement Requests"
        filter={activeFilter}
        filterOptions={filterOptions}
        keyName="data"
        refresh={refresh}
        url="/retrieve-representative-invoices-for-rep/"
      />
    </React.Fragment>
  );
}

RepresentativeTable.defaultProps = {};

const statusOptions = [
  { text: "All", value: `[false, true]` },
  { text: "Unpaid", value: false },
  { text: "Paid", value: true },
];

const filterOptions = [{ name: "name", obj: "self" }];
