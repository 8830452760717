import React from "react";
import { Link } from "react-router-dom";
import { TiEye } from "react-icons/ti";

export default function TableViewButton({ url, onClick }) {
  // Check if the url prop is provided
  if (url) {
    return (
      <Link to={url}>
        <TiEye size={24} className="action-icon" />
      </Link>
    );
  }

  // If the url prop is not provided but onClick is provided
  if (onClick) {
    return (
      <button onClick={onClick} style={{ border: "none", background: "none", padding: 0, cursor: "pointer", color: "#fb4c10" }}>
        <TiEye size={24} className="action-icon" />
      </button>
    );
  }

  // Fallback in case neither url nor onClick is provided
  return null;
}