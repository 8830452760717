import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Icon } from "semantic-ui-react";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { BasicTable } from "@components/shared";

export default function Corrections({
  actionsDisabled,
  editing,
  representativeId,
  selected,
  onChange,
  fetchData,
 	selectedCorrections,
  setSelectedCorrections,
 	unsettledCorrections,
 	setUnsettledCorrections,
}) {
  const [loading, setLoading] = useState(false);

	useEffect(() => {
	  fetchCorrections();
	}, []);

  function fetchCorrections() {
    const req = {
      representative_id: representativeId,
      status: false,
			ach_payment_id: false,
    };

    setLoading(true);

    axios
      .post(`/retrieve-representative-corrections/`, req)
      .then(({ data }) => {
				setUnsettledCorrections(data.data);
      })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }

  function onAddLinkToPaymentClick(correctionToAdd) {
		setSelectedCorrections(prevLinks => [...prevLinks, correctionToAdd]);
  }
	
	function onRemoveLinkToPaymentClick(correctionToRemove) {
	  setSelectedCorrections(prevLinks =>
	    prevLinks.filter(correction => correction !== correctionToRemove)
	  );
	}
	
  let columns = [
    {
      Header: "",
      width: 35,
      id: "inv",
      accessor: (d) => {
				const selected = selectedCorrections.includes(d.id);
 
        if (selected) {
            return (
              <Button
                icon="check"
                color="green"
                style={{ transform: "scale(0.7)" }}
                circular
                loading={loading}
                size="mini"
                title="Remove"
                onClick={() => onRemoveLinkToPaymentClick(d.id)}
              />
            );
        }

        if (!selected) {
          return (
            <Button
              icon="plus"
              style={{ transform: "scale(0.7)" }}
              circular
              basic
              loading={loading}
              size="mini"
              title="Add"
              onClick={() => onAddLinkToPaymentClick(d.id)}
            />
          );
        }

        return (
          <Form.Checkbox
            checked={selected.includes(d.id)}
            onChange={() => onChange(d.id, d)}
          />
        );
      },
    },
		{ Header: "Date Recorded", accessor: "date_formatted", width: 120 },
		{
		  Header: "Amount",
		  accessor: "amount_formatted",
		  width: 120,
		  Cell: ({ value }) => {
		    let numericValue = parseFloat(value.replace(/,/g, ''));
		    const formattedValue = numericValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		    return <span>{formattedValue}</span>;
		  }
		},
		{ Header: "Settled", 
	  accessor: (d) =>
	    d.settled ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
		id: "settled", width: 100
		},
	  { Header: "Notes", accessor: "notes" },
  ];

  return (
    <div style={{ marginTop: "15px" }}>
      <BasicTable
        fetch={false}
        data={unsettledCorrections}
        loading={loading}
        columns={columns}
        entityName="Corrections"
      />
    </div>
  );
}

Corrections.defaultProps = {
  actionsDisabled: false,
  editing: false,
  selected: [],
};
