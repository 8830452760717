import React, { useState } from "react";

import { Button, FilterSelect, PaginatedTable, BasicTable } from "@components/shared";
import { Icon } from "semantic-ui-react";

export default function Table({ representativeId, representativeInvoices, representativeView }) {
  const [activeFilter, setActiveFilter] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [updated, setUpdated] = useState(false);

  const columns = [
    {
      Header: "",
      id: "view",
      width: 50,
      accessor: (d) => <Button.View url={`/representative-reimbursements/${d.id}`} />,
    },
    {
      Header: "Representative",
      accessor: "representative.full_name",
      id: "representative_name",
      sortable: false,
    },
    {
      Header: "Date Paid",
      accessor: "date_paid_formatted",
      id: "date_paid_formatted",
      sortable: true,
      width: 120,
    },
    {
      Header: "Amount",
      accessor: "amount_formatted",
      id: "amount_formatted",
      sortable: true,
      width: 120,
    },
    {
      Header: "Posted to ACH",
      accessor: (d) =>
        d.posted_to_ach ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
      id: "posted_to_ach",
      sortable: false,
      width: 125,
    },
    {
      Header: "ACH Reference",
      accessor: "ach_reference",
      id: "ach_reference",
      sortable: false,
      width: 140,
    },
    {
      Header: "Notes",
      accessor: "notes",
      id: "notes",
      sortable: false,
      width: 140,
    },
    {
      Header: "Status",
      accessor: "status_formatted",
      id: "status_formatted",
      sortable: false,
      width: 125,
    },
  ].map((m) => ({
    ...m,
    filterable: false,
  })).filter((f) => 
    representativeView ? !["representative_name"].includes(f.id) : true
  );

  const additionalPostData = {
    status: JSON.parse(activeFilter),
  };

  return (
    <React.Fragment>
      {!representativeInvoices && (
        <React.Fragment>
          <FilterSelect
            options={statusOptions}
            value={activeFilter}
            onChange={(e) => setActiveFilter(e)}
            entityName="Status"
          />
          <PaginatedTable
            columns={columns}
            additionalPostData={additionalPostData}
            entityName="Reimbursements"
            filter={activeFilter}
            filterOptions={filterOptions}
            keyName="data"
            refresh={refresh}
            url="/representative-reimbursements-for-rep/"
          />
        </React.Fragment>
      )}
      {representativeInvoices && (
        <BasicTable
          columns={columns}
          data={representativeInvoices}
          entityName="Representative Invoices for Reimbursement"
        />
      )}

    </React.Fragment>
  );
}

Table.defaultProps = {};

const statusOptions = [
	{ text: "All", value: `[true, false]` },
  { text: "Unpaid", value: false },
  { text: "Paid", value: true },
];

const filterOptions = [{ name: "name", obj: "self" }];
