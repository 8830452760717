export const statusOptions = [
  { text: "Active", value: 0 },
  { text: "Inactive", value: 1 },
  { text: "Removed", value: 2 },
];

export const accessOptions = [
  { text: "Full Access", value: 0 },
  { text: "View Only", value: 2 },
];
