import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { BasicForm, Button } from "@components/shared";
import { RepresentativeSelect } from "@components/Representative";
import { getRoleInfo } from "@util/roleInfo";

const STEP_REPRESENTATIVE = "representer";
const STEP_ENTRY = "entry";

export default function JobRepresentativeForm({
  id,
  representativeFullName,
  jobId,
  jobName,
	jobType,
	jobTypeName,
  percentage,
  splitPercentage,
  notes,
  onSuccess,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(id ? STEP_ENTRY : STEP_REPRESENTATIVE);
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const roleInfo = getRoleInfo(useSelector((state) => state));

  function onRepresentativeSelect() {
    setActiveStep(STEP_ENTRY);
  }
  
  function onRepresentativeClear() {
    setActiveStep(STEP_REPRESENTATIVE);
    setSelectedRepresentative(null);
  }
  

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      representative_id: selectedRepresentative.representative.value,
      job_id: jobId,
			override_percentage: selectedRepresentative.representative.representativeOverridePercentage,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/job-knocking-links/`, req)
      .then(({ data }) => {
        return data.id;
      })
      .then(() => setLoading(false))
      .then(() => {
        toast.success("Job Knocking Added!");
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/job-knocking-links/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Job Knocking Updated!"))
      .then((newId) => {
        onSuccess(newId);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  const fields = [
    {
      name: "percentage",
      initialValue: percentage,
      label: "Commission %",
      required: true,
      schema: () =>
        Yup.string()
          .min(1, "Too Short!")
          .max(3, "Too Long!")
          .required("Required"),
    },
    {
      name: "split_percentage",
      initialValue: splitPercentage,
      label: "Split % (100 unless a commissioned rep)",
      required: true,
      schema: () =>
        Yup.string()
          .min(1, "Too Short!")
          .max(3, "Too Long!")
          .required("Required"),
    },
    {
      name: "notes",
      initialValue: notes,
      label: "Notes",
      required: false,
      schema: () => Yup.string().max(50, "Too Long!"),
    },
  ]
		.filter((f) => (!id ? f.name !== "split_percentage" : true))
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_view_only ? true : false,
    }));

  return (
    <div>
    {!id && activeStep === STEP_REPRESENTATIVE && (
      <React.Fragment>
        {selectedRepresentative && (
          <div style={{ display: "flex", marginBottom: "10px" }}>
              <strong>Representative: {selectedRepresentative.representative.name}</strong> 
              <Button.Clear
                person
                onClick={onRepresentativeClear}
              />
          </div>
            )}
        <div>
          <RepresentativeSelect onChange={(e) => setSelectedRepresentative(e)} />
        </div>
        <div>
          <Button.Basic
            primary
            text="Next Step"
            disabled={!selectedRepresentative}
            onClick={onRepresentativeSelect}
            style={{ margin: "10px 10px 10px 0" }}
          />
        </div>
      </React.Fragment>
    )}
    {activeStep === STEP_ENTRY && (
      <React.Fragment>
        <div>
          {selectedRepresentative && (
            <div style={{ display: "flex", marginBottom: "10px" }}>
                <strong>Representative: {selectedRepresentative.representative.name}</strong> 
                <Button.Clear
                  person
                  onClick={onRepresentativeClear}
                />
            </div>
              )}
            {!id && (
              <Button.Basic
              text="Back"
              onClick={() => setActiveStep(STEP_REPRESENTATIVE)}
              style={{ margin: "10px 10px 10px 0" }}
              />
            )}
          <BasicForm
            error={error}
            fields={fields}
            loading={isLoading}
            onSubmit={onSubmit}
            showButton={!id ? true : roleInfo.admin_full ? true : false}
          />
        </div>
      </React.Fragment>
    )}
    </div>
  );
}

JobRepresentativeForm.defaultProps = {
  id: null,
  representative: "",
  job: "",
  percentage: 1,
  splitPercentage: null,
  paid: "",
  notes: "",
  onSuccess: () => alert("On Success"),
};
