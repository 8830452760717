import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "semantic-ui-react";

export default function JobSelect({ onChange }) {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    const req = {
      status: [true,false],
    };

    axios.post(`/lookup-jobs/`, req).then(({ data }) => {
      setJobs(
        data.data.map((d) => ({
          key: d.id,
          text: d.name,
          value: d.id,
        }))
      );
      setLoading(false);
    });
  }

  const handleChange = (e, { value }) => {
    const selectedJob = jobs.find(c => c.value === value);
    if (selectedJob) {
      onChange({
        job: { name: selectedJob.text, value: selectedJob.value }
      });
    }
  };

  return (
    <div>
      <Form>
        <Form.Select
          placeholder="Select a job..."
          loading={loading}
          options={jobs}
          search
          onChange={handleChange}
        />
      </Form>
    </div>
  );
}

JobSelect.defaultProps = {
  selected: [],
};