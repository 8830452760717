import React, { useState, useEffect } from "react";
import axios from "axios";
import { Icon } from "semantic-ui-react";
import { Card } from "@components/shared";

export default function JobBreakdown({
  jobId,
}) {
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jobBreakdown, setJobBreakdown] = useState(populateJobBreakdown());
  
  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/job-breakdown/${jobId}/`)
      .then(({ data }) => {
        setLoading(false);
        setJobBreakdown(populateJobBreakdown(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
  
  return (
    <React.Fragment>
    <p><span style={{ color: "var(--secondaryColorDark)" }}>Green</span> = paid, <span style={{ color: "var(--negativeColor)" }}>Red</span> = unpaid</p>
      <Card.Wrapper>
        <Card.Meta
          title={`${jobBreakdown.name}`}
          {...(jobBreakdown.paid ? { secondary: true } : { tertiary: true })}
          stats={[
            { 
              title: "Job Total", 
              value: `$${parseFloat(jobBreakdown.jobTotalFormatted).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` 
            },
            { 
              title: "Total Cost", 
              value: `$${parseFloat(jobBreakdown.totalCostFormatted).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` 
            },
            { title: "Leap Job ID", value: jobBreakdown.leapJobId },
            { title: "Customer Payment Date", value: jobBreakdown.datePaidFormatted },
            { title: "Job Paid In Full", value: jobBreakdown.paid ? <Icon color="white" name="check" /> : <Icon color="white" name="x" /> },
            { title: "Job Type", value: jobBreakdown.jobTypeFormatted },
            { title: "Job Classification", value: jobBreakdown.jobClassificationFormatted },
						{ title: "Profit Margin %", value: jobBreakdown.profitMarginPercentage },
						{ 
							title: "Profit",
							value: `$${parseFloat(jobBreakdown.profitFormatted).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
						},
						{ title: "Squares", value: jobBreakdown.squares },
						{ 
							title: "Price Per Square",
							value: `$${parseFloat(jobBreakdown.pricePerSquareFormatted).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
						},
						{ 
							title: "Cost Per Square",
							value: `$${parseFloat(jobBreakdown.costPerSquareFormatted).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
						},
						{ title: "Above 25%?", value: jobBreakdown.no_bonus_warning ? <Icon color="white" name="check" /> : <Icon color="red" name="x" /> },
 					 	{ title: "Bonus To Be Paid", value: jobBreakdown.no_bonus ?  <Icon color="white" name="x" /> : <Icon color="white" name="check" /> },
						{ title: "No Bonus Reason", value: jobBreakdown.bonusReasonFromatted },
          ]}
          hasActions={false}
        />
      </Card.Wrapper>
      <h2>Representatives</h2>
      {jobBreakdown.jobPaymentLinks.length > 0 ? (
        jobBreakdown.jobPaymentLinks.map((jobPaymentLink, index) => (
          <Card.Wrapper key={index}>
            <Card.Meta
              title={`${jobPaymentLink.representative.full_name}`}
              {...(jobPaymentLink.paid ? { secondary: true } : { tertiary: true })}
              stats={[
                { title: "Override", value: `${jobPaymentLink.override_percentage}%` },
                { title: "Paid Lead", value: `${jobPaymentLink.paid_lead_percentage}%` },
                { title: "Split", value: `${jobPaymentLink.split_percentage}%` },
                { title: "Commission Ratio", value: jobPaymentLink.resulting_ratio_reason },
								{ 
									title: "Commission", 
									value: `$${parseFloat(jobPaymentLink.commission_formatted).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` 
								},
              ]}
              hasActions={false}
            />
          </Card.Wrapper>
        ))
      ) : (
        <div><p>No representatives associated with this job.</p></div>
      )}
      <h2>Reimbursements</h2>
      {jobBreakdown.jobReimbursementLinks.length > 0 ? (
        jobBreakdown.jobReimbursementLinks.map((jobReimbursementLink, index) => (
          <Card.Wrapper key={index}>
            <Card.Meta
              title={`${jobReimbursementLink.representative.full_name}`}
              {...(jobReimbursementLink.paid ? { secondary: true } : { tertiary: true })}
              stats={[
                { 
                  title: "Amount", 
                  value: `$${parseFloat(jobReimbursementLink.amount_formatted).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` 
                },
                { title: "Date", value: jobReimbursementLink.invoice_date_formatted },
                { title: "Paid", value: jobReimbursementLink.paid ? <Icon color="white" name="check" /> : <Icon color="white" name="x" />},
                { title: "Supplier", value: jobReimbursementLink.supplier },
                { title: "Purpose", value: jobReimbursementLink.purpose },
                { title: "Notes", value: jobReimbursementLink.notes },
              ]}
              hasActions={false}
            />
          </Card.Wrapper>
        ))
      ) : (
        <div><p>No reimbursements for this job.</p></div>
      )}
      <h2>Contractor Invoices</h2>
      {jobBreakdown.contractorJobLinks.length > 0 ? (
        jobBreakdown.contractorJobLinks.map((contractorJobLink, index) => (
          <Card.Wrapper key={index}>
            <Card.Meta
              title={`${contractorJobLink.contractor.name}`}
              {...(contractorJobLink.paid ? { secondary: true } : { tertiary: true })}
              stats={[
                { 
                  title: "Amount", 
                  value: `$${parseFloat(contractorJobLink.amount_formatted).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` 
                },
                { title: "Date", value: contractorJobLink.invoice_date_formatted },
                { title: "Paid", value: contractorJobLink.paid ? <Icon color="white" name="check" /> : <Icon color="white" name="x" /> },
                { title: "Invoice", value: contractorJobLink.invoice_number },
                { title: "Notes", value: contractorJobLink.notes },
              ]}
              hasActions={false}
            />
          </Card.Wrapper>
        ))
      ) : (
        <div><p>No contractor invoices for this job.</p></div>
      )}
    </React.Fragment>
  );
}

function populateJobBreakdown(d = {}) {
  return {
    name: d.name || "",
    leapJobId: d.leap_job_id || "",
    jobTypeFormatted: d.job_type_formatted,
    jobClassificationFormatted: d.job_classification_formatted,
    paidLead: d.paid_lead,
    noBonus: d.no_bonus,
		bonusReasonFromatted: d.bonus_reason_formatted,
    invoiceNumber: d.invoice_number || "",
    totalCostFormatted: d.total_cost_formatted || "",
    jobTotalFormatted: d.job_total_formatted || "",
    squares: d.squares || 0,
    paid: d.paid,
    datePaidFormatted: d.date_paid_formatted || "",
    postedToAch: d.posted_to_ach,
    achReference: d.ach_reference || "",
    status: d.status,
    notes: d.notes || "",
		profitFormatted: d.profit_formatted || "",
		profitMarginPercentage: d.profit_margin_percentage || "",
		pricePerSquareFormatted: d.price_per_square_formatted || "",
		costPerSquareFormatted: d.cost_per_square_formatted || "",
    jobPaymentLinks: d.job_payment_links || [],
    jobReimbursementLinks: d.job_reimbursement_links || [],
    contractorJobLinks: d.contractor_job_links || [],
  };
}
