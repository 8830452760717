import styled from "styled-components";

export const ValueBlock = styled.div`
  h1 {
    margin-bottom: 0;
  }
`;

export const ChartWrapper = styled.div`
  width: 100%;
`;

export const Title = styled.h4`
  background: #eee;
  /* border-bottom: 2px solid #eee; */
  padding: 9px 8px;
  text-transform: uppercase;
  /* color: #fff; */
  border-radius: 2px;
`;
