import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { BasicGrid, Card, Button, EmptyList } from "@components/shared";
import { ManagerInfo, RepresentativeManagerForm, RepresentativeManagerEditForm } from "@components/RepresentativeManager";

import { getRoleInfo } from "@util/roleInfo";

export default function RepresentativeManagerManagement({
  representativeId,
	managerLink,
  onUpdate,
	onSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const roleInfo = getRoleInfo(useSelector((state) => state));
	
  function handleSuccess() {
    window.location.reload();
  }

  return (
    <div>
	    {!managerLink && (
				<React.Fragment>
					<h3>Add A Manager</h3>
					<RepresentativeManagerForm representativeId={representativeId} managerLink={managerLink} onSuccess={handleSuccess} />
				</React.Fragment>
	    )}
      <ManagerInfo representativeId={representativeId} managerLink={managerLink} />
			<RepresentativeManagerEditForm managerLink={managerLink} onSuccess={handleSuccess} />
    </div>
  );
}