import React from "react";
import styled from "styled-components";
import { cssVar, lighten } from "polished";

const Item = styled.div`
  display: flex;
  position: relative;

  button {
    width: 100%;

    &:first-of-type {
      width: ${(props) => (props.viewOnly ? "100%" : "88%")};
      margin-right: 0;
      border-radius: ${(props) =>
        props.viewOnly ? `4px !important` : `4px 0 0 4px !important`};
      border: none;
    }

    + button {
      width: 15%;
      margin-left: 0;
      /* margin-left: 15px; */
      padding: 0 10px 0 15px;
      color: ${(props) =>
        props.hasNote
          ? "#fff"
          : `${lighten(0.25, cssVar("--primaryColorLight"))}`};
      /* background: rgba(0, 0, 0, 0.15); */
      background: var(--primaryColorLight);
      border-radius: 0 4px 4px 0;

      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

export default function OptionItem({ children, hasNote, viewOnly }) {
  return (
    <Item viewOnly={viewOnly} hasNote={hasNote}>
      {children}
    </Item>
  );
}

OptionItem.defaultProps = {
  hasNote: false,
  viewOnly: false,
};
