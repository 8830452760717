import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const LinkWrapper = styled(Link)`
  color: var(--primaryColorDark);
  font-weight: bold;
`;

export default function InlineLink({ to, text }) {
  if (!to || !text) return null;

  return <LinkWrapper to={to}>{text}</LinkWrapper>;
}

InlineLink.defaultProps = {
  to: "",
  text: "",
};
