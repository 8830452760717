export const SET_REPRESENTATIVE = 'SET_REPRESENTATIVE';

function representativeReducer(state, action) {
  switch (action.type) {
  case SET_REPRESENTATIVE:
    return {
      ...state,
      representative: action.payload,
    };
  default:
    return state;
  }

  return state;
}

export default representativeReducer;
