import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  AchDetail,
	AdminDetail,
  AdminIndex,
  Dashboard,
  JobsDetail,
  JobsIndex,
  JobRepresentativesDetail,
	JobKnockingsDetail,
	KnockingPaymentsDetail,
	KnockingPaymentsIndex,
	ManagerPaymentsDetail,
	ManagerPaymentsIndex,
  RepresentativesDetail,
  RepresentativesIndex,
  RepresentativeCorrectionsIndex,
  RepresentativeCorrectionsDetail,
  RepresentativeInvoicesDetail,
  RepresentativeInvoicesIndex,
  RepresentativeReimbursementsIndex,
  RepresentativeReimbursementsDetail,
  RepresentativePaymentsIndex,
  RepresentativePaymentsDetail,
  RepresentativeBonusesIndex,
  RepresentativeBonusesDetail,
	RepresentativeDrawsDetail,
	RepresentativeDrawsIndex,
	RepresentativeSalaryPaymentsIndex,
	RepresentativeSalaryPaymentsDetail,
  ContractorsDetail,
  ContractorsIndex,
  ContractorInvoicesDetail,
  ContractorInvoicesIndex,
  ContractorPaymentsDetail,
  ContractorPaymentsIndex,
  Profile,
  ReportsIndex,
	UnpaidRepresentativesIndex,
	UnreimbursedRepresentativesIndex,
} from "@pages/admin";
import { AppView, AppContainer } from "@components/Layouts";
import Nav from "@components/Nav";
import { NoMatch } from "@components/shared";

import ScrollToTop from "./ScrollToTop";

import { getRoleInfo } from "@util/roleInfo";

export default function Admin() {
  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <Router>
      <div style={{ height: "100%" }}>
        <Toaster
          toastOptions={{
            success: {
              iconTheme: {
                primary: "var(--positiveColor)",
              },
            },
          }}
        />
        <AppContainer>
          <Nav admin />
          <AppView>
            <ScrollToTop />
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/jobs" component={JobsIndex} />
              <Route path="/jobs/:id" component={JobsDetail} />
              <Route path="/job-representative/:id" component={JobRepresentativesDetail} />
							<Route path="/job-knocking/:id" component={JobKnockingsDetail} />
							<Route path="/knocking-payments" component={KnockingPaymentsIndex} />
							<Route path="/knocking-payments/:id" component={KnockingPaymentsDetail} />
              <Route exact path="/manager-payments" component={ManagerPaymentsIndex} />
              <Route path="/manager-payments/:id" component={ManagerPaymentsDetail} />
              <Route exact path="/representatives" component={RepresentativesIndex} />
              <Route path="/representatives/:id" component={RepresentativesDetail} />
							<Route path="/ach" component={UnpaidRepresentativesIndex} />
							<Route path="/ach-payments/:id" component={AchDetail} />
							<Route path="/unreimbursed-representatives" component={UnreimbursedRepresentativesIndex} />
              <Route exact path="/representative-corrections" component={RepresentativeCorrectionsIndex} />
              <Route path="/representative-corrections/:id" component={RepresentativeCorrectionsDetail} />
              <Route exact path="/representative-invoices" component={RepresentativeInvoicesIndex} />
              <Route path="/representative-invoices/:id" component={RepresentativeInvoicesDetail} />
              <Route exact path="/representative-reimbursements" component={RepresentativeReimbursementsIndex} />
              <Route path="/representative-reimbursements/:id" component={RepresentativeReimbursementsDetail} />
              <Route exact path="/representative-payments" component={RepresentativePaymentsIndex} />
              <Route path="/representative-payments/:id" component={RepresentativePaymentsDetail} />
              <Route exact path="/representative-bonuses" component={RepresentativeBonusesIndex} />
              <Route path="/representative-bonuses/:id" component={RepresentativeBonusesDetail} />
              <Route exact path="/representative-draws" component={RepresentativeDrawsIndex} />
              <Route path="/representative-draws/:id" component={RepresentativeDrawsDetail} />
              <Route exact path="/salary-payments" component={RepresentativeSalaryPaymentsIndex} />
              <Route path="/salary-payments/:id" component={RepresentativeSalaryPaymentsDetail} />
              <Route exact path="/contractors" component={ContractorsIndex} />
              <Route path="/contractors/:id" component={ContractorsDetail} />
              <Route exact path="/contractor-invoices" component={ContractorInvoicesIndex} />
              <Route path="/contractor-invoices/:id" component={ContractorInvoicesDetail} />
              <Route exact path="/contractor-payments" component={ContractorPaymentsIndex} />
              <Route path="/contractor-payments/:id" component={ContractorPaymentsDetail} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/reports" component={ReportsIndex} />
              {roleInfo.admin_full && [
                <Route exact path="/admins" component={AdminIndex} />,
                <Route exact path="/admins/:id" component={AdminDetail} />,
              ]}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </AppView>
        </AppContainer>
      </div>
    </Router>
  );
}
