import React from "react";
import styled from "styled-components";

const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  border: 1px solid #f2f2f2;
  // border: none;
  border-radius: 4px;
  background: ${(props) =>
    props.active ? `var(--primaryColorDark)` : "#e6e6e6"};
  color: ${(props) => (props.active ? `white` : "initial")};

  &:hover {
    opacity: 0.7;
  }

  span.name {
    font-weight: bold;
  }

  span.description {
    font-size: 0.85em;
    margin-top: 3px;
  }

  span.note {
    margin-top: 12px;
    margin-bottom: 6px;
    font-size: 0.85em;
    /* padding: 10px; */
    /* background: var(--primaryColorLight); */
    /* color: var(--primaryTextColor); */
    /* color: #f2f2f2; */
    /* border-radius: 3px; */
    width: 95%;
    /* margin: 12px auto 0 auto; */
  }
`;

export default function OptionButton({
  active,
  onClick,
  text,
  description,
  note,
}) {
  return (
    <Button onClick={onClick} active={active}>
      <span className="name">{text}</span>
      {description && <span className="description">{description}</span>}
      {note && <span className="note">{note}</span>}
    </Button>
  );
}

OptionButton.defaultProps = {
  active: false,
  description: "",
  note: "",
  text: "Button",
  onClick: () => alert("Option Button!"),
};
