import React, { useState } from "react";
import { KnockingPaymentForm, Table } from "@components/KnockingPayment";
import { EditModal } from "@components/RepresentativeJobSelection";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

export default function KnockingPaymentsIndex() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Knocking Payments"
      >
        <Table representativeView={false} />
      </Wrapped>
      
    </div>
  );
}
