import React, { Component } from "react";
import ReactTable from "react-table";
import axios from "axios";
import PropTypes from "prop-types";

import { ErrorBoundary } from "@components/shared";

class PaginatedTable extends Component {
  state = {
    loading: true,
    pages: null,
    tableData: [],
    total: 0,
  };

  table = React.createRef();

  componentDidMount = () => {};

  componentDidUpdate = (prevProps) => {
    if (prevProps.refresh !== this.props.refresh) {
      this.onFetchData(this.table.current.state);
    }

    if (
      JSON.stringify(prevProps.additionalPostData) !==
      JSON.stringify(this.props.additionalPostData)
    ) {
      this.onFetchData(this.table.current.state);
    }
  };

  onFetchData = (state, instance) => {
    const { page, pageSize, sorted, filtered } = state;

    this.setState({ loading: true });

    const postData = {
      ...this.props.additionalPostData,
      page: page * pageSize,
      page_size: pageSize,
      sorted,
      filtered,
    };

    if (this.axiosCancelSource) {
      this.axiosCancelSource.cancel("Cancelled");
    }

    this.axiosCancelSource = axios.CancelToken.source();

    axios
      .post(this.props.url, postData, {
        cancelToken: this.axiosCancelSource.token,
      })
      .then(({ data }) => {
        let pages = 0;
        const total = data.total;

        if (total < pageSize) {
          pages = 1;
        } else {
          pages = Math.ceil(total / pageSize);
        }

        this.setState({
          loading: false,
          pages,
          tableData: data.data,
          total: total ? total : 0,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    if (!this.props.columns.length) {
      return <p>No Columns...</p>;
    }

    return (
      <ErrorBoundary>
        <ReactTable
          className="-ui -striped -table"
          columns={[...this.props.columns]}
          data={this.state.tableData}
          defaultPageSize={this.props.defaultPageSize}
          defaultSorted={this.props.defaultSorted}
          loading={this.state.loading}
          loadingText={`Loading ${this.props.entityName}`}
          manual
          minRows={5}
          multiSort={false}
          noDataText={`No ${this.props.entityName} Available`}
          onFetchData={this.onFetchData}
          pages={this.state.pages}
          ref={this.table}
          rowsText={`${this.props.entityName}`}
        />
      </ErrorBoundary>
    );
  }
}

PaginatedTable.defaultProps = {
  additionalPostData: {},
  columns: [],
  defaultPageSize: 50,
  defaultSorted: [],
  refresh: false,
};

PaginatedTable.propTypes = {
  columns: PropTypes.array,
  defaultPageSize: PropTypes.number,
  defaultSorted: PropTypes.array,
  entityName: PropTypes.string,
  keyName: PropTypes.string,
  refresh: PropTypes.bool,
  url: PropTypes.string,
};

export default PaginatedTable;
