import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { 
					AchPayments,
					AchPaymentReport,
					RepresentativeForm, 
					RepresentativeJobManagement, 
					RepresentativeReimbursements, 
					RepresentativeDraws, 
					RepresentativeBonuses,
					RepresentativeCorrections, 
					RepresentativeSalaryPayments,
					RepresentativeKnockings,
					RepresentativeManagerManagement, 
																						} from "@components/Representative";
import { Table } from "@components/ManagedRepresentatives";

export default function RepresentativesDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [representative, setRepresentative] = useState(populateRepresentative());

  const representativeId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/representatives/${representativeId}/`)
      .then(({ data }) => {
        setLoading(false);
        setRepresentative(populateRepresentative(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!representative) return null;

  return (
    <div>
      <PageTitle
        title={representative.fullName}
        subtitle="Representative"
        links={[
          { text: "Detail", to: match.url },
					{ text: "Payments", to: `${match.url}/payments` },
					{ text: "YTD Report", to: `${match.url}/reports` },
          { text: "Commissions", to: `${match.url}/commissions` },
          { text: "Reimbursements", to: `${match.url}/reimbursements` },
					{ text: "Salary", to: `${match.url}/salary` },
					{ text: "Knocking", to: `${match.url}/knocking` },
					{ text: "Draws", to: `${match.url}/draws` },
          { text: "Bonuses", to: `${match.url}/bonuses` },
					{ text: "Corrections", to: `${match.url}/corrections` },
        ].map((l, i) => ({ ...l, key: i + 1 }))}
      />
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <Wrapped>
              <RepresentativeForm
                id={representativeId}
                prefix={representative.prefix}
                firstName={representative.firstName}
                lastName={representative.lastName}
                suffix={representative.suffix}
								companyName={representative.companyName}
                email={representative.email}
                phone={representative.phone}
                status={representative.status}
                statusFormatted={representative.statusFormatted}
                addressOne={representative.addressOne}
                addressTwo={representative.addressTwo}
                city={representative.city}
                state={representative.state}
                zipcode={representative.zipcode}
                overridePercentage={representative.overridePercentage}
                paidLeadPercentage={representative.paidLeadPercentage}
                globalCommissionRatio={representative.globalCommissionRatio}
                manager={representative.manager}
                managerPointsPercentage={representative.managerPointsPercentage}
                salary={representative.salary}
                weeklySalaryFormatted={representative.weeklySalaryFormatted}
                notes={representative.notes}
                onSuccess={fetchData}
              />
            </Wrapped>
            <Wrapped>
							<RepresentativeManagerManagement representativeId={representativeId} managerLink={representative.managerLink} />
            </Wrapped>
						<Wrapped>
				      	<h2>Managed Representatives</h2>
								<Table managedRepresentatives={representative.managedRepresentatives} />
							</Wrapped>
          </React.Fragment>
        </Route>
        <Route path={`${match.path}/payments`}>
          <Wrapped>
            <AchPayments representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/reports`}>
          <Wrapped>
            <AchPaymentReport representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/commissions`}>
          <Wrapped>
            <RepresentativeJobManagement representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/reimbursements`}>
          <Wrapped>
            <RepresentativeReimbursements representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/salary`}>
          <Wrapped>
            <RepresentativeSalaryPayments representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/knocking`}>
          <Wrapped>
            <RepresentativeKnockings representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/draws`}>
          <Wrapped>
            <RepresentativeDraws representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/bonuses`}>
          <Wrapped>
            <RepresentativeBonuses representativeId={representativeId} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/corrections`}>
          <Wrapped>
            <RepresentativeCorrections representativeId={representativeId} />
          </Wrapped>
        </Route>
      </Switch>
    </div>
  );
}

function populateRepresentative(d = {}) {
  return {
		managerLink: d.manager_representative || "",
		managedRepresentatives: d.managed_representatives,
		prefix: d.prefix || "",
    firstName: d.first_name || "",
    lastName: d.last_name || "",
    suffix: d.suffix || "",
    fullName: d.full_name || "",
		companyName: d.company_name || "",
    email: d.email || "",
    phone: d.phone || "",
    addressOne: d.address_1 || "",
    addressTwo: d.address_2 || "",
    city: d.city || "",
    state: d.state || "",
    overridePercentage: d.override_percentage,
    paidLeadPercentage: d.paid_lead_percentage,
    globalCommissionRatio: d.global_commission_ratio,
    manager: d.manager,
    managerPointsPercentage: d.manager_points_percentage,
    salary: d.salary,
    weeklySalaryFormatted: d.weekly_salary_formatted || "0",
    zipcode: d.zipcode || "",
    status: d.status,
    statusFormatted: d.status_formatted,
    notes: d.notes || "",
  };
}