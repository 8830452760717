export const getRoleInfo = (state) => {
  const { admin, representative } = state;
	
  return {
    admin_full: admin ? admin.access === 0 : false,
    admin_partial: admin ? admin.access === 1 : false,
    admin_view_only: admin ? admin.access === 2 : false,
		manager_view: representative ? representative.manager === true : false,
  };
};
