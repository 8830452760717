import React from "react";
import styled from "styled-components";

import { size, device } from "@util/breakpoints";

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: 100%;
  margin: 20px 50px 20px 220px;
  padding-bottom: 85px;

  @media (max-width: ${size.tablet}) {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  @media (min-width: ${size.laptop}) {
    margin-left: 20px;
  }

  @media (min-width: 770px) and (max-width: 920px) {
    margin-left: 150px !important;
    margin-right: 0;
  }
`;

export default function AppContainer({ children }) {
  return <Wrapper>{children}</Wrapper>;
}
