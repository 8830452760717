import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import * as Yup from "yup";
import { BasicForm, BasicTable } from "@components/shared";
import { Icon } from "semantic-ui-react";
import PropTypes from 'prop-types';

import { getRoleInfo } from "@util/roleInfo";

function AchPaymentForm({
  id,
  representativeName,
  representativeId,
  datePaid,
  amountFormatted,
  postedToJp,
  postedToAch,
  achReference,
  notes,
  status,
	jobPaymentLinks,
 	selectedJobPaymentLinks,
	unpaidJobPaymentLinks,
	jobManagerPointsLinks,
 	selectedManagerPoints,
 	unpaidManagerPoints,
	representativeSalaryPayments,
 	selectedSalaries,
 	unpaidSalaries,
	jobReimbursementLinks,
 	selectedJobReimbursementLinks,
 	unpaidJobReimbursementLinks,
	drawCollections,
 	selectedUnsettledDrawCollections,
 	unsettledDrawCollections,
	representativeBonuses,
 	selectedBonuses,
 	unpaidBonuses,
	representativeCorrections,
 	selectedCorrections,
	unsettledCorrections,
	jobKnockingLinks,
 	selectedKnockings,
 	unpaidKnockings,
  onFormSubmit,
  onSuccess,
  dispatch,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const roleInfo = getRoleInfo(useSelector((state) => state));
	
	
	const adjustedUnsettledDrawCollections = unsettledDrawCollections
	  .filter(item => selectedUnsettledDrawCollections.includes(item.id)) // Filter based on selected IDs
	  .map(item => ({ 
	      id: item.id, 
	      amount_to_collect: item.amount_to_collect // Map to include only id and amount_to_collect
	  }));
  
  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
			representative_id: representativeId,
      job_payment_link_ids: selectedJobPaymentLinks,
			job_manager_points_link_ids: selectedManagerPoints,
			representative_salary_payment_ids: selectedSalaries,
			job_knocking_link_ids: selectedKnockings,
			job_reimbursement_link_ids: selectedJobReimbursementLinks,
			draw_collections: adjustedUnsettledDrawCollections,
			representative_bonus_ids: selectedBonuses,
			representative_correction_ids: selectedCorrections,
      representative_payment_total: totalCommissionAmount,
			manager_payment_total: totalPointsAmount,
			knocking_payment_total: totalKnockingsAmount,
			representative_reimbursement_total: totalReimbursementsAmount,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/ach-payments/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success("Ach Payment Created!");
        onSuccess(data.data.id);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/ach-payments/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Ach Payment Updated!"))
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
	
	const filteredJobPaymentLinks = (unpaidJobPaymentLinks || []).filter(link => 
	  selectedJobPaymentLinks.includes(link.id)
	);
	
	const totalCommissionAmount = (jobPaymentLinks && jobPaymentLinks.length > 0 ? jobPaymentLinks : filteredJobPaymentLinks).reduce((total, currentLink) => {
	  return total + +currentLink.commission_in_pennies;
	}, 0);

	const formattedTotalCommissionAmount = (totalCommissionAmount / 100).toLocaleString('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});
	
	const filteredManagerPoints = (unpaidManagerPoints || []).filter(link => 
	  selectedManagerPoints.includes(link.id)
	);
	
	const totalPointsAmount = (jobManagerPointsLinks && jobManagerPointsLinks.length > 0 ? jobManagerPointsLinks : filteredManagerPoints).reduce((total, currentLink) => {
	  return total + +currentLink.points_commission_in_pennies;
	}, 0);
	
	const formattedTotalPointsAmount = (totalPointsAmount / 100).toLocaleString('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});
	
	const filteredSalaries = (unpaidSalaries || []).filter(link => 
	  selectedSalaries.includes(link.id)
	);
	
	const totalSalaryPaymentsAmount = (representativeSalaryPayments && representativeSalaryPayments.length > 0 ? representativeSalaryPayments : filteredSalaries).reduce((total, currentLink) => {
	  return total + +currentLink.amount_in_pennies;
	}, 0);
	
	const formattedTotalSalaryPaymentsAmount = (totalSalaryPaymentsAmount / 100).toLocaleString('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});
	
	const filteredKnockings = (unpaidKnockings || []).filter(link => 
	  selectedKnockings.includes(link.id)
	);
	
	const totalKnockingsAmount = (jobKnockingLinks && jobKnockingLinks.length > 0 ? jobKnockingLinks: filteredKnockings).reduce((total, currentLink) => {
	  return total + +currentLink.commission_in_pennies;
	}, 0);
	
	const formattedTotalKnockingsAmount = (totalKnockingsAmount / 100).toLocaleString('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});
	
	const filteredJobReimbursementLinks = (unpaidJobReimbursementLinks || []).filter(link => 
	  selectedJobReimbursementLinks.includes(link.id)
	);
	
	const totalReimbursementsAmount = (jobReimbursementLinks && jobReimbursementLinks.length > 0 ? jobReimbursementLinks : filteredJobReimbursementLinks).reduce((total, currentLink) => {
	  return total + +currentLink.amount_in_pennies;
	}, 0);
	
	const formattedTotalReimbursementsAmount = (totalReimbursementsAmount / 100).toLocaleString('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});
	
	const filteredDrawCollections = (unsettledDrawCollections || []).filter(link => 
	  selectedUnsettledDrawCollections.includes(link.id)
	);
	
	const totalDrawCollectionsAmount = (drawCollections && drawCollections.length > 0 ? drawCollections : filteredDrawCollections).reduce((total, currentLink) => {
	  return total - Math.abs(+Math.round(parseFloat(currentLink.amount_to_collect.replace(/,/g, '')) * 100)); // Subtracting the absolute value
	}, 0);
	
	const formattedTotalDrawCollectionsAmount = (totalDrawCollectionsAmount / 100).toLocaleString('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});
	
	const filteredBonuses = (unpaidBonuses || []).filter(link => 
	  selectedBonuses.includes(link.id)
	);
	
	const totalBonusesAmount = (representativeBonuses && representativeBonuses.length > 0 ? representativeBonuses : filteredBonuses).reduce((total, currentLink) => {
	  return total + +currentLink.amount_in_pennies;
	}, 0);

	
	const formattedTotalBonusesAmount = (totalBonusesAmount / 100).toLocaleString('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});
	
	const filteredCorrections = (unsettledCorrections || []).filter(link => 
	  selectedCorrections.includes(link.id)
	);
	
	const totalCorrectionsAmount = (representativeCorrections && representativeCorrections.length > 0 ? representativeCorrections : filteredCorrections).reduce((total, currentLink) => {
	  return total + +currentLink.amount_in_pennies;
	}, 0);

	const formattedTotalCorrectionsAmount = (totalCorrectionsAmount / 100).toLocaleString('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});
	
	function formatNumber(value) {
	    // Perform initial formatting to two decimal places
	    let formattedValue = (value / 100).toFixed(2);
	    // Check if the formatted string represents -0.00 and convert it if necessary
	    if (formattedValue === '-0.00') {
	        formattedValue = '0.00';
	    }
	    return formattedValue;
	}

	// Sum up all components and format the total
	const totalAmount = formatNumber(
	    Math.round(totalCommissionAmount + totalPointsAmount + totalSalaryPaymentsAmount + totalKnockingsAmount + totalReimbursementsAmount + totalDrawCollectionsAmount + totalBonusesAmount + totalCorrectionsAmount)
	);

  const statusOptions = [
    { text: "Pending", value: 0 },
    { text: "Complete", value: 1 },
  ];

  const fields = [
    {
      grouped: true,
      fields: [
		    {
		      name: "date_paid",
		      initialValue: datePaid
		            ? new Date(datePaid)
		            : null,
		      label: "Date Paid (optional)",
		      datepicker: true,
		      required: false,
		      schema: () =>
		        Yup.string().nullable(),
		    },
				{
		      name: "amount_formatted",
		      initialValue: id ? amountFormatted : totalAmount,
		      label: "Total ACH Payment",
		      required: true,
		      schema: () => Yup.number().typeError('Amount must be a numbers only.')
																		.min(0, 'The value must be zero or positive.')
																		.required("Required"),
		    },
      ],
    },
    {
      grouped: true,
      fields: [
        {
          name: "posted_to_ach",
          label: "Posted To ACH",
          initialValue: postedToAch,
          required: true,
          radio: true,
          trueText: "Yes",
          falseText: "No",
          schema: () => null,
        },
        {
          name: "ach_reference",
          initialValue: achReference,
          label: "ACH Reference",
          required: false,
          schema: () => Yup.string().max(20, "Too Long!"),
        },
      ],
    },
    {
      name: "status",
      initialValue: status,
      label: "Status",
      select: true,
      required: true,
      options: statusOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
  ]
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_view_only ? true : false,
    }));
		
	  let commissionColumns = [
			{ Header: "Job", accessor: "job.name", width: 200 },
			{ Header: "Pay After", accessor: "job.date_paid_formatted", width: 110 },
			{ Header: "Leap ID", accessor: "job.leap_job_id", width: 150 },
			{ Header: "Job Total", accessor: "job.job_total_formatted", width: 120 },
			{ Header: "Total Cost", accessor: "job.total_cost_formatted", width: 120 },
			{ Header: "Profit %", accessor: "job.profit_margin_percentage", width: 100 },
		  { Header: "Override %", accessor: "override_percentage", width: 100 },
			{ Header: "Paid Lead", 
		  accessor: (d) =>
		    d.job.paid_lead ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
			id: "job_paid_lead", width: 100
			},
		  { Header: "Paid Lead %", accessor: "paid_lead_percentage", width: 100 },
		  { Header: "Split %", accessor: "split_percentage", width: 100 },
			{ Header: "Commission Ratio", accessor: "resulting_ratio_reason" },
		  { Header: "Commission", accessor: "commission_formatted", width: 120 },
			{ Header: "Paid", 
		  accessor: (d) =>
		    d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
			id: "paid", width: 100
			},
	  ];
		
	  let pointsColumns = [
			{ Header: "Job", accessor: "job.name", width: 200 },
			{ Header: "Representative", accessor: "representative.full_name", width: 175 },
			{ Header: "Pay After", accessor: "job.date_paid_formatted", width: 100 },
			{ Header: "Leap ID", accessor: "job.leap_job_id", width: 150 },
			{ Header: "Job Total", accessor: "job.job_total_formatted", width: 110 },
			{ Header: "Total Cost", accessor: "job.total_cost_formatted", width: 120 },
			{ Header: "Profit %", accessor: "job.profit_margin_percentage", width: 100 },
		  { Header: "Points %", accessor: "points_percentage", width: 100 },
			{ Header: "Commission", accessor: "points_commission_formatted", width: 120 },
			{ Header: "Paid", 
		  accessor: (d) =>
		    d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
			id: "paid", width: 75
			},
			{ Header: "Notes", accessor: "notes"},
	  ];
		
	  let salariesColumns = [
			{ Header: "Period Begin", accessor: "period_begin_formatted", width: 120 },
			{ Header: "Period End", accessor: "period_end_formatted", width: 120 },
		  { Header: "Date", accessor: "date_formatted", width: 120 },
		  { Header: "Notes", accessor: "notes"},
			{ Header: "Amount", accessor: "amount_formatted", width: 120 },
			{ Header: "Paid", 
		  accessor: (d) =>
		    d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
			id: "paid", width: 100
			},
	  ];
		
	  let reimbursementColumns = [
		{ Header: "Job", 
	  accessor: (d) =>
	    d.job ? d.job.name : "Roofing Done Wright",
		id: "job", width: 300
		},
		{ Header: "Supplier", accessor: "supplier", width: 120 },
		{ Header: "Purpose", accessor: "purpose", width: 120 },
	  { Header: "Date Requested", accessor: "invoice_date_formatted", width: 125 },
	  { Header: "Notes", accessor: "notes" },
		{ Header: "Amount", accessor: "amount_formatted", width: 120 },
		{ Header: "Paid", 
	  accessor: (d) =>
	    d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
		id: "paid", width: 100
		},
	  ];
		
	  let drawCollectionsColumns = [
		  { Header: "Notes", accessor: "notes" },
			{
			  Header: "Amount",
			  accessor: "amount_formatted",
			  width: 120,
			 },
 			{
 			  Header: "Amount To Collect",
 			  accessor: "amount_to_collect",
 			  width: 300,
 			 },
	  ];
		
	  let bonusesColumns = [
		  { Header: "Date", accessor: "date_formatted", width: 120 },
		  { Header: "Notes", accessor: "notes" },
			{ Header: "Amount", accessor: "amount_formatted", width: 120 },
			{ Header: "Paid", 
		  accessor: (d) =>
		    d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
			id: "paid", width: 100
			},
	  ];
		
	  let correctionColumns = [
			{ Header: "Date Recorded", accessor: "date_formatted", width: 120 },
		  { Header: "Notes", accessor: "notes" },
			{
			  Header: "Amount",
			  accessor: "amount_formatted",
			  width: 120,
			},
			{ Header: "Settled", 
		  accessor: (d) =>
		    d.settled ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
			id: "settled", width: 100
			},
	  ];
		
	  let knockingsColumns = [
			{ Header: "Job", accessor: "job.name", width: 275 },
			{ Header: "Pay After", accessor: "job.date_paid_formatted", width: 120 },
			{ Header: "Leap ID", accessor: "job.leap_job_id", width: 150 },
			{ Header: "Job Total", accessor: "job.job_total_formatted", width: 120 },
			{ Header: "Total Cost", accessor: "job.total_cost_formatted", width: 120 },
			{ Header: "Profit %", accessor: "job.profit_margin_percentage", width: 120 },
		  { Header: "Commission %", accessor: "percentage" },
		  { Header: "Commission", accessor: "commission_formatted", width: 120  },
			{ Header: "Paid", 
		  accessor: (d) =>
		    d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
			id: "paid", width: 75
			},
	  ];

  return (
    <React.Fragment>
      <div style={{ margin: "30px 10px 10px 0" }}>
				<React.Fragment>
				  <div style={{ display: "flex", justifyContent: "space-between" }}>
				    <div>

				    </div>
				    <div>
				      <table>
				        <tr>
				          <td style={{ width: "250px", textAlign: "right" }}>Commission</td><td>{formattedTotalCommissionAmount}</td>
				        </tr>
				        <tr>
				          <td style={{ width: "250px", textAlign: "right" }}>Manager Points</td><td>{formattedTotalPointsAmount}</td>
				        </tr>
				        <tr>
				          <td style={{ width: "250px", textAlign: "right" }}>Salary Payments</td><td>{formattedTotalSalaryPaymentsAmount}</td>
				        </tr>
				        <tr>
				          <td style={{ width: "250px", textAlign: "right" }}>Knocking Commissions</td><td>{formattedTotalKnockingsAmount}</td>
				        </tr>
				        <tr>
				          <td style={{ width: "250px", textAlign: "right" }}>Reimbursements</td><td>{formattedTotalReimbursementsAmount}</td>
				        </tr>
				        <tr>
				          <td style={{ width: "250px", textAlign: "right" }}>Draw Collections</td><td>{formattedTotalDrawCollectionsAmount}</td>
				        </tr>
				        <tr>
				          <td style={{ width: "250px", textAlign: "right" }}>Bonuses</td><td>{formattedTotalBonusesAmount}</td>
				        </tr>
				        <tr>
				          <td style={{ width: "250px", textAlign: "right" }}>Corrections</td><td>{formattedTotalCorrectionsAmount}</td>
				        </tr>
				      </table>
				    </div>
				  </div>
				</React.Fragment>

        <BasicForm
          error={error}
          fields={fields}
          loading={isLoading}
          onSubmit={onSubmit}
        />
      </div>
			{
			  (jobPaymentLinks.length > 0 || filteredJobPaymentLinks.length > 0) && (
			    <div style={{ margin: "30px 10px 10px 0" }}>
			      <h2>Commissions</h2>
			      <BasicTable
			        fetch={false}
			        data={id ? jobPaymentLinks : filteredJobPaymentLinks}
			        loading={isLoading}
			        columns={commissionColumns}
			        entityName="Jobs"
			      />
			    </div>
			  )
			}
			{
			  (jobManagerPointsLinks.length > 0 || filteredManagerPoints.length > 0) && (
					<div style={{ margin: "30px 10px 10px 0" }}>
						<h2>Manager Points</h2>
					  <BasicTable
					    fetch={false}
					    data={id ? jobManagerPointsLinks : filteredManagerPoints}
					    loading={isLoading}
					    columns={pointsColumns}
					    entityName="Manager Points"
					  />
			    </div>
			  )
			}
			{
			  (representativeSalaryPayments.length > 0 || filteredSalaries.length > 0) && (
					<div style={{ margin: "30px 10px 10px 0" }}>
						<h2>Salary Payments</h2>
					  <BasicTable
					    fetch={false}
					    data={id ? representativeSalaryPayments : filteredSalaries}
					    loading={isLoading}
					    columns={salariesColumns}
					    entityName="Salary Payments"
					  />
			    </div>
			  )
			}
			{
			  (jobKnockingLinks.length > 0 || filteredKnockings.length > 0) && (
					<div style={{ margin: "30px 10px 10px 0" }}>
						<h2>Knocking Commissions</h2>
					  <BasicTable
					    fetch={false}
					    data={id ? jobKnockingLinks : filteredKnockings}
					    loading={isLoading}
					    columns={knockingsColumns}
					    entityName="Knockings"
					  />
			    </div>
			  )
			}
			{
			  (jobReimbursementLinks.length > 0 || filteredJobReimbursementLinks.length > 0) && (
					<div style={{ margin: "30px 10px 10px 0" }}>
						<h2>Reimbursements</h2>
					  <BasicTable
					    fetch={false}
					    data={id ? jobReimbursementLinks : filteredJobReimbursementLinks}
					    loading={isLoading}
					    columns={reimbursementColumns}
					    entityName="Reimbursements"
					  />
			    </div>
			  )
			}
			{
			  (drawCollections.length > 0 || filteredDrawCollections.length > 0) && (
					<div style={{ margin: "30px 10px 10px 0" }}>
						<h2>Draw Collections</h2>
					  <BasicTable
					    fetch={false}
					    data={id ? drawCollections : filteredDrawCollections}
					    loading={isLoading}
					    columns={drawCollectionsColumns}
					    entityName="Draw Collections"
					  />
			    </div>
			  )
			}
			{
			  (representativeBonuses.length > 0 || filteredBonuses.length > 0) && (
					<div style={{ margin: "30px 10px 10px 0" }}>
						<h2>Bonuses</h2>
					  <BasicTable
					    fetch={false}
					    data={id ? representativeBonuses : filteredBonuses}
					    loading={isLoading}
					    columns={bonusesColumns}
					    entityName="Bonuses"
					  />
			    </div>
			  )
			}
			{
			  (representativeCorrections.length > 0 || filteredCorrections.length > 0) && (
					<div style={{ margin: "30px 10px 10px 0" }}>
						<h2>Corrections</h2>
					  <BasicTable
					    fetch={false}
					    data={id ? representativeCorrections : filteredCorrections}
					    loading={isLoading}
					    columns={correctionColumns}
					    entityName="Corrections"
					  />
			    </div>
			  )
			}
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps)(AchPaymentForm);

AchPaymentForm.propTypes = {
  selectedJobPaymentLinks: PropTypes.array,
  unpaidJobPaymentLinks: PropTypes.array,
  jobPaymentLinks: PropTypes.array,
};

AchPaymentForm.defaultProps = {
  selectedJobPaymentLinks: [],
  unpaidJobPaymentLinks: [],
  jobPaymentLinks: [],
	jobManagerPointsLinks: [],
	representativeSalaryPayments: [],
	jobReimbursementLinks: [],
	jobKnockingLinks: [],
	drawCollections: [],
	unsettledDrawCollections: [],
	representativeBonuses: [],
	representativeCorrections: [],
  datePaid: null,
  id: null,
  postedToJp: null,
  postedToAch: true,
  achReference: "",
  status: 1,
};
