import React from "react";

import { DoughnutChart, ReportComponent } from "./";
import { BasicGrid } from "@components/shared";
import { getUuid } from "@util/getUuid";
import { getRgbaColors } from "@util/colors";

export default function PatientDemographics({ data }) {
  if (!data) return null;

  const genderItems = [
    { text: "Male", value: data.male_count, color: "#a2d2ff" },
    { text: "Female", value: data.female_count, color: "#ffafcc" },
    { text: "Non-Binary", value: data.non_binary_count, color: "#cdb4db" },
  ].filter((f) => f.value);

  const ageItems = [
    {
      text: "Average Age",
      value: `${data.average_age} ± ${data.age_standard_deviation}`,
    },
    {
      text: "Youngest",
      value: data.min_age,
    },
    {
      text: "Oldest",
      value: data.max_age,
    },
  ].map((m) => ({ ...m, key: getUuid() }));

  const iopAndMedItems = [
    {
      text: "Mean IOP",
      value: `${data.mean_iop} ± ${data.iop_standard_deviation}`,
    },
    { text: "Mean Meds", value: data.mean_meds },
  ].map((m) => ({ ...m, key: getUuid() }));

  const ethnicityItems = Object.keys(data.ethnicities)
    .map((m) => ({
      text: m,
      value: data.ethnicities[m],
      key: getUuid(),
    }))
    .filter((f) => f.value);

  const eyeItems = [
    { text: "OD", value: data.od },
    { text: "OS", value: data.os },
  ];

  return (
    <div>
      {/* <h2>n = {data.n}</h2> */}
      <ReportComponent.Title>Age</ReportComponent.Title>
      <BasicGrid columns={4}>
        {ageItems.map((m) => (
          <ReportComponent.ValueBlock key={m.key}>
            <h1>{m.value}</h1>
            <p>{m.text}</p>
          </ReportComponent.ValueBlock>
        ))}
      </BasicGrid>
      <BasicGrid columns={2}>
        <ReportComponent.ChartWrapper>
          <ReportComponent.Title>Gender</ReportComponent.Title>
          <DoughnutChart
            data={{
              labels: genderItems.map((m) => m.text),
              datasets: [
                {
                  data: genderItems.map((m) => m.value),
                  backgroundColor: genderItems.map((m) => m.color),
                },
              ],
            }}
          />
        </ReportComponent.ChartWrapper>
        <ReportComponent.ChartWrapper>
          <ReportComponent.Title>Ethnicities</ReportComponent.Title>
          <DoughnutChart
            data={{
              labels: ethnicityItems.map((m) => m.text),
              datasets: [
                {
                  data: ethnicityItems.map((m) => m.value),
                  backgroundColor: getRgbaColors(12),
                },
              ],
            }}
          />
        </ReportComponent.ChartWrapper>
      </BasicGrid>
      <BasicGrid columns={2}>
        <ReportComponent.ChartWrapper>
          <ReportComponent.Title>Eyes</ReportComponent.Title>
          <DoughnutChart
            data={{
              labels: eyeItems.map((m) => m.text),
              datasets: [
                {
                  data: eyeItems.map((m) => m.value),
                  backgroundColor: getRgbaColors(125),
                },
              ],
            }}
          />
        </ReportComponent.ChartWrapper>
        <div>
          <ReportComponent.Title>IOP & Meds</ReportComponent.Title>
          <BasicGrid columns={2}>
            {iopAndMedItems.map((m) => (
              <ReportComponent.ValueBlock key={m.key}>
                <h1>{m.value}</h1>
                <p>{m.text}</p>
              </ReportComponent.ValueBlock>
            ))}
          </BasicGrid>
        </div>
      </BasicGrid>
    </div>
  );
}
