import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { ManagerPaymentForm } from "@components/ManagerPayment";

export default function ManagerPaymentsDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [managerPayment, setManagerPayment] = useState(populateManagerPayment());

  const managerPaymentId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/manager-payments/${managerPaymentId}/`)
      .then(({ data }) => {
        setLoading(false);
        setManagerPayment(populateManagerPayment(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!managerPayment) return null;

  return (
    <div>
      <PageTitle
        title={`${managerPayment.representative.full_name}`}
        subtitle="Manager Payment"
      />
      <Wrapped>
				<p>
					<Link to="/manager-payments"><strong>Points</strong></Link>
        </p>
        <ManagerPaymentForm
          id={managerPaymentId}
          datePaid={managerPayment.datePaid}
          postedToJp={managerPayment.postedToJp}
          postedToAch={managerPayment.postedToAch}
          achReference={managerPayment.achReference}
          jobManagerPointsLinks={managerPayment.jobManagerPointsLinks}
					amountFormatted={managerPayment.amountFormatted}
          status={managerPayment.status}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateManagerPayment(d = {}) {
  return {
    representative: d.representative || "",
		jobManagerPointsLinks: d.job_manager_points_links,
    datePaid: d.date_paid || "",
    postedToAch: d.posted_to_ach,
    achReference: d.ach_reference || "",
		amountFormatted: d.amount_formatted || "",
    status: d.status,
  };
}
