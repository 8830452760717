import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Icon } from "semantic-ui-react";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { BasicTable } from "@components/shared";

export default function Jobs({
  actionsDisabled,
  editing,
  representativeId,
  selected,
  onChange,
  fetchData,
	selectedJobPaymentLinks,
	setSelectedJobPaymentLinks,
	unpaidJobPaymentLinks, 
	setUnpaidJobPaymentLinks,
}) {
  const [loading, setLoading] = useState(false);

	useEffect(() => {
	  fetchJobs();
	}, []);

  function fetchJobs() {
    const req = {
      representative_id: representativeId,
      status: false,
    };

    setLoading(true);

    axios
      .post(`/representative-job-payment-links/`, req)
      .then(({ data }) => {
				setUnpaidJobPaymentLinks(data.data);
      })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }

  function onAddLinkToPaymentClick(jobPaymentLinkToAdd) {
		setSelectedJobPaymentLinks(prevLinks => [...prevLinks, jobPaymentLinkToAdd]);
  }
	
	function onRemoveLinkToPaymentClick(jobPaymentLinkToRemove) {
	  setSelectedJobPaymentLinks(prevLinks =>
	    prevLinks.filter(jobPaymentLink => jobPaymentLink !== jobPaymentLinkToRemove)
	  );
	}
	
  let columns = [
    {
      Header: "",
      width: 35,
      id: "inv",
      accessor: (d) => {
				const selected = selectedJobPaymentLinks.includes(d.id);
 
        if (selected) {
            return (
              <Button
                icon="check"
                color="green"
                style={{ transform: "scale(0.7)" }}
                circular
                loading={loading}
                size="mini"
                title="Remove"
                onClick={() => onRemoveLinkToPaymentClick(d.id)}
              />
            );
        }

        if (!selected) {
          return (
            <Button
              icon="plus"
              style={{ transform: "scale(0.7)" }}
              circular
              basic
              loading={loading}
              size="mini"
              title="Add"
              onClick={() => onAddLinkToPaymentClick(d.id)}
            />
          );
        }

        return (
          <Form.Checkbox
            checked={selected.includes(d.id)}
            onChange={() => onChange(d.id, d)}
          />
        );
      },
    },
		{ Header: "Job", accessor: "job.name", width: 275 },
		{ Header: "Pay After", accessor: "job.date_paid_formatted", width: 120 },
		{ Header: "Leap ID", accessor: "job.leap_job_id", width: 150 },
		{ Header: "Job Total", accessor: "job.job_total_formatted", width: 120 },
		{ Header: "Total Cost", accessor: "job.total_cost_formatted", width: 120 },
		{ Header: "Profit %", accessor: "job.profit_margin_percentage", width: 120 },
	  { Header: "Override %", accessor: "override_percentage", width: 120 },
		{ Header: "Paid Lead", 
	  accessor: (d) =>
	    d.job.paid_lead ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
		id: "job_paid_lead", width: 100
		},
	  { Header: "Paid Lead %", accessor: "paid_lead_percentage", width: 120 },
	  { Header: "Split %", accessor: "split_percentage", width: 120 },
		{ Header: "Commission Ratio", accessor: "resulting_ratio_reason", width: 225 },
	  { Header: "Commission", accessor: "commission_formatted" },
  ];

  return (
    <div style={{ marginTop: "15px" }}>
      <BasicTable
        fetch={false}
        data={unpaidJobPaymentLinks}
        loading={loading}
        columns={columns}
        entityName="Jobs"
      />
    </div>
  );
}

Jobs.defaultProps = {
  actionsDisabled: false,
  editing: false,
  selected: [],
};
