import React, { useState } from "react";
import axios from "axios";

import { PasswordForm } from "@components/Auth";

export default function ChangePassword({ adminId, representativeId, onSuccess }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  function onSubmit(formData) {
    setError(null);

    const req = {
      current_password: formData.currentPassword,
      new_password: formData.newPassword,
      confirmed_password: formData.confirmedPassword,
      updated: true,
    };

    if (adminId) {
      req.admin_id = adminId;
      req.admin = true;
			
	    setLoading(true);

	    axios
	      .post(`/admin-change-password/`, req)
	      .then(() => {
	        onSuccess();
	      })
	      .catch((err) => {
	        setError(err);
	        setLoading(false);
	      });
    }

    if (representativeId) {
      req.representative_id = representativeId;
			
	    setLoading(true);

	    axios
	      .post(`/representative-change-password/`, req)
	      .then(() => {
	        onSuccess();
	      })
	      .catch((err) => {
	        setError(err);
	        setLoading(false);
	      });
    }


  }

  return (
    <PasswordForm change onSubmit={onSubmit} error={error} loading={loading} />
  );
}

ChangePassword.defaultProps = {
  isAdmin: false,
};
