import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import * as Yup from "yup";
import { BasicForm } from "@components/shared";
import { Table } from "@components/ContractorInvoice";

import { getRoleInfo } from "@util/roleInfo";

function ContractorPaymentForm({
  id,
  contractorName,
  contractorId,
  datePaid,
  postedToJp,
  postedToAch,
  achReference,
  status,
  invoices,
  onFormSubmit,
  onSuccess,
  dispatch,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const roleInfo = getRoleInfo(useSelector((state) => state));
  
  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      status: 0,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/contractor-payments/`, req)
      .then(({ data }) => {
        return data.id;
      })
      .then(() => setLoading(false))
      .then(() => {
        toast.success("Contractor Payment Created!");
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/contractor-payments/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Contractor Payment Updated!"))
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
  
  const statusOptions = [
    { text: "Pending", value: 0 },
    { text: "Complete", value: 1 },
  ];

  const fields = [
    {
      name: "date_paid",
      initialValue: datePaid
            ? new Date(datePaid)
            : null,
      label: "Date Paid (optional)",
      datepicker: true,
      required: false,
      schema: () =>
        Yup.string().nullable(),
    },
    {
      name: "amount_formatted",
      initialValue: amountFormatted,
      label: "Amount",
      required: true,
      schema: () => Yup.number().typeError('Amount must be a numbers only.').positive("Negative numbers not allowed").required("Required"),
    },
    {
      grouped: true,
      fields: [
        {
          name: "posted_to_ach",
          label: "Posted To ACH",
          initialValue: postedToAch,
          required: true,
          radio: true,
          trueText: "Yes",
          falseText: "No",
          schema: () => null,
        },
        {
          name: "ach_reference",
          initialValue: achReference,
          label: "ACH Reference",
          required: false,
          schema: () => Yup.string().max(20, "Too Long!"),
        },
      ],
    },
    {
      name: "status",
      initialValue: status,
      label: "Status",
      select: true,
      required: true,
      options: statusOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
  ]
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_view_only ? true : false,
    }));

  return (
    <React.Fragment>
      <div>
        <BasicForm
          error={error}
          fields={fields}
          loading={isLoading}
          onSubmit={onSubmit}
        />
      </div>
      <div style={{ margin: "30px 10px 10px 0" }}>
        <Table contractorInvoices={invoices} />
      </div>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    contractorId: state.contractor ? state.contractor.id : null,
  };
}

export default connect(mapStateToProps)(ContractorPaymentForm);

ContractorPaymentForm.defaultProps = {
  onSuccess: () => alert("On Success"),
  datePaid: null,
  id: null,
  postedToJp: null,
  postedToAch: null,
  achReference: "",
  status: 0,
};
