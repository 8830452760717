import React from "react";

import { BarChart } from "./";
import { getRgbaColors } from "@util/colors";

export default function PreviousOcularSurgery({ data }) {
  if (!data) return null;

  const formattedData = {
    labels: data.previous_ocular_surgeries.map(
      (m) => m.previous_ocular_surgery
    ),
    datasets: [
      {
        data: data.previous_ocular_surgeries.map((m) => m.count),
        backgroundColor: getRgbaColors(12),
      },
    ],
  };

  return <BarChart data={formattedData} />;
}
