import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Icon } from "semantic-ui-react";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { BasicTable } from "@components/shared";

export default function Invoices({
  actionsDisabled,
  editing,
  representativeId,
  selected,
  onChange,
  fetchData,
	selectedJobReimbursementLinks,
	setSelectedJobReimbursementLinks,
	unpaidJobReimbursementLinks, 
	setUnpaidJobReimbursementLinks,
}) {
  const [loading, setLoading] = useState(false);

	useEffect(() => {
	  fetchInvoices();
	}, []);

  function fetchInvoices() {
    const req = {
      representative_id: representativeId,
      status: false,
    };

    setLoading(true);

    axios
      .post(`/representative-invoices/`, req)
      .then(({ data }) => {
				setUnpaidJobReimbursementLinks(data.data);
      })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }

  function onAddLinkToPaymentClick(jobReimbursementLinkToAdd) {
		setSelectedJobReimbursementLinks(prevLinks => [...prevLinks, jobReimbursementLinkToAdd]);
  }
	
	function onRemoveLinkToPaymentClick(jobReimbursementLinkToRemove) {
	  setSelectedJobReimbursementLinks(prevLinks =>
	    prevLinks.filter(jobReimbursementLink => jobReimbursementLink !== jobReimbursementLinkToRemove)
	  );
	}
	
  let columns = [
    {
      Header: "",
      width: 35,
      id: "inv",
      accessor: (d) => {
				const selected = selectedJobReimbursementLinks.includes(d.id);
 
        if (selected) {
            return (
              <Button
                icon="check"
                color="green"
                style={{ transform: "scale(0.7)" }}
                circular
                loading={loading}
                size="mini"
                title="Remove"
                onClick={() => onRemoveLinkToPaymentClick(d.id)}
              />
            );
        }

        if (!selected) {
          return (
            <Button
              icon="plus"
              style={{ transform: "scale(0.7)" }}
              circular
              basic
              loading={loading}
              size="mini"
              title="Add"
              onClick={() => onAddLinkToPaymentClick(d.id)}
            />
          );
        }

        return (
          <Form.Checkbox
            checked={selected.includes(d.id)}
            onChange={() => onChange(d.id, d)}
          />
        );
      },
    },
		{ Header: "Job", accessor: "job.name", width: 300 },
		{ Header: "Amount", accessor: "amount_formatted", width: 120 },
		{ Header: "Supplier", accessor: "supplier", width: 120 },
		{ Header: "Purpose", accessor: "purpose", width: 120 },
	  { Header: "Invoce Date", accessor: "invoice_date_formatted", width: 120 },
		{ Header: "Paid", 
	  accessor: (d) =>
	    d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
		id: "paid", width: 100
		},
	  { Header: "Notes", accessor: "notes", width: 120 },
  ];

  return (
    <div style={{ marginTop: "15px" }}>
      <BasicTable
        fetch={false}
        data={unpaidJobReimbursementLinks}
        loading={loading}
        columns={columns}
        entityName="Invoice"
      />
    </div>
  );
}

Invoices.defaultProps = {
  actionsDisabled: false,
  editing: false,
  selected: [],
};
