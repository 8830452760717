import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaExclamationCircle } from "react-icons/fa";

import PasswordForm from "./PasswordForm";

class Code extends Component {
  state = {
    adminId: null,
    error: null,
    loading: true,
    submitting: false,
    representativeId: null,
    variety: null,
    verified: false,
		email: null,
		remoteCodeId: null,
		remoteCode: null,
  };

  componentDidMount = () => {
    this.verifyCode();
  };

  verifyCode = () => {
    const { id } = this.props.match.params;

    axios
      .get(`/verify-code/${id}/`)
      .then(({ data }) => {
        let code = data;
				
        this.setState({
          email: code.email,
          remoteCodeId: code.id,
					remoteCode: code.code,
          adminId: code.admin_id,
          representativeId: code.representative_id,
          loading: false,
          variety: code.variety,
          verified: true,
          rawCodeData: code,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          loading: false,
          verified: false,
        });
      });
  };

	onPasswordSubmit = ({ newPassword, confirmedPassword }) => {
	  const { adminId, remoteCode, representativeId, variety } = this.state;

	  const req = {
	    new_password: newPassword,
	    confirmed_password: confirmedPassword,
	    remote_code: remoteCode,
	  };

	  if (representativeId) {
	    req.representative_id = representativeId;
	  }

	  if (adminId) {
	    req.admin_id = adminId;
	  }

	  this.setState({
	    submitting: true,
	    error: null,
	  });

	  // Determine the path based on the variety
	  let path;
	  switch (variety) {
	    case 0:
	      path = "/admin-password-create/";
	      break;
	    case 1:
	      path = "/admin-password-reset/";
	      break;
	    case 2:
	      path = "/representative-password-create/";
	      break;
	    case 3:
	      path = "/representative-password-reset/";
	      break;
	    default:
	      break;
	  }

	  axios
	    .post(path, req) // Use the dynamically determined path
	    .then(() => {
	      if (adminId) {
	        window.location = `/admin`;
	      } else {
	        window.location = `/`;
	      }
	    })
	    .catch((err) => {
	      this.setState({ submitting: false, error: err });

	      setTimeout(() => {
	        this.setState({ error: null });
	      }, 3000);
	    });
	};

  render() {
    const { error, loading, userId, adminId, variety, verified } = this.state;

    const text = varietyLib(variety);

    if (loading) {
      return <div></div>;
    }

    if (!verified && !loading) {
      return (
        <div>
          <FaExclamationCircle size={90} color="#D93030" />
          <h1>Oops!</h1>
					<p>{error}</p>
          <div className="auth-other">
            <Link to="/">Login</Link>
            <Link to="/forgot-password">Forgot Password</Link>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h1>{text.header}</h1>
        <p>{text.body}</p>
        <PasswordForm
          disabled={this.state.submitting || this.state.error}
          error={this.state.error}
          onSubmit={this.onPasswordSubmit}
        />
      </div>
    );
  }
}

export default Code;

const varietyLib = (variety, entity = "Customer") => {
  switch (variety) {
    case 0:
      return {
        header: "Create Admin Password",
        body: "Create the password for your account",
        path: "/admin-password-create/",
      };
    case 1:
      return {
        header: "New Password",
        body: "Create a new password for your account",
        path: "/admin-password-reset/",
      };
    case 2:
      return {
        header: "Create Password",
        body: "Create the password for your account",
        path: "/representative-password-create/",
      };
    case 3:
      return {
        header: "New Password",
        body: "Create a new password for your account",
        path: "/representative-password-reset/",
      };
    default:
      break;
  }
};
