import React from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";

const loaderConfig = {
  type: "ThreeDots",
  height: 10,
  width: 20,
  color: "#fff",
};

const Button = styled.button`
  padding: ${(props) => (props.borderless ? 0 : "12px 16px")};
  line-height: 1.2;
  background: ${(props) =>
    props.primary ? `var(--buttonBackgroundColor)` : "transparent"};
  border-radius: 4px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  border: ${(props) =>
    props.borderless
      ? `1px solid transparent`
      : props.primary
      ? `1px solid var(--buttonBackgroundColor)`
      : "1px solid var(--buttonBackgroundColor)"};
  color: ${(props) =>
    props.primary ? "#fff" : "var(--buttonBackgroundColor)"};
  font-weight: 600;
  font-size: 1rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid transparent; */

  &:focus {
    border: 1px solid #000;
  }
`;

export default function BasicButton({
  borderless,
  primary,
  loading,
  style,
  onClick,
  disabled,
  text,
  type,
	hoverText,
}) {
  return (
    <Button
      onClick={onClick}
      borderless={borderless}
      disabled={disabled}
      style={style}
      primary={primary}
      type={type}
			title={hoverText}
    >
      {loading ? <Loader {...loaderConfig} /> : text}
    </Button>
  );
}

BasicButton.defaultProps = {
  borderless: false,
  disabled: false,
  loading: false,
  primary: false,
  text: "Basic",
  style: {},
  type: "button",
	hoverText: '',
};
