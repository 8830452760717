import React, { useState, useEffect } from "react";
import axios from "axios";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { ContractorPaymentForm } from "@components/ContractorPayment";

export default function ContractorPaymentsDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contractorPayment, setContractorPayment] = useState(populateContractorPayment());

  const contractorPaymentId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/contractor-payments/${contractorPaymentId}/`)
      .then(({ data }) => {
        setLoading(false);
        setContractorPayment(populateContractorPayment(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!contractorPayment) return null;

  return (
    <div>
      <PageTitle
        title={`${contractorPayment.contractor.name}`}
        subtitle="Contractor Payment"
      />
      <Wrapped>
        <ContractorPaymentForm
          id={contractorPaymentId}
          datePaid={contractorPayment.datePaid}
          postedToJp={contractorPayment.postedToJp}
          postedToAch={contractorPayment.postedToAch}
          achReference={contractorPayment.achReference}
          invoices={contractorPayment.invoices}
          status={contractorPayment.status}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateContractorPayment(d = {}) {
  return {
    contractor: d.contractor || "",
    invoices: d.contractor_job_links || [],
    datePaid: d.date_paid || "",
    postedToAch: d.posted_to_ach,
    achReference: d.ach_reference || "",
    status: d.status,
  };
}
