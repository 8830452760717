import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import * as Yup from "yup";
import { BasicForm, BasicTable } from "@components/shared";
import { Icon } from "semantic-ui-react";
import PropTypes from 'prop-types';

import { getRoleInfo } from "@util/roleInfo";

function ManagerPaymentForm({
  id,
  representativeName,
  representativeId,
  datePaid,
  amountFormatted,
  postedToJp,
  postedToAch,
  achReference,
  notes,
  status,
  jobs,
 	selectedManagerPointsLinks,
 	unpaidManagerPointsLinks,
	jobManagerPointsLinks,
  onFormSubmit,
  onSuccess,
  dispatch,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const roleInfo = getRoleInfo(useSelector((state) => state));
  
  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      job_payment_link_ids: selectedManagerPointsLinks,
			representative_id: representativeId,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/manager-payments/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success("Manager Payment Created!");
        onSuccess(data.data.id);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/manager-payments/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Manager Payment Updated!"))
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
	
	const filteredJobManagerPointsLinks = (unpaidManagerPointsLinks || []).filter(link => 
	  selectedManagerPointsLinks.includes(link.id)
	);
	
	const totalAmount = filteredJobManagerPointsLinks.reduce((total, currentLink) => {
	  return total + (currentLink.commission_in_pennies / 100);
	}, 0);
	
  const statusOptions = [
    { text: "Pending", value: 0 },
    { text: "Complete", value: 1 },
  ];

  const fields = [
    {
      grouped: true,
      fields: [
		    {
		      name: "date_paid",
		      initialValue: datePaid
		            ? new Date(datePaid)
		            : null,
		      label: "Date Paid (optional)",
		      datepicker: true,
		      required: false,
		      schema: () =>
		        Yup.string().nullable(),
		    },
				{
		      name: "amount_formatted",
		      initialValue: id ? amountFormatted : totalAmount.toFixed(2),
		      label: "Total Commission Amount",
		      required: true,
		      schema: () => Yup.number().typeError('Amount must be a numbers only.').positive("Negative numbers not allowed").required("Required"),
		    },
      ],
    },
    {
      grouped: true,
      fields: [
        {
          name: "posted_to_ach",
          label: "Posted To ACH",
          initialValue: postedToAch,
          required: true,
          radio: true,
          trueText: "Yes",
          falseText: "No",
          schema: () => null,
        },
        {
          name: "ach_reference",
          initialValue: achReference,
          label: "ACH Reference",
          required: false,
          schema: () => Yup.string().max(20, "Too Long!"),
        },
      ],
    },
    {
      name: "status",
      initialValue: status,
      label: "Status",
      select: true,
      required: true,
      options: statusOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
  ]
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_view_only ? true : false,
    }));
		
	  let columns = [
			{ Header: "Job", accessor: "job.name" },
			{ Header: "Job Total", accessor: "job.job_total_formatted" },
			{ Header: "Total Cost", accessor: "job.total_cost_formatted" },
			{ Header: "Profit %", accessor: "job.profit_margin_percentage" },
	    { Header: "Points %", accessor: "points_percentage" },
	    { Header: "Commission", accessor: "points_commission_formatted" },
	  ];

  return (
    <React.Fragment>
      <div style={{ margin: "30px 10px 10px 0" }}>
		  <BasicTable
		    fetch={false}
		    data={id ? jobManagerPointsLinks : filteredJobManagerPointsLinks}
		    loading={isLoading}
		    columns={columns}
		    entityName="Jobs"
		  />
      </div>
      <div>
        <BasicForm
          error={error}
          fields={fields}
          loading={isLoading}
          onSubmit={onSubmit}
        />
      </div>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    representativeId: state.representative ? state.representative.id : null,
  };
}

export default connect(mapStateToProps)(ManagerPaymentForm);

ManagerPaymentForm.propTypes = {
  selectedManagerPointsLinks: PropTypes.array,
  unpaidManagerPointsLinks: PropTypes.array,
  jobManagerPointsLinks: PropTypes.array,
};

ManagerPaymentForm.defaultProps = {
  selectedManagerPointsLinks: [],
  unpaidManagerPointsLinks: [],
  jobManagerPointsLinks: [],
  datePaid: null,
  id: null,
  postedToJp: null,
  postedToAch: null,
  achReference: "",
  status: 0,
};
