import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { connect } from "react-redux";

import { Button, Message } from "@components/shared";

function PasswordForm(props) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const { change } = props;

  function onSubmit(e) {
    e.preventDefault();
    props.onSubmit({ newPassword, confirmedPassword });
  }

  return (
    <div>
      <form className="ui form auth-form">
        <div className="form field">
          {change && (
            <label style={{ marginTop: "1em", marginBottom: "0" }}>
              New Password
            </label>
          )}
          <input
            type="password"
            name="newPassword"
            placeholder={change ? "" : "New Password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={{ marginTop: change ? "0" : "auto" }}
          />
        </div>
        <div className="form field">
          {change && (
            <label style={{ marginTop: "1em", marginBottom: "0" }}>
              Confirm New Password
            </label>
          )}
          <input
            type="password"
            name="confirmedPassword"
            placeholder={change ? "" : "New Password Confirmation"}
            value={confirmedPassword}
            onChange={(e) => setConfirmedPassword(e.target.value)}
            style={{ marginTop: change ? "0" : "auto" }}
          />
        </div>
        {props.error && (
          <Fade bottom>
            <Message negative text={props.error} />
          </Fade>
        )}
        <Button.Basic
          primary
          type="submit"
          text="Submit"
          disabled={
            !change
              ? !confirmedPassword ||
                newPassword !== confirmedPassword ||
                props.disabled
              : !confirmedPassword || props.disabled
          }
          onClick={onSubmit}
          loading={props.loading || false}
        />
      </form>
    </div>
  );
}

PasswordForm.defaultProps = {
  change: false,
  loading: false,
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(PasswordForm);
