import React from "react";
import styled from "styled-components";
import { size, device } from "@util/breakpoints";

const StyledWrapper = styled.div`
  width: 90%;
  margin: 20px auto;

  @media ${device.desktop} {
    max-width: 1600px;
  }

  @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
  }

  @media (min-width: 770px) and (max-width: 920px) {
    width: 98%;
  }

  @media (max-width: ${size.tablet}) {
    width: 98%;
    max-width: 98%;
  }

  & + & {
    margin-top: 2em;
  }

  .page-title {
    font-size: 21px;
    text-transform: capitalize;
    display: flex;
    align-items: center;

    button {
      margin-bottom: 0 !important;
      margin-left: 10px;
    }
  }
`;

export default function Wrapped(props) {
  return (
    <StyledWrapper>
      {props.title && (
        <h1 className="page-title">
          {props.title} {props.button ? props.button : null}
        </h1>
      )}
      {props.children}
    </StyledWrapper>
  );
}

Wrapped.defaultProps = {
  title: "",
  style: {},
};
