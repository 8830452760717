import React, { useState, useEffect } from "react";
import axios from "axios";
import { Icon } from "semantic-ui-react";
import { Card } from "@components/shared";

export default function AchPaymentReport({
  representativeId,
}) {
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [achPaymentReport, setAchPaymentReport] = useState(populateAchPaymentReport());
  
  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    const req = {
      representative_id: representativeId,
    };

    axios
      .post(`/representative-ach-payment-totals/`, req)
      .then(({ data }) => {
        setLoading(false);
				setAchPaymentReport(populateAchPaymentReport(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
  
  return (
    <React.Fragment>
      <Card.Wrapper>
        <Card.Meta
          title={`Year To Date ACH Payment Breakdown`}
          stats={[
            { 
              title: "Commissions", 
              value: achPaymentReport.representativePayments
            },
            { 
              title: "Manager Points", 
              value: achPaymentReport.managerPayments
            },
            { title: "Salary Payments", value: achPaymentReport.representativeSalaryPayments },
            { title: "Knocking Commissions", value: achPaymentReport.knockingPayments },
            { title: "Reimbursements", value: achPaymentReport.representativeReimbursements },
            { title: "Representative Bonuses", value: achPaymentReport.representativeBonuses },
            { title: "Corrections", value: achPaymentReport.representativeCorrections },
						{ title: "Draws", value: achPaymentReport.representativeDraws },
						{ 
							title: "Draw Collections",
							value: achPaymentReport.drawCollections
						},
          ]}
          hasActions={false}
        />
      </Card.Wrapper>
    </React.Fragment>
  );
}

function populateAchPaymentReport(d = {}) {
  return {
    representativePayments: d.representative_payments || "",
    managerPayments: d.manager_payments || "",
    representativeSalaryPayments: d.representative_salary_payments,
    knockingPayments: d.knocking_payments,
    representativeReimbursements: d.representative_reimbursements,
    representativeBonuses: d.representative_bonuses,
		representativeCorrections: d.representative_corrections,
    representativeDraws: d.representative_draws || "",
    drawCollections: d.draw_collections || "",
    };
}
