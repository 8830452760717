import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { BasicForm, Button } from "@components/shared";
import { withRouter } from 'react-router-dom';

import { getRoleInfo } from "@util/roleInfo";

function JobForm({
  jobType,
  jobClassification,
  name,
  leapJobId,
	leapJobIdOne,
	leapJobIdTwo,
	leapJobIdThree,
  invoiceNumber,
  paidLead,
	paidLeadSource,
  noBonus,
	bonusReason,
  totalCostFormatted,
  jobTotalFormatted,
  squares,
  paid,
  datePaid,
  id,
  postedToJp,
  postedToAch,
  achReference,
  notes,
	deleteable,
	deleteConfirmationMessage,
  onSuccess,
  onFormSubmit,
	history,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
	const [paidLeadSources, setPaidLeadSources] = useState([]);
	
  useEffect(() => {
    fetchPaidLeadSourceOptions();
  }, []);

  const roleInfo = getRoleInfo(useSelector((state) => state));
	
	

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/jobs/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success("Job Created!");
        onSuccess(data.data.id);
				history.push(`/jobs/${data.data.id}/representatives`);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      job_id: id,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/jobs/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Job Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
	
	function fetchPaidLeadSourceOptions() {
	  axios.get("/paid-lead-sources/")
	    .then(({ data }) => {
	      setPaidLeadSources(
	        Object.keys(data).map(key => ({
	          text: data[key],
	          value: parseInt(key, 10)
	        }))
	      );
	    })
	    .catch(error => {
	      // Handle the error here, if needed
	      console.error("Error fetching paid lead sources:", error);
	    });
	}

  const typeOptions = [
    { text: "Insurance", value: 0 },
    { text: "Full Roof Retail", value: 1 },
    { text: "Repair Retail", value: 2 },
  ];

  const classificationOptions = [
    { text: "Residential", value: 0 },
    { text: "Commercial", value: 1 },
  ];
	
  const bonusReasonOptions = [
		{ text: "None", value: null },
    { text: "Margin", value: 0 },
    { text: "Customer Type", value: 1 },
    { text: "Discresionary", value: 2 },
  ];

  const fields = [
    {
      name: "name",
      initialValue: name,
      label: "Name",
      required: true,
      schema: () => Yup.string().required("Required"),
    },
	  ...(id ? [
	    {
	      name: "leap_job_id",
	      initialValue: leapJobId,
	      label: "Leap Job ID",
	      required: true,
	      schema: () => Yup.string().required("Required"),
	    }
	  ] : [
	    {
	      grouped: true,
	      fields: [
	        {
	          name: "leap_job_id_one",
	          initialValue: leapJobIdOne,
	          label: "Leap 4",
	          required: true,
	          schema: () => Yup.string().max(4, "Too Long!").required("Required"),
	        },
	        {
	          name: "leap_job_id_two",
	          initialValue: leapJobIdTwo,
	          label: "Leap 7",
	          required: true,
	          schema: () => Yup.string().max(7, "Too Long!").required("Required"),
	        },
	        {
	          name: "leap_job_id_three",
	          initialValue: leapJobIdThree,
	          label: "Leap Job ID",
	          required: true,
	          schema: () => Yup.string().max(8, "Too Long!").required("Required"),
	        },
	      ],
	    }
	  ]),
    {
      grouped: true,
      fields: [
		    {
		      name: "paid_lead",
		      label: "Paid Lead",
		      initialValue: paidLead,
		      required: true,
		      radio: true,
		      trueText: "Yes",
		      falseText: "No",
		      schema: () => null,
		    },
		    {
		      name: "paid_lead_source",
		      initialValue: paidLeadSource,
		      label: "Paid Lead Source",
		      select: true,
		      required: true,
		      options: paidLeadSources,
		      schema: () => Yup.number().nullable().required("Required"),
		    },
      ],
    },
    {
      grouped: true,
      fields: [
		    {
		      name: "no_bonus",
		      label: "No Bonus",
		      initialValue: noBonus,
		      required: true,
		      radio: true,
		      trueText: "Yes",
		      falseText: "No",
		      schema: () => null,
		    },
		    {
		      name: "bonus_reason",
		      initialValue: bonusReason,
		      label: "No Bonus Reason",
		      select: true,
		      required: false,
		      options: bonusReasonOptions,
		      schema: () => Yup.number().nullable(),
		    },
      ],
    },
    {
      name: "job_type",
      initialValue: jobType,
      label: "Type",
      select: true,
      required: true,
      options: typeOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
    {
      name: "job_classification",
      initialValue: jobClassification,
      label: "Classification",
      select: true,
      required: true,
      options: classificationOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
    {
      name: "job_total_formatted",
      initialValue: jobTotalFormatted,
      label: "Job Total",
      required: true,
      schema: () => Yup.number().typeError('Amount must be a numbers only.').min(0, 'Must be zero or positive').required("Required"),
    },
    {
      name: "total_cost_formatted",
      initialValue: totalCostFormatted,
      label: "Total Cost",
      required: true,
      schema: () => Yup.number().typeError('Amount must be a numbers only.').min(0, 'Must be zero or positive').required("Required"),
    },
    {
      name: "squares",
      initialValue: squares,
      label: "Squares",
      required: false,
      schema: () => Yup.number().typeError('Amount must be a numbers only.').positive("Negative numbers not allowed"),
    },
    {
      grouped: true,
      fields: [
        {
          name: "paid",
          label: "Job Paid In Full",
          initialValue: paid,
          required: true,
          radio: true,
          trueText: "Yes",
          falseText: "No",
          schema: () => null,
        },
        {
          name: "date_paid",
          initialValue: datePaid
                ? new Date(datePaid)
                : null,
          label: "Pay Contractors After (optional)",
          datepicker: true,
          required: false,
          schema: () =>
            Yup.string().nullable(),
        },
      ],
    },
    {
      name: "notes",
      initialValue: notes,
      label: "Notes",
      required: false,
      schema: () => Yup.string().max(50, "Too Long!"),
    },
  ]
  .map((m) => ({
    ...m,
    readOnly: id && roleInfo.admin_view_only ? true : false,
  }));

  return (
    <div>
      <BasicForm
        error={error}
        fields={fields}
        loading={isLoading}
        onSubmit={onSubmit}
      />
			<DeleteComponent jobId={id} deleteable={deleteable} deleteConfirmationMessage={deleteConfirmationMessage} />
    </div>
  );
}

JobForm.defaultProps = {
  jobType: "",
  jobClassification: "",
	paidLeadSource: 0,
  name: "",
  leapJobId: "",
  invoiceNumber: "",
  paidLead: false,
  noBonus: false,
	bonusReason: null,
  totalCostFormatted: "",
  JobTotalFormatted: "",
  squares: "",
  paid: false,
  datePaid: null,
  id: null,
  postedToJp: false,
  postedToAch: false,
  achReference: "",
  notes: "",
  onSuccess: () => alert("On Success"),
};


export default withRouter(JobForm);

export function DeleteComponent({ history, deleteConfirmationMessage, deleteable, jobId }) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
	
  const handleDelete = () => {
	  const req = {
	    job_id: jobId,
	  };

    setLoading(true);
    setError(null);

    axios
      .post(`/delete-job/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success("Job Deleted!");
				window.location = `/jobs`;
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const confirmDelete = () => {
    // Show a confirmation dialog with the custom message
    if (window.confirm(deleteConfirmationMessage || "This delete is permanent, but will not affect any other records.")) {
      handleDelete();
    }
  };

  return (
    <div>
		  <Button.Basic
		    secondary
		    text="Delete"
				hoverText={deleteConfirmationMessage}
		    onClick={confirmDelete}
				disabled={!deleteable}
		    style={{ margin: "10px 10px 10px 0" }}
		  />
    </div>
  );
}