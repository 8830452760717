import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { Wrapped } from "@components/Layouts";
import { Notes, PageTitle, ViewError } from "@components/shared";
import { JobForm, JobRepresentativeManagement, JobKnockingManagement, JobBreakdown } from "@components/Job";
import { RepresentativeJobForm } from "@components/RepresentativeJob";
import { getRoleInfo } from "@util/roleInfo";

export default function AssignedJobsDetail({ isAdmin, match, history }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [job, setJob] = useState(populateJob());

  const jobId = match.params.id;
  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/jobs/${jobId}/`)
      .then(({ data }) => {
        setLoading(false);
        setJob(populateJob(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!job) return null;

  return (
    <div>
      <PageTitle
        title={job.name}
        subtitle="Job"
        links={[
          { text: "Detail", to: match.url },
          { text: "Representatives", to: `${match.url}/representatives` },
					{ text: "Knockings", to: `${match.url}/knockings` },
          { text: "Breakdown", to: `${match.url}/breakdown` },
        ]
          .filter((f) => (!isAdmin ? !f.admin : true))
          .map((l, i) => ({ ...l, key: i + 1 }))}
      />
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <Wrapped>
              <JobForm
                onSuccess={fetchData}
                id={jobId}
                name={job.name}
                leapJobId={job.leapJobId}
                jobType={job.jobType}
                jobClassification={job.jobClassification}
                paidLead={job.paidLead}
								paidLeadSource={job.paidLeadSource}
                noBonus={job.noBonus}
								bonusReason={job.bonusReason}
                invoiceNumber={job.invoiceNumber}
                totalCostFormatted={job.totalCostFormatted}
                jobTotalFormatted={job.jobTotalFormatted}
                squares={job.squares}
                paid={job.paid}
                datePaid={job.datePaid}
                postedToJp={job.postedToJp}
                postedToAch={job.postedToAch}
                achReference={job.achReference}
                notes={job.notes}
								deleteable={job.deleteable}
								deleteConfirmationMessage={job.deleteConfirmationMessage}
              />
            </Wrapped>
          </React.Fragment>
        </Route>
        <Route path={`${match.path}/representatives`}>
          <Wrapped>
              <JobRepresentativeManagement jobId={jobId} jobName={job.name} jobType={job.jobType} jobTypeName={job.jobTypeName} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/knockings`}>
          <Wrapped>
              <JobKnockingManagement jobId={jobId} jobName={job.name} jobType={job.jobType} jobTypeName={job.jobTypeName} />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/breakdown`}>
          <Wrapped>
              <JobBreakdown jobId={jobId} />
          </Wrapped>
        </Route>
      </Switch>
    </div>
  );
}

AssignedJobsDetail.defaultProps = {
  admin: false,
};

function populateJob(d = {}) {
  return {
		name: d.name || "",
    leapJobId: d.leap_job_id || "",
    jobType: d.job_type,
		jobTypeName: d.job_type_formatted || "",
    jobClassification: d.job_classification,
    paidLead: d.paid_lead || false,
		paidLeadSource:  d.paid_lead_source,
    noBonus: d.no_bonus || false,
		bonusReason: d.bonus_reason,
    invoiceNumber: d.invoice_number || "",
    totalCostFormatted: d.total_cost_formatted || "",
    jobTotalFormatted: d.job_total_formatted || "",
    squares: d.squares || "",
    paid: d.paid || false,
    datePaid: d.date_paid || "",
    postedToAch: d.posted_to_ach || false,
    achReference: d.ach_reference || "",
    notes: d.notes || "",
		deleteable: d.deleteable,
		deleteConfirmationMessage: d.confirm_delete_message,
  };
}
