import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import * as Yup from "yup";
import { BasicForm, Button } from "@components/shared";
import { getRoleInfo } from "@util/roleInfo";

function RepresentativeManagerEditForm({
  managerLink,
  onFormSubmit,
  onSuccess,
  dispatch,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const roleInfo = getRoleInfo(useSelector((state) => state));
	
	console.log(managerLink);

  function onSubmit(formData) {
    if (managerLink) return onUpdate(formData);
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/manager-links/${managerLink.id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Manager Updated for Representative!"))
      .then((newId) => {
        onSuccess(newId);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
  
  const statusOptions = [
    { text: "Active", value: 0 },
    { text: "Inactive", value: 1 },
  ];

  const fields = [
    {
      name: "manager_points_percentage",
      initialValue: managerLink.manager_points_percentage,
      label: "Manager Points %",
      required: true,
      schema: () =>
        Yup.number().typeError('Amount must be a numbers only.').required("Required"),
    },
    {
      name: "status",
      label: "Status",
      initialValue: managerLink.status,
      select: true,
      options: statusOptions,
      required: true,
      schema: () => Yup.number().nullable().required("Required"),
    },
  ]
    .map((m) => ({
      ...m,
      readOnly: managerLink && roleInfo.admin_view_only ? true : false,
    }));

    return (
				<div>
					{managerLink && (
	          <React.Fragment>
							<h3>Edit Manager Settings</h3>
	            <div>
	              <BasicForm
	                error={error}
	                fields={fields}
	                loading={isLoading}
	                onSubmit={onSubmit}
	              />
	            </div>
	          </React.Fragment>
						)}
	      </div>
    );
  }

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(RepresentativeManagerEditForm);

RepresentativeManagerEditForm.defaultProps = {
  onSuccess: () => alert("On Success"),
  managerLink: null,
};
