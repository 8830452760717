import React, { useState } from "react";
import { RepresentativeTable } from "@components/RepresentativeDraw";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

export default function DrawsIndex() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Draws"
      >
        <RepresentativeTable representativeView={true} />
      </Wrapped>
      
    </div>
  );
}