import React, { useState } from "react";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";
import { JobsDetail } from "../representative";
import { JobForm } from "@components/Job";

export default function Detail(props) {
	 const [isOpen, setOpen] = useState(false);
		
  return (
    <div>
      <Wrapped
        title="Jobs"
        button={
          <Button.Toggle
            closedText="Add Job"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        }
      >
        {isOpen ? <JobForm onSuccess={() => setOpen(false)} /> : <JobsDetail isAdmin {...props} />}
      </Wrapped>
    </div>
  );
}