import React from "react";
import { Dropdown } from "semantic-ui-react";

export default function FilterSelect({
  entityName,
  onChange,
  value,
  options,
  filterText,
}) {
  const filterInfo = filterText || `Filtering ${entityName} by:`;

  return (
    <div
      style={{
        marginBottom: "20px",
      }}
    >
      {filterInfo}
      <Dropdown
        onChange={(e, d) => onChange(d.value)}
        options={options}
        style={{
          marginLeft: "5px",
        }}
        value={value}
      />
    </div>
  );
}

FilterSelect.defaultProps = {
  entityName: "Entities",
  value: null,
  options: [],
};
