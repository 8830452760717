import React from "react";
import { Wrapped } from "@components/Layouts";
import Dashboard from "@components/Dashboard";

export default function DashboardPage() {
  return (
    <React.Fragment>
      <Wrapped title="Dashboard">
        <Dashboard />
      </Wrapped>
    </React.Fragment>
  );
}
