import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

import { SET_ADMIN } from "@duck/admin";
import { SET_REPRESENTATIVE } from "@duck/representative";

import { Admin, Auth, Representative } from "./containers/";

class PayrollApp extends Component {
  state = {
    error: null,
    loading: true,
  };

  componentDidMount() {
    axios
      .get("/status/")
      .then(({ data }) => {
        if (data.admin) {
          return this.props.dispatch({
            type: SET_ADMIN,
            payload: data.admin,
          });
        }

        if (data.representative) {
          return this.props.dispatch({
            type: SET_REPRESENTATIVE,
            payload: data.representative,
          });
        }
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false, error: "Status Error" });
      });
  }

  render() {
    const { error, loading } = this.state;
    const { admin, representative } = this.props;

    if (error) {
      return <Auth error={true} />;
    }

    if (error) {
      return <Auth error={true} />;
    }

    if (loading) {
      return <div></div>;
    }

    if (!loading && !admin && !representative) {
      return <Auth />;
    }

    if (admin) {
      return <Admin />;
    }

    if (representative) {
      return <Representative representativeId={representative.id} />;
    }

    return <div></div>;
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
    representative: state.representative,
  };
}
export default connect(mapStateToProps)(PayrollApp);
