import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { FaExchangeAlt } from "react-icons/fa";
import { Modal } from "semantic-ui-react";
import styled from "styled-components";

import { BasicGrid, Button } from "@components/shared";

function UserSetPractice({ activeRole, practices, sessionPracticeId }) {
  const [isOpen, setOpen] = useState(false);

  if (practices.length <= 1) return null;

  useEffect(() => {
    if (!sessionPracticeId) {
      setOpen(true);
    }
  }, [sessionPracticeId]);

  function onClose() {
    if (!sessionPracticeId) return;
    setOpen(false);
  }

  function onSwitchClick(practiceId) {
    axios
      .get(`/practices/${practiceId}/set-session/`)
      .then(() => {
        window.location = "/dashboard";
      })
      .catch((err) => {
        window.location.reload();
      });
  }

  const activePractice =
    sessionPracticeId &&
    practices.find((f) => f.practice_id === sessionPracticeId)
      ? practices.find((f) => f.practice_id === sessionPracticeId)
      : null;

  return (
    <React.Fragment>
      <SwitchButton title="Change Practice" onClick={() => setOpen(true)}>
        <FaExchangeAlt />{" "}
        {activePractice && <span>{activePractice.practice_name}</span>}
        {activeRole && <span>{activeRole}</span>}
      </SwitchButton>
      <Modal open={isOpen} onClose={onClose}>
        <Modal.Content>
          <BasicGrid columns={1}>
            {practices.map((p) => (
              <PracticeItem key={p.practice_id}>
                <div className="text">
                  <h3>{p.practice_name}</h3>
                  {p.role && <p>{p.role}</p>}
                </div>
                <Button.Basic
                  primary
                  disabled={
                    activePractice &&
                    activePractice.practice_id === p.practice_id
                  }
                  text={
                    activePractice &&
                    activePractice.practice_id === p.practice_id
                      ? "Currently Viewing"
                      : "View Practice"
                  }
                  onClick={() => onSwitchClick(p.practice_id)}
                />
              </PracticeItem>
            ))}
          </BasicGrid>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    activeRole: state.user ? state.user.role_formatted : null,
    practices: state.user ? state.user.active_practices : [],
    sessionPracticeId: state.user ? state.user.session_practice_id : null,
  };
}

export default connect(mapStateToProps)(UserSetPractice);

const PracticeItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  .text {
    flex-grow: 1;
    width: 80%;
  }

  h3 {
    margin: 0;
  }

  & + & {
    border-top: 1px solid #eee;
  }
`;

const SwitchButton = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    font-weight: bold;
    font-size: 16px;
    margin-top: 8px;
  }
`;
