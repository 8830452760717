import React from "react";

import { DoughnutChart, ReportComponent } from "./";
import { BasicGrid } from "@components/shared";
import { getUuid } from "@util/getUuid";
import { getRgbaColors } from "@util/colors";

export default function ProcedureBreakdown({ data }) {
  if (!data) return null;

  const blocks = [
    { text: "Axial Length", value: data.avg_axial_length },
    { text: "Duration", value: data.avg_duration },
    { text: "IOP", value: data.avg_iop },
    { text: "Power", value: data.avg_power },
    { text: "Refraction", value: data.avg_refraction },
  ].map((m) => ({ ...m, key: getUuid() }));

  return (
    <div>
      <ReportComponent.Title>Averages</ReportComponent.Title>
      <BasicGrid columns={5}>
        {blocks.map((m) => (
          <ReportComponent.ValueBlock key={m.key}>
            <h1>{m.value}</h1>
            <p>{m.text}</p>
          </ReportComponent.ValueBlock>
        ))}
      </BasicGrid>
      <BasicGrid columns={2}>
        <ReportComponent.ChartWrapper>
          <ReportComponent.Title>BCVA Method</ReportComponent.Title>
          <DoughnutChart
            data={{
              labels: Object.keys(data.bcva_method),
              datasets: [
                {
                  data: Object.keys(data.bcva_method).map(
                    (m) => data.bcva_method[m]
                  ),
                  backgroundColor: getRgbaColors(12),
                },
              ],
            }}
          />
        </ReportComponent.ChartWrapper>
        <ReportComponent.ChartWrapper>
          <ReportComponent.Title>Laser Parameter</ReportComponent.Title>
          <DoughnutChart
            data={{
              labels: Object.keys(data.laser_parameter),
              datasets: [
                {
                  data: Object.keys(data.laser_parameter).map(
                    (m) => data.laser_parameter[m]
                  ),
                  backgroundColor: getRgbaColors(159),
                },
              ],
            }}
          />
        </ReportComponent.ChartWrapper>
        <ReportComponent.ChartWrapper>
          <ReportComponent.Title>Lens Status</ReportComponent.Title>
          <DoughnutChart
            data={{
              labels: Object.keys(data.lens_status),
              datasets: [
                {
                  data: Object.keys(data.lens_status).map(
                    (m) => data.lens_status[m]
                  ),
                  backgroundColor: getRgbaColors(269),
                },
              ],
            }}
          />
        </ReportComponent.ChartWrapper>
        <ReportComponent.ChartWrapper>
          <ReportComponent.Title>Sweep Count</ReportComponent.Title>
          <DoughnutChart
            data={{
              labels: Object.keys(data.sweep_count),
              datasets: [
                {
                  data: Object.keys(data.sweep_count).map(
                    (m) => data.sweep_count[m]
                  ),
                  backgroundColor: getRgbaColors(345),
                },
              ],
            }}
          />
        </ReportComponent.ChartWrapper>
        <ReportComponent.ChartWrapper>
          <ReportComponent.Title>Sweep Velocity</ReportComponent.Title>
          <DoughnutChart
            data={{
              labels: Object.keys(data.sweep_velocity),
              datasets: [
                {
                  data: Object.keys(data.sweep_velocity).map(
                    (m) => data.sweep_velocity[m]
                  ),
                  backgroundColor: getRgbaColors(569),
                },
              ],
            }}
          />
        </ReportComponent.ChartWrapper>
        <ReportComponent.ChartWrapper>
          <ReportComponent.Title>Technique</ReportComponent.Title>
          <DoughnutChart
            data={{
              labels: Object.keys(data.technique),
              datasets: [
                {
                  data: Object.keys(data.technique).map(
                    (m) => data.technique[m]
                  ),
                  backgroundColor: getRgbaColors(39),
                },
              ],
            }}
          />
        </ReportComponent.ChartWrapper>
      </BasicGrid>
    </div>
  );
}
