import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Icon } from "semantic-ui-react";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { BasicTable } from "@components/shared";

export default function Points({
  actionsDisabled,
  editing,
  representativeId,
  selected,
  onChange,
  fetchData,
	selectedManagerPoints,
	setSelectedManagerPoints,
	unpaidManagerPoints, 
	setUnpaidManagerPoints,
}) {
  const [loading, setLoading] = useState(false);

	useEffect(() => {
	  fetchManagerPoints();
	}, []);

  function fetchManagerPoints() {
    const req = {
      representative_id: representativeId,
      status: false,
    };

    setLoading(true);

    axios
      .post(`/representative-job-manager-points-links/`, req)
      .then(({ data }) => {
				setUnpaidManagerPoints(data.data);
      })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }
	
  function onAddLinkToPaymentClick(managerPointsToAdd) {
		setSelectedManagerPoints(prevLinks => [...prevLinks, managerPointsToAdd]);
  }
	
	function onRemoveLinkToPaymentClick(managerPointsToRemove) {
	  setSelectedManagerPoints(prevLinks =>
	    prevLinks.filter(managerPoints => managerPoints !== managerPointsToRemove)
	  );
	}
	
  let columns = [
    {
      Header: "",
      width: 35,
      id: "inv",
      accessor: (d) => {
				const selected = selectedManagerPoints.includes(d.id);
 
        if (selected) {
            return (
              <Button
                icon="check"
                color="green"
                style={{ transform: "scale(0.7)" }}
                circular
                loading={loading}
                size="mini"
                title="Remove"
                onClick={() => onRemoveLinkToPaymentClick(d.id)}
              />
            );
        }

        if (!selected) {
          return (
            <Button
              icon="plus"
              style={{ transform: "scale(0.7)" }}
              circular
              basic
              loading={loading}
              size="mini"
              title="Add"
              onClick={() => onAddLinkToPaymentClick(d.id)}
            />
          );
        }

        return (
          <Form.Checkbox
            checked={selected.includes(d.id)}
            onChange={() => onChange(d.id, d)}
          />
        );
      },
    },
		{ Header: "Job", accessor: "job.name", width: 300 },
		{ Header: "Representative", accessor: "representative.full_name", width: 300 },
		{ Header: "Pay After", accessor: "job.date_paid_formatted", width: 120 },
		{ Header: "Leap ID", accessor: "job.leap_job_id", width: 150 },
		{ Header: "Job Total", accessor: "job.job_total_formatted", width: 120 },
		{ Header: "Total Cost", accessor: "job.total_cost_formatted", width: 120 },
		{ Header: "Profit %", accessor: "job.profit_margin_percentage", width: 120 },
	  { Header: "Points %", accessor: "points_percentage", width: 120 },
		{ Header: "Commission", accessor: "points_commission_formatted", width: 120 },
  ];

  return (
    <div style={{ marginTop: "15px" }}>
      <BasicTable
        fetch={false}
        data={unpaidManagerPoints}
        loading={loading}
        columns={columns}
        entityName="Manager Points"
      />
    </div>
  );
}

Points.defaultProps = {
  actionsDisabled: false,
  editing: false,
  selected: [],
};
