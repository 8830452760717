import React from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { FaUserMinus, FaRegTimesCircle } from "react-icons/fa";

const Button = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0 10px 0 10px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  svg {
    font-size: 24px;
    fill: var(--buttonBackgroundColor);
  }
`;

export default function ClearButton({
  person,
  disabled,
  onClick,
}) {
  return (
    <Button
      onClick={onClick}
      open={open}
      disabled={disabled}
    >
      {person ? <FaUserMinus /> : <FaRegTimesCircle />}
    </Button>
  );
}

ClearButton.defaultProps = {
  disabled: false,
  edit: false,
};