import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { FaExclamationCircle } from "react-icons/fa";
import { defaults } from "chart.js";

import {
  GlaucomaDiagnosis,
  MeanIopMeds,
  PatientDemographics,
  PostopComplications,
  PreviousOcularSurgery,
  ProcedureBreakdown,
  ReportSelect,
  StatisticalOverview,
} from "./";
import { reportTypes } from "./ReportSelect";

defaults.font.family =
  "Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif";

export default function ReportContainer({
  queryContainerId,
  visits,
  visitMode,
}) {
  const [reportType, setReportType] = useState(1);
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [reportType]);

  function fetchData() {
    setLoading(true);
    setError(null);
    setReportData(null);

    const req = {
      report_type: reportType,
      query_container_id: queryContainerId,
      visits,
      consistent_cohort: visitMode === visitValues.consistent,
    };

    axios
      .post(`/reports/`, req)
      .then(({ data }) => {
        setLoading(false);
        setReportData(data);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        setReportData(null);
      });
  }

  return (
    <div>
      <ReportSelect
        onChange={(e) => {
          setReportType(e);
          setLoading(true);
        }}
        value={reportType}
      />
      <ReportWrapper>
        {loading ? (
          <div>Loading</div>
        ) : (
          <React.Fragment>
            {reportData && reportData.n === 0 ? (
              <p className="error-msg">
                <b>n = 0</b>
              </p>
            ) : (
              <React.Fragment>
                {!error ? (
                  <React.Fragment>
                    {reportData && reportData.n && (
                      <p>
                        <b>
                          n = {reportData.n}
                          {reportData.n &&
                            reportData.n_difference &&
                            `, n difference = ${reportData.n_difference}`}
                        </b>
                      </p>
                    )}
                    {reportType === reportTypes.demographics && (
                      <PatientDemographics data={reportData} />
                    )}
                    {reportType === reportTypes.glaucoma_diagnosis && (
                      <GlaucomaDiagnosis data={reportData} />
                    )}
                    {reportType === reportTypes.previous_ocular_surgery && (
                      <PreviousOcularSurgery data={reportData} />
                    )}
                    {reportType === reportTypes.procedure_breakdown && (
                      <ProcedureBreakdown data={reportData} />
                    )}
                    {reportType === reportTypes.post_op_complications && (
                      <PostopComplications data={reportData} />
                    )}
                    {reportType === reportTypes.mean_iop_and_meds && (
                      <MeanIopMeds data={reportData} />
                    )}
                    {reportType === reportTypes.statistical_overview && (
                      <StatisticalOverview data={reportData} />
                    )}
                  </React.Fragment>
                ) : (
                  <p className="error-msg">
                    <FaExclamationCircle /> {error}
                  </p>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </ReportWrapper>
    </div>
  );
}

const ReportWrapper = styled.div`
  margin: 2em 0;

  p.error-msg {
    display: flex;
    align-items: center;
    font-size: 1.15em;
    font-weight: bold;
    margin: 0;

    svg {
      margin-right: 8px;
      color: var(--negativeColor);
    }
  }
`;
