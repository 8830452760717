import React from "react";
import styled from "styled-components";

import { Wrapped } from "@components/Layouts";
import { Subnav } from "./";

const Header = styled.div`
  margin-bottom: ${(props) => (props.hasSubnav ? "1em" : "0")};

  h1 {
    font-size: 3em;
    margin-bottom: 0;
  }

  p {
    /* color: #702586; */
    font-size: 1.25em;
  }
`;

export default function PageTitle({ title, subtitle, links }) {
  return (
    <Wrapped>
      <Header hasSubnav={links.length > 0}>
        <h1>{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </Header>
      {links.length > 0 && <Subnav links={links} />}
    </Wrapped>
  );
}

PageTitle.defaultProps = {
  title: "Page Title",
  subtitle: "",
  links: [],
};
