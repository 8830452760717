import React from "react";
import styled from "styled-components";

import { size, device } from "@util/breakpoints";

const Wrapper = styled.div`
  display: block;
  position: relative;
  /* height: 100%; */
  margin: 20px 50px 20px 220px;
  padding-bottom: 85px;

  @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
    margin-left: 0;
  }

  @media (max-width: ${size.tablet}) {
    margin: 0 10px;
    padding: 0;
  }

  @media (min-width: ${size.tablet}) and (max-width: 900px) {
  }
`;

export default function AppView({ children }) {
  return <Wrapper>{children}</Wrapper>;
}
