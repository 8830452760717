import React, { useState, useEffect } from "react";
import axios from "axios";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { ContractorInvoiceForm } from "@components/ContractorInvoice";

export default function ContractorInvoicesDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contractorInvoice, setContractorInvoice] = useState(populateContractorInvoice());

  const contractorInvoiceId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/contractor-job-links/${contractorInvoiceId}/`)
      .then(({ data }) => {
        setLoading(false);
        setContractorInvoice(populateContractorInvoice(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!contractorInvoice) return null;

  return (
    <div>
      <PageTitle
        title={`${contractorInvoice.invoiceNumber} for ${contractorInvoice.contractor.name} on ${contractorInvoice.job.name}`}
        subtitle="Invoice"
      />
      <Wrapped>
        <ContractorInvoiceForm
          id={contractorInvoiceId}
          invoiceType={contractorInvoice.invoiceType}
          invoiceNumber={contractorInvoice.invoiceNumber}
          invoiceDate={contractorInvoice.invoiceDate}
          amountFormatted={contractorInvoice.amountFormatted}
          paid={contractorInvoice.paid}
          notes={contractorInvoice.notes}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateContractorInvoice(d = {}) {
  return {
    contractor: d.contractor || "",
    job: d.job || "",
    invoiceType: d.invoice_type,
    invoiceNumber: d.invoice_number || "",
    invoiceDate: d.invoice_date || "",
    amountFormatted: d.amount_formatted || "",
    paid: d.paid,
    notes: d.notes || "",
  };
}
