import React from "react";

class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };

  componentDidCatch = (error, info) => {
    this.setState({
      error,
      errorInfo: info,
    });
  };

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <details>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
