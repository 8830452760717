import React from "react";
import DatePicker from "react-datepicker";

export default function DateField(props) {
  return <DatePicker {...props} />;
}

DateField.defaultProps = {
  placeholderText: "Select a date...",
  showYearDropdown: true,
  dropdownMode: "select",
};
