import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { RepresentativeReimbursementForm } from "@components/RepresentativeReimbursement";

export default function RepresentativeReimbursementsDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [representativeReimbursement, setRepresentativeReimbursement] = useState(populateRepresentativeReimbursement());

  const representativeReimbursementId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/representative-reimbursements/${representativeReimbursementId}/`)
      .then(({ data }) => {
        setLoading(false);
        setRepresentativeReimbursement(populateRepresentativeReimbursement(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!representativeReimbursement) return null;

  return (
    <div>
      <PageTitle
        title={`${representativeReimbursement.representative.full_name}`}
        subtitle="Representative Reimbursement"
      />
      <Wrapped>
				<p>
					<Link to="/representative-reimbursements"><strong>Reimbursements</strong></Link>
        </p>
        <RepresentativeReimbursementForm
          id={representativeReimbursementId}
          datePaid={representativeReimbursement.datePaid}
          postedToJp={representativeReimbursement.postedToJp}
          postedToAch={representativeReimbursement.postedToAch}
          achReference={representativeReimbursement.achReference}
          jobReimbursementLinks={representativeReimbursement.jobReimbursementLinks}
					amountFormatted={representativeReimbursement.amountFormatted}
          status={representativeReimbursement.status}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateRepresentativeReimbursement(d = {}) {
  return {
    representative: d.representative || "",
		jobReimbursementLinks: d.job_reimbursement_links,
    datePaid: d.date_paid || "",
    postedToAch: d.posted_to_ach,
    achReference: d.ach_reference || "",
		amountFormatted: d.amount_formatted || "",
    status: d.status,
  };
}