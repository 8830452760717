import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	AssignedJobsIndex,
	AssignedJobsDetail,
	PaymentsIndex,
	PaymentsDetail,
  CommissionsIndex,
	CommissionsDetail,
	ReimbursementsIndex,
	ReimbursementsDetail,
	DrawsIndex,
	DrawsDetail,
	RepresentativeProfile,
	ManagedRepresentativesIndex,
	ManagedRepresentativesDetail,
	Reports,
} from "@pages/representative";
import Nav from "@components/Nav";
import { AppContainer, AppView } from "@components/Layouts";
import { NoMatch } from "@components/shared";
import ScrollToTop from "./ScrollToTop";
import { getRoleInfo } from "@util/roleInfo";

export default function Representative(props) {
  const roleInfo = getRoleInfo(useSelector((state) => state));
  const representerInfo = useSelector((state) => state).representer;

  return (
    <Router>
      <div style={{ height: "100%" }}>
        <Toaster
          toastOptions={{
            success: {
              iconTheme: {
                primary: "var(--positiveColor)",
              },
            },
          }}
        />
        <AppContainer>
          <Nav />
          <AppView>
            <ScrollToTop />
            <Switch>
              <Route exact path="/jobs" component={AssignedJobsIndex} />
              <Route exact path="/payments" component={PaymentsIndex} />
							<Route exact path="/reports" component={Reports} />
              <Route path="/payments/:id" component={PaymentsDetail} />
              <Route exact path="/commissions" component={CommissionsIndex} />
              <Route path="/commissions/:id" component={CommissionsDetail} />
              <Route exact path="/reimbursements" component={ReimbursementsIndex} />
              <Route path="/reimbursements/:id" component={ReimbursementsDetail} />
              <Route exact path="/draws" component={DrawsIndex} />
              <Route path="/draws/:id" component={DrawsDetail} />
		          {roleInfo.manager_view && [
		            <Route exact path="/representatives" component={ManagedRepresentativesIndex} />,
		            <Route path="/representatives/:id" component={ManagedRepresentativesDetail} />,
		          ]}
							<Route path="/representative-profile" component={RepresentativeProfile} />
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </AppView>
        </AppContainer>
      </div>
    </Router>
  );
}
