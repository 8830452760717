import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaPencilAlt,
  FaCaretUp,
  FaCaretDown,
  FaPaperPlane,
} from "react-icons/fa";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";
import styled from "styled-components";

import { Button } from "@components/shared";
import useInterval from "@util/useInterval";
import { size, device } from "@util/breakpoints";

const NotesWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 4px;
  width: 350px;

  @media (max-width: ${size.tablet}) {
    right: 0;
    width: 100%;
  }

  button.title {
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px 4px 0 0;
    background: var(--primaryColorDark);
    color: #fff;
    font-weight: bold;
    outline: none;
    border: none;

    @media (max-width: ${size.tablet}) {
      text-align: center;
    }

    svg {
      margin-left: 5px;
    }
  }

  .content {
    min-height: 350px;
    display: flex;
    flex-direction: column;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    background: var(--navBackgroundColor);
    flex-grow: 1;
  }

  textarea {
    display: block;
    padding: 4px;
    font-size: 14px;
    font-family: inherit;
    border: 2px solid transparent;
    /* border: 2px solid #eee; */
    outline: none;
    background: #fff;
    border-radius: 3px;
    min-height: 65px;
    height: 65px;
    max-height: 120px;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;

    &:focus {
      border: 2px solid #eee;
    }
  }

  .actions {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .list-wrapper {
    flex-grow: 2;
    background: #fff;
    overflow: auto;
    max-height: 350px;
    min-height: 350px;
    position: relative;
  }

  .empty {
    text-align: center;
    height: 350px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    text-align: center;
    align-items: center;

    svg {
      fill: #eee;
      font-size: 40px;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
    }
  }
`;

const AdminOnlyButton = styled.button`
  opacity: ${(props) => (props.active ? 1 : 0.25)};
  background: none;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  outline: none;
  border: none;
`;

function Notes({
  additionalCreateData,
  additionalFetchData,
  admin,
  buttonTitle,
  fetchUrl,
  title,
  user,
}) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // form values
  const [adminOnly, setAdminOnly] = useState(true);
  const [noteValue, setNoteValue] = useState("");
  const [subjectValue, setSubjectValue] = useState("");
  // the notes
  const [items, setItems] = useState([]);
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [notesLoading, setNotesLoading] = useState(false);

  useEffect(() => {
    fetchNotes();
  }, []);

  useInterval(() => {
    fetchNotes();
  }, 60000);

  function fetchNotes() {
    if (!additionalFetchData) return;

    setNotesLoading(true);

    const req = {
      ...additionalFetchData,
      admin_only: false,
    };

    axios
      .post(fetchUrl, req)
      .then(({ data }) => {
        setItems(
          data.response.sort((a, b) =>
            new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
          )
        );
        setNotesLoading(false);
      })
      .catch((err) => {
        setNotesLoading(false);
        setItems([]);
      });
  }

  function onSubmit() {
    const req = {
      ...additionalCreateData,
      message: noteValue,
      subject: subjectValue,
      admin_only: user ? false : adminOnly,
    };

    setError(null);
    setLoading(true);

    axios
      .post(`/notes/`, req)
      .then(() => {
        setLoading(false);
        resetForm();
        fetchNotes();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);

        setTimeout(() => {
          setError(null);
        }, 4000);
      });
  }

  function resetForm() {
    setNoteValue("");
    setSubjectValue("");
    setAdminOnly(true);
  }

  return (
    <NotesWrapper>
      <button className="title" onClick={() => setOpen(!isOpen)}>
        {title} {isOpen ? <FaCaretDown /> : <FaCaretUp />}
      </button>
      {isOpen && (
        <div className="content">
          {/* <Form size="small">
            <Form.Input
              label="Subject (optional)"
              value={subjectValue}
              onChange={(e) => setSubjectValue(e.target.value)}
            />
            <Form.TextArea
              label="Note"
              value={noteValue}
              style={{ maxHeight: "75px" }}
              onChange={(e) => setNoteValue(e.target.value)}
            />
            {admin && (
              <Form.Checkbox
                toggle
                checked={adminOnly}
                label="Admin Only"
                onChange={(e) => setAdminOnly(!adminOnly)}
              />
            )}
            <div style={{ marginTop: "1.5em" }}>
              <Button.Basic
                primary
                text="Submit"
                onClick={onSubmit}
                loading={loading}
                disabled={!noteValue}
              />
            </div>
          </Form> */}
          <div className="list-wrapper">
            <NoteList notes={items} />
            {!loading && !items.length && (
              <div className="empty">
                <FaPencilAlt />
                <p>No notes yet.</p>
              </div>
            )}
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <textarea
              value={noteValue}
              placeholder="Your note here..."
              onChange={(e) => {
                setNoteValue(e.target.value);
              }}
            />
            <div className="actions">
              {admin && (
                <div
                  style={{
                    flexGrow: "1",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <AdminOnlyButton
                    active={adminOnly}
                    type="button"
                    onClick={() => setAdminOnly(!adminOnly)}
                  >
                    Admin Only
                  </AdminOnlyButton>
                </div>
              )}
              <div
                style={{
                  flexGrow: "1",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button.Icon
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit();
                  }}
                  disabled={!noteValue}
                  opacity={!noteValue ? 0.3 : 1}
                  icon={
                    <FaPaperPlane
                      color={noteValue ? "var(--primaryColorLight)" : "#eee"}
                      size={24}
                    />
                  }
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </NotesWrapper>
  );
}

Notes.defaultProps = {
  // data that needs to be sent along with a note create
  additionalCreateData: null,
  // data that needs to be sent along with a fetch req
  additionalFetchData: null,
  // title of the button that opens the modal
  buttonTitle: "Notes",
  // where to fetch notes
  fetchUrl: "/retrieve-notes/",
  // title of modal
  title: "Notes",
};

function mapStateToProps(state) {
  return {
    admin: state.admin,
    user: state.user,
  };
}
export default connect(mapStateToProps)(Notes);

const Note = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 4px;
  background: #fff;

  &:nth-of-type(even) {
    background: #eee;
  }

  p {
    margin: 0;
  }

  time {
    font-size: 12px;
    color: #717171;
  }
`;

const List = styled.div`
  /* max-height: 200px; */
  overflow: auto;
`;

function NoteList({ notes }) {
  if (!notes.length) return null;

  return (
    <List>
      {notes.map(
        ({
          id,
          subject,
          message,
          admin_only,
          owned_by,
          created_at_formatted,
        }) => (
          <Note key={id}>
            <div>
              <p style={{ marginBottom: "0", marginRight: "10px" }}>
                <b>
                  {owned_by.full_name}
                  {/* {owned_by.practice && (
                  <React.Fragment> of {owned_by.practice.name}</React.Fragment>
                )} */}
                </b>
                <span style={{ fontSize: "0.75em", marginLeft: "5px" }}>
                  {admin_only && `(admin only)`}
                </span>
              </p>
              <time>{created_at_formatted}</time>
            </div>
            <div>
              {subject && <p>{subject}</p>}
              <p>{message}</p>
            </div>
          </Note>
        )
      )}
    </List>
  );
}

NoteList.defaultProps = {
  notes: [],
};
