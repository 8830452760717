import React, { useState } from "react";
import { Form, Icon } from "semantic-ui-react";
import { Button, FilterSelect, PaginatedTable, BasicTable } from "@components/shared";

export default function Table({ representativeView, representativeDraws }) {
  const [activeFilter, setActiveFilter] = useState(`[true, false]`);
  const [refresh, setRefresh] = useState(false);
  const [updated, setUpdated] = useState(false);

  const columns = [
    {
      Header: "",
      id: "view",
      width: 50,
      accessor: (d) => <Button.View url={`/representative-draws/${d.id}`} />,
    },
    {
      Header: "Representative",
      accessor: "representative.full_name",
      id: "representative_name",
      sortable: false,
    },
    {
      Header: "Date Paid",
      accessor: "date_formatted",
      id: "date_formatted",
      sortable: true,
      width: 120,
    },
    {
      Header: "Amount",
      accessor: "amount_formatted",
      id: "amount_formatted",
      sortable: true,
      width: 120,
    },
    {
      Header: "Balance Due",
      accessor: "balance_due_formatted",
      id: "balance_due_formatted",
      sortable: true,
      width: 120,
    },
    {
      Header: "Posted to ACH",
      accessor: (d) =>
        d.posted_to_ach ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
      id: "posted_to_ach",
      sortable: false,
      width: 125,
    },
    {
      Header: "ACH Reference",
      accessor: "ach_reference",
      id: "ach_reference",
      sortable: false,
      width: 140,
    },
    {
      Header: "Notes",
      accessor: "notes",
      id: "notes",
      sortable: false,
      width: 300,
    },
    {
      Header: "Paid",
      accessor: (d) =>
        d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
      id: "paid",
      sortable: true,
      width: 75,
    },
  ].map((m) => ({
    ...m,
    filterable: false,
  })).filter((f) => 
    representativeView ? !["representative_name"].includes(f.id) : true
  );


  const additionalPostData = {
    status: JSON.parse(activeFilter),
  };

  return (
    <React.Fragment>
    {!representativeDraws && (
      <React.Fragment>
		    <FilterSelect
		      options={statusOptions}
		      value={activeFilter}
		      onChange={(e) => setActiveFilter(e)}
		      entityName="Status"
		    />
		    <PaginatedTable
		      columns={columns}
		      additionalPostData={additionalPostData}
		      entityName="Representative Draws"
		      filter={activeFilter}
		      filterOptions={filterOptions}
		      keyName="data"
		      refresh={refresh}
		      url="/retrieve-representative-draws/"
		    />
      </React.Fragment>
    )}
    {representativeDraws && (
      <BasicTable
        columns={columns}
        data={representativeDraws}
        entityName="Representative Draws"
      />
    )}
    </React.Fragment>
  );
}

Table.defaultProps = {};

const statusOptions = [
  { text: "All", value: `[true, false]` },
  { text: "Unpaid", value: false },
  { text: "Paid", value: true },
];

const filterOptions = [{ name: "name", obj: "self" }];
