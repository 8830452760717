import React from "react";
import styled from "styled-components";

const WrapperStyle = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1250px;
  margin: 0 auto;
`;

export const Wrapper = ({ children }) => (
  <WrapperStyle>{children}</WrapperStyle>
);

const MainStyle = styled.main`
  width: 80%;
  margin-right: 1em;
  padding-right: 4em;

  .wrapped {
    width: 100%;
    /* margin: 0; */
  }
`;

export const Main = ({ children }) => <MainStyle>{children}</MainStyle>;

const SidebarStyle = styled.div`
  width: 20%;

  .wrapped {
    width: 100%;
  }
`;

export const Sidebar = ({ children }) => (
  <SidebarStyle>{children}</SidebarStyle>
);
