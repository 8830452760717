import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "semantic-ui-react";

export default function ContractorSelect({ onChange }) {
  const [contractors, setContractors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    const req = {
      status: 0,
    };

    axios.post(`/lookup-contractors/`, req).then(({ data }) => {
      setContractors(
        data.data.map((d) => ({
          key: d.id,
          text: d.name,
          value: d.id,
        }))
      );
      setLoading(false);
    });
  }

  const handleChange = (e, { value }) => {
    const selectedContractor = contractors.find(c => c.value === value);
    if (selectedContractor) {
      onChange({
        contractor: { name: selectedContractor.text, value: selectedContractor.value }
      });
    }
  };

  return (
    <div>
      <Form>
        <Form.Select
          placeholder="Select a contractor..."
          loading={loading}
          options={contractors}
          search
          onChange={handleChange}
        />
      </Form>
    </div>
  );
}

ContractorSelect.defaultProps = {
  selected: [],
};