import React from "react";
import { Wrapped } from "@components/Layouts";
import Reports from "@components/Reports";

export default function DashboardPage() {
  return (
    <React.Fragment>
      <Wrapped title="Reports">
        <p>coming soon</p>
      </Wrapped>
    </React.Fragment>
  );
}
