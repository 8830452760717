import React from "react";
import styled from "styled-components";

const Text = styled.p`
  color: var(--negativeColor);
  font-weight: bold;
`;

export default function ErrorText({ text }) {
  if (!text) return null;

  return <Text>{text}</Text>;
}

ErrorText.defaultProps = {
  text: "",
};
