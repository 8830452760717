import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { RepresentativeBonusForm } from "@components/RepresentativeBonus";

export default function RepresentativeBonusesDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [representativeBonus, setRepresentativeBonus] = useState(populateRepresentativeBonus());

  const representativeBonusId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/representative-bonuses/${representativeBonusId}/`)
      .then(({ data }) => {
        setLoading(false);
        setRepresentativeBonus(populateRepresentativeBonus(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!representativeBonus) return null;

  return (
    <div>
      <PageTitle
        title={`${representativeBonus.representative.full_name}`}
        subtitle="Representative Bonus"
      />
      <Wrapped>
				<p>
					<Link to="/representative-bonuses"><strong>Bonuses</strong></Link>
        </p>
        <RepresentativeBonusForm
          id={representativeBonusId}
          amountFormatted={representativeBonus.amountFormatted}
          paid={representativeBonus.paid}
          date={representativeBonus.date}
          postedToAch={representativeBonus.postedToAch}
          achReference={representativeBonus.achReference}
          notes={representativeBonus.notes}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateRepresentativeBonus(d = {}) {
  return {
    representative: d.representative || "",
    amountFormatted: d.amount_formatted || "",
    date: d.date || "",
    paid: d.paid,
    postedToAch: d.posted_to_ach,
    achReference: d.ach_reference || "",
    notes: d.notes || "",
  };
}
