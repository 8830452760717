import React, { useState, useEffect } from "react";
import axios from "axios";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { AdminForm } from "@components/Admin";

export default function AdminDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [admin, setAdmin] = useState(populateAdmin());

  const adminId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/admins/${adminId}/`)
      .then(({ data }) => {
        setLoading(false);
        setAdmin(populateAdmin(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!admin) return null;

  return (
    <div>
      <PageTitle
        title={`${admin.firstName} ${admin.lastName}`}
        subtitle="Admin"
      />
      <Wrapped>
        <AdminForm
          id={adminId}
          email={admin.email}
          phone={admin.phone}
          prefix={admin.prefix}
          firstName={admin.firstName}
          lastName={admin.lastName}
          suffix={admin.suffix}
          onSuccess={fetchData}
          status={admin.status}
          access={admin.access}
        />
      </Wrapped>
    </div>
  );
}

function populateAdmin(d = {}) {
  return {
    prefix: d.prefix || "",
    firstName: d.first_name || "",
    lastName: d.last_name || "",
    suffix: d.suffix || "",
    email: d.email || "",
    phone: d.phone || "",
    status: d.status,
    access: d.access,
  };
}
