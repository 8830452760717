import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { AchPaymentForm } from "@components/AchPayment";

export default function AchDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [achPayment, setAchPayment] = useState(populateAchPayment());

  const achPaymentId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/ach-payments/${achPaymentId}/`)
      .then(({ data }) => {
        setLoading(false);
        setAchPayment(populateAchPayment(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!achPayment) return null;

  return (
    <div>
      <PageTitle
        title={`${achPayment.representative.full_name}`}
        subtitle="ACH Payment"
      />
      <Wrapped>
				<p>
					<Link to="/ach/payments"><strong>ACH Payments</strong></Link>
				</p>
        <AchPaymentForm
          id={achPaymentId}
          datePaid={achPayment.datePaid}
          postedToJp={achPayment.postedToJp}
          postedToAch={achPayment.postedToAch}
          achReference={achPayment.achReference}
					amountFormatted={achPayment.amountFormatted}
          status={achPayment.status}
					jobPaymentLinks={achPayment.jobPaymentLinks}
					jobManagerPointsLinks={achPayment.jobManagerPointsLinks}
					representativeSalaryPayments={achPayment.representativeSalaryPayments}
					jobKnockingLinks={achPayment.jobKnockingLinks}
					jobReimbursementLinks={achPayment.jobReimbursementLinks}
					drawCollections={achPayment.drawCollections}
					representativeBonuses={achPayment.representativeBonuses}
					representativeCorrections={achPayment.representativeCorrections}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateAchPayment(d = {}) {
  return {
    representative: d.representative || "",
		jobPaymentLinks: d.job_payment_links,
    datePaid: d.date_paid || "",
    postedToAch: d.posted_to_ach,
    achReference: d.ach_reference || "",
		amountFormatted: d.amount_formatted || "",
    status: d.status,
		jobPaymentLinks: d.job_payment_links,
		jobManagerPointsLinks: d.job_manager_points_links,
		representativeSalaryPayments: d.representative_salary_payments,
		jobKnockingLinks: d.job_knocking_links,
		jobReimbursementLinks: d.job_reimbursement_links,
		drawCollections: d.draw_collections,
		representativeBonuses: d.representative_bonuses,
		representativeCorrections: d.representative_corrections,
  };
}
