import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { Button } from "./";

export default function ButtonModal({ buttonProps, children }) {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Button.Basic onClick={() => setOpen(true)} {...buttonProps} />
      <Modal open={isOpen} onClose={onClose}>
        <Modal.Content>{children}</Modal.Content>
        <Modal.Actions>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button.Basic text="Close" onClick={onClose} />
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
}

ButtonModal.defaultProps = {
  buttonProps: {
    text: "Text",
    primary: true,
  },
};
