import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { RepresentativePaymentForm } from "@components/RepresentativePayment";

export default function RepresentativePaymentsDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [representativePayment, setRepresentativePayment] = useState(populateRepresentativePayment());

  const representativePaymentId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/representative-payments/${representativePaymentId}/`)
      .then(({ data }) => {
        setLoading(false);
        setRepresentativePayment(populateRepresentativePayment(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!representativePayment) return null;

  return (
    <div>
      <PageTitle
        title={`${representativePayment.representative.full_name}`}
        subtitle="Representative Payment"
      />
      <Wrapped>
				<p>
					<Link to="/representative-payments"><strong>Commissions</strong></Link>
        </p>
        <RepresentativePaymentForm
          id={representativePaymentId}
          datePaid={representativePayment.datePaid}
          postedToJp={representativePayment.postedToJp}
          postedToAch={representativePayment.postedToAch}
          achReference={representativePayment.achReference}
          jobPaymentLinks={representativePayment.jobPaymentLinks}
					amountFormatted={representativePayment.amountFormatted}
          status={representativePayment.status}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateRepresentativePayment(d = {}) {
  return {
    representative: d.representative || "",
		jobPaymentLinks: d.job_payment_links,
    datePaid: d.date_paid || "",
    postedToAch: d.posted_to_ach,
    achReference: d.ach_reference || "",
		amountFormatted: d.amount_formatted || "",
    status: d.status,
  };
}
