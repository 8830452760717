import React, { useState, useEffect } from "react";
import axios from "axios";
import { Label, Icon, Modal, Form } from "semantic-ui-react";
import { Button } from "@components/shared";
import Jobs from "./Jobs";
import Salaries from "./Salaries"
import Invoices from "./Invoices";
import DrawCollections from "./DrawCollections";
import Bonuses from "./Bonuses";
import Points from "./Points";
import Knocking from "./Knocking";
import Corrections from "./Corrections";
import { RepresentativeSelect } from "@components/Representative";
import { MemberCard, Tidbit, Text } from "@components/shared";
import { AchPaymentForm } from "@components/AchPayment";

const STEP_REPRESENTATIVE = "representative";
const STEP_JOBS = "jobs";
const STEP_POINTS = "points";
const STEP_SALARIES = "salaries";
const STEP_KNOCKING = "knocking";
const STEP_INVOICES = "invoices";
const STEP_DRAWS = "draws";
const STEP_BONUSES = "bonuses";
const STEP_CORRECTIONS = "corrections";
const STEP_PAYMENT = "payment";

export default function EditModal({ id, editable, representative, onUpdate, onSuccess, onClose }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRepresentativeId, setSelectedRepresentativeId] = useState(null);
  const [activeStep, setActiveStep] = useState(STEP_REPRESENTATIVE);
	const [selectedJobPaymentLinks, setSelectedJobPaymentLinks] = useState([]);
	const [unpaidJobPaymentLinks, setUnpaidJobPaymentLinks] = useState([]);
	const [selectedManagerPoints, setSelectedManagerPoints] = useState([]);
	const [unpaidManagerPoints, setUnpaidManagerPoints] = useState([]);
	const [selectedSalaries, setSelectedSalaries] = useState([]);
	const [unpaidSalaries, setUnpaidSalaries] = useState([]);
	const [selectedJobReimbursementLinks, setSelectedJobReimbursementLinks] = useState([]);
	const [unpaidJobReimbursementLinks, setUnpaidJobReimbursementLinks] = useState([]);
	const [selectedUnsettledDrawCollections, setSelectedUnsettledDrawCollections] = useState([]);
	const [unsettledDrawCollections, setUnsettledDrawCollections] = useState([]);
	const [selectedBonuses, setSelectedBonuses] = useState([]);
	const [unpaidBonuses, setUnpaidBonuses] = useState([]);
	const [selectedCorrections, setSelectedCorrections] = useState([]);
	const [unsettledCorrections, setUnsettledCorrections] = useState([]);
	const [selectedKnockings, setSelectedKnockings] = useState([]);
	const [unpaidKnockings, setUnpaidKnockings] = useState([]);
  const [statusValue, setStatusValue] = useState(null);
  const [error, setError] = useState(null);
  const [altFlow, setAltFlow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    // fetchData();
    setError(null);
    setShowDelete(false);
		onRepresentativeProvided();
  }, [isOpen]);
	
	function onRepresentativeProvided() {
	  if (representative) {
			setOpen(true);
	    setSelectedRepresentativeId(representative);
	    setActiveStep(STEP_JOBS);
	  }
	}

  function onRepresentativeSelect() {
    setActiveStep(STEP_JOBS);
  }
  
  function onRepresentativeClear() {
    setActiveStep(STEP_REPRESENTATIVE);
    setSelectedRepresentativeId(null);
  }


  return (
    <div>
			{!representative && (
			  <Button.Toggle
			    closedText="Select Representative and Jobs"
			    onClick={() => setOpen(!isOpen)}
			    open={isOpen}
			  />
			)}
      <Modal open={isOpen} onClose={() => setOpen(false)} size="fullscreen">
        <Modal.Header style={{ display: "flex", alignItems: "center" }}>
          ACH Payment
          {selectedRepresentativeId && (
            <React.Fragment>
              {" for "}
              {selectedRepresentativeId.representative.name}
              <Button.Clear
                person
                onClick={onRepresentativeClear}
                style={{ margin: "0 0 10px 0" }}
              />
            </React.Fragment>
          )}
        </Modal.Header>
        <Modal.Content>
          {activeStep === STEP_REPRESENTATIVE && (
            <React.Fragment>
              <div>
                <RepresentativeSelect onChange={(e) => setSelectedRepresentativeId(e)} />
              </div>
              <div>
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={(!selectedRepresentativeId)}
                  onClick={onRepresentativeSelect}
                  style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_JOBS && (
            <React.Fragment>
              <div>
								<h2>Job Commissions</h2>
            		<Jobs
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedJobPaymentLinks={selectedJobPaymentLinks}
			           setSelectedJobPaymentLinks={setSelectedJobPaymentLinks}
								 unpaidJobPaymentLinks={unpaidJobPaymentLinks}
								 setUnpaidJobPaymentLinks={setUnpaidJobPaymentLinks}
								  />
              </div>
              <div>
					 			{!representative && (
	                <Button.Basic
	                  text="Back"
	                  onClick={() => setActiveStep(STEP_REPRESENTATIVE)}
	                  style={{ margin: "10px 10px 0 0" }}
	                />
					 			)}
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={!selectedRepresentativeId}
                  onClick={() => setActiveStep(STEP_POINTS)}
									style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_POINTS && (
            <React.Fragment>
              <div>
								<h2>Manager Points</h2>
			       		<Points
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedManagerPoints={selectedManagerPoints}
			           setSelectedManagerPoints={setSelectedManagerPoints}
								 unpaidManagerPoints={unpaidManagerPoints}
								 setUnpaidManagerPoints={setUnpaidManagerPoints}
								  />
              </div>
              <div>
					 			<Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_JOBS)}
                  style={{ margin: "10px 10px 0 0" }}
                />
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={!selectedRepresentativeId}
                  onClick={() => setActiveStep(STEP_SALARIES)}
									style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_SALARIES && (
            <React.Fragment>
              <div>
								<h2>Salary Payments</h2>
			       		<Salaries
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedSalaries={selectedSalaries}
			           setSelectedSalaries={setSelectedSalaries}
								 unpaidSalaries={unpaidSalaries}
								 setUnpaidSalaries={setUnpaidSalaries}
								  />
              </div>
              <div>
					 			<Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_POINTS)}
                  style={{ margin: "10px 10px 0 0" }}
                />
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={!selectedRepresentativeId}
                  onClick={() => setActiveStep(STEP_KNOCKING)}
									style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_KNOCKING && (
            <React.Fragment>
              <div>
								<h2>Knocking Commission</h2>
			       		<Knocking
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedKnockings={selectedKnockings}
			           setSelectedKnockings={setSelectedKnockings}
								 unpaidKnockings={unpaidKnockings}
								 setUnpaidKnockings={setUnpaidKnockings}
								  />
              </div>
              <div>
					 			<Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_SALARIES)}
                  style={{ margin: "10px 10px 0 0" }}
                />
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={!selectedRepresentativeId}
                  onClick={() => setActiveStep(STEP_INVOICES)}
									style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_INVOICES && (
            <React.Fragment>
              <div>
								<h2>Reimbursement</h2>
            		<Invoices
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedJobReimbursementLinks={selectedJobReimbursementLinks}
			           setSelectedJobReimbursementLinks={setSelectedJobReimbursementLinks}
								 unpaidJobReimbursementLinks={unpaidJobReimbursementLinks}
								 setUnpaidJobReimbursementLinks={setUnpaidJobReimbursementLinks}
								  />
              </div>
              <div>
					 			<Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_SALARIES)}
                  style={{ margin: "10px 10px 0 0" }}
                />
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={!selectedRepresentativeId}
                  onClick={() => setActiveStep(STEP_DRAWS)}
									style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_DRAWS && (
            <React.Fragment>
              <div>
								<h2>Draw Collections</h2>
            		<DrawCollections
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedUnsettledDrawCollections={selectedUnsettledDrawCollections}
			           setSelectedUnsettledDrawCollections={setSelectedUnsettledDrawCollections}
								 unsettledDrawCollections={unsettledDrawCollections}
								 setUnsettledDrawCollections={setUnsettledDrawCollections}
								  />
              </div>
              <div>
					 			<Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_INVOICES)}
                  style={{ margin: "10px 10px 0 0" }}
                />
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={!selectedRepresentativeId}
                  onClick={() => setActiveStep(STEP_BONUSES)}
									style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_BONUSES && (
            <React.Fragment>
              <div>
								<h2>Bonuses</h2>
            		<Bonuses
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedBonuses={selectedBonuses}
			           setSelectedBonuses={setSelectedBonuses}
								 unpaidBonuses={unpaidBonuses}
								 setUnpaidBonuses={setUnpaidBonuses}
								  />
              </div>
              <div>
					 			<Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_DRAWS)}
                  style={{ margin: "10px 10px 0 0" }}
                />
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={!selectedRepresentativeId}
                  onClick={() => setActiveStep(STEP_CORRECTIONS)}
									style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_CORRECTIONS && (
            <React.Fragment>
              <div>
								<h2>Corrections</h2>
            		<Corrections
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedCorrections={selectedCorrections}
			           setSelectedCorrections={setSelectedCorrections}
								 unsettledCorrections={unsettledCorrections}
								 setUnsettledCorrections={setUnsettledCorrections}
								  />
              </div>
              <div>
					 			<Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_BONUSES)}
                  style={{ margin: "10px 10px 0 0" }}
                />
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={!selectedRepresentativeId}
                  onClick={() => setActiveStep(STEP_PAYMENT)}
									style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_PAYMENT && (
            <React.Fragment>
              <div>
								<AchPaymentForm
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedJobPaymentLinks={selectedJobPaymentLinks}
								 unpaidJobPaymentLinks={unpaidJobPaymentLinks}
								 selectedManagerPoints={selectedManagerPoints}
								 unpaidManagerPoints={unpaidManagerPoints}
								 selectedSalaries={selectedSalaries}
								 unpaidSalaries={unpaidSalaries}
								 selectedJobReimbursementLinks={selectedJobReimbursementLinks}
								 unpaidJobReimbursementLinks={unpaidJobReimbursementLinks}
								 selectedUnsettledDrawCollections={selectedUnsettledDrawCollections}
								 unsettledDrawCollections={unsettledDrawCollections}
								 selectedBonuses={selectedBonuses}
								 unpaidBonuses={unpaidBonuses}
								 selectedCorrections={selectedCorrections}
							 	 unsettledCorrections={unsettledCorrections}
								 selectedKnockings={selectedKnockings}
								 unpaidKnockings={unpaidKnockings}
								 onSuccess={onSuccess}
								 />
								 
                <Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_CORRECTIONS)}
                  style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
					{representative && (
		        <Button.Basic
		          text="Close"
		          onClick={onClose}
		          style={{ margin: "10px 10px 10px 0" }}
		        />
					)}
        </Modal.Content>
      </Modal>
    </div>
  );
}

EditModal.defaultProps = {
  editable: false,
};