import React, { useState } from "react";
import { FilterSelect, PaginatedTable, Button } from "@components/shared";

import { statusOptions, accessOptions } from "./status";

export default function Table() {
  const [activeStatusFilter, setActiveStatusFilter] = useState(0);
  const [activeAccessFilter, setActiveAccessFilter] = useState(0);

  const additionalPostData = {
    status: JSON.parse(activeStatusFilter),
    access: JSON.parse(activeAccessFilter),
  };

  return (
    <React.Fragment>
      <FilterSelect
        options={statusDropdownOptions}
        value={activeStatusFilter}
        onChange={(e) => setActiveStatusFilter(e)}
        entityName="Status"
      />
      <FilterSelect
        options={accessDropdownOptions}
        value={activeAccessFilter}
        onChange={(e) => setActiveAccessFilter(e)}
        entityName="Access"
      />
      <PaginatedTable
        columns={[
          {
            Header: "",
            width: 50,
            id: "view",
            accessor: (d) => <Button.View url={`/admins/${d.id}`} />,
          },
          { Header: "First Name", accessor: "first_name" },
          { Header: "Last Name", accessor: "last_name" },
          { Header: "Email", accessor: "email" },
          { Header: "Status", accessor: "status_formatted" },
          { Header: "Access", accessor: "access_formatted" },
        ].map((m) => ({ ...m, sortable: false }))}
        additionalPostData={additionalPostData}
        entityName="Admins"
        keyName="data"
        url="/retrieve-admins/"
      />
    </React.Fragment>
  );
}

const statusDropdownOptions = [
  { text: "All", value: JSON.stringify(statusOptions.map((s) => s.value)) },
  ...statusOptions,
];

const accessDropdownOptions = [
  { text: "All", value: JSON.stringify(statusOptions.map((s) => s.value)) },
  ...accessOptions,
];