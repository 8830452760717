import React from "react";
import styled from "styled-components";

const JoinWrapper = styled.div`
  /* margin-bottom: 1em; */

  &:last-of-type {
    margin-bottom: 1em;
  }

  h5 {
    color: #fff;
    margin-bottom: 5px;
    padding-left: 3px;
  }

  .joins {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .join {
    padding: 3px 10px;
    background: ${(props) =>
      props.secondary
        ? "var(--secondaryColorLight)"
        : "var(--primaryColorLight)"};
    border-radius: 15px;
    font-size: 0.85em;
    color: #fff;
    font-weight: 600;
    border: 2px solid
      ${(props) =>
        props.secondary ? "var(--secondaryColor)" : "var(--primaryColor)"};
    /* margin-bottom: 5px; */
    margin: 2px;

    + .join {
      /* margin-left: 5px; */
    }
  }
`;

const JoinSection = ({ title, secondary, joins }) => (
  <JoinWrapper secondary={secondary}>
    <h5>{title}</h5>
    <div className="joins">
      {joins.map((j) => (
        <div key={j.id} className="join">
          {j.text}
        </div>
      ))}
    </div>
  </JoinWrapper>
);

JoinSection.defaultProps = {
  secondary: false,
  title: "",
  joins: [],
};

export default JoinSection;
