import React from "react";
import { FaClock } from "react-icons/fa";

export default function ClockIcon({ size }) {
  return <FaClock size={size} />;
}

ClockIcon.defaultProps = {
  size: 18,
};
