import React, { useState, useEffect } from "react";
import axios from "axios";
import { Label, Icon, Modal, Form } from "semantic-ui-react";
import { Button } from "@components/shared";
import Invoices from "./Invoices";
import { ContractorSelect } from "@components/Contractor";
import { MemberCard, Tidbit, Text } from "@components/shared";

const STEP_CONTRACTOR = "contractor";
const STEP_INVOICES = "invoices";
const STEP_PAYMENT = "payment";

export default function EditModal({ id, editable, teamId, onUpdate }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedContractorId, setSelectedContractorId] = useState(null);
  const [activeStep, setActiveStep] = useState(STEP_CONTRACTOR);
  const [bulkInvoices, setBulkInvoices] = useState([]);
  
  const [statusValue, setStatusValue] = useState(null);
  const [error, setError] = useState(null);
  const [altFlow, setAltFlow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    // fetchData();
    setError(null);
    setBulkInvoices([]);
    setShowDelete(false);
  }, [isOpen]);

  function onContractorSelect() {
    setActiveStep(STEP_INVOICES);
  }
  
  function onContractorClear() {
    setActiveStep(STEP_CONTRACTOR);
    setSelectedContractorId(null);
  }

  function onInviteUpdate() {
    fetchData();
    onUpdate();
  }

  function onInitClick(confirmation) {
    if (!confirmation) {
      return onCancelClick();
    }

    const req = {
      status: 1,
      id,
    };

    axios.put(`/hot-seats/${id}/`, req).then(() => {
      fetchData();
    });
  }

  function onCancelClick() {
    const req = {
      id: id,
      status: 6,
    };

    axios
      .put(`/hot-seats/${id}/`, req)
      .then(() => {
        onUpdate();
      })
      .then(() => setOpen(false));
  }

  function onStatusChange(status) {
    const req = {
      id,
      status,
    };

    setStatusValue(status);

    axios.put(`/hot-seats/${id}/`, req).then(() => {
      onUpdate();
      fetchData();
    });
    // .then(() => setOpen(false));
  }
  
  // const {
  //   application,
  //   invites,
  //   meetings,
  //   selectedProfessional,
  //   status,
  //   statusFormatted,
  //   team,
  //   classification,
  // } = hotseat;

  return (
    <div>
      <Button.Toggle
        closedText="Select Contractor and Invoices"
        onClick={() => setOpen(!isOpen)}
        open={isOpen}
      />
      <Modal open={isOpen} onClose={() => setOpen(false)} size="fullscreen">
        <Modal.Header style={{ display: "flex", alignItems: "center" }}>
          Contractor Payment
          {selectedContractorId && (
            <React.Fragment>
              {" for "}
              {selectedContractorId.contractor.name}
              <Button.Clear
                person
                onClick={onContractorClear}
                style={{ margin: "0 0 10px 0" }}
              />
            </React.Fragment>
          )}
        </Modal.Header>
        <Modal.Content>
          {activeStep === STEP_CONTRACTOR && (
            <React.Fragment>
              <div>
                <ContractorSelect onChange={(e) => setSelectedContractorId(e)} />
              </div>
              <div>
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={(!selectedContractorId)}
                  onClick={onContractorSelect}
                  style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_INVOICES && (
            <React.Fragment>
              <div>
            <Invoices contractorId={selectedContractorId.contractor.id} />
              </div>
              <div>
                <Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_CONTRACTOR)}
                  style={{ margin: "10px 10px 10px 0" }}
                />
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={!selectedContractorId || bulkInvoices === []}
                  onClick={() => setActiveStep(STEP_PAYMENT)}
                />
              </div>
            </React.Fragment>
          )}
          
        </Modal.Content>
      </Modal>
    </div>
  );
}

EditModal.defaultProps = {
  editable: false,
  teamId: null,
};

function populateState(d) {
  let data = { ...d };

  let meetings = [];

  if (data.meeting_one) {
    meetings.push(data.meeting_one);
  }
  if (data.meeting_two) {
    meetings.push(data.meeting_two);
  }

  return {
  };
}

const statusOptions = [
  { key: 0, text: "Choice", value: 0 },
  { key: 1, text: "Decision", value: 1 },
  { key: 2, text: "Invited", value: 2 },
  { key: 3, text: "Cancelled", value: 3 },
  { key: 4, text: "Complete", value: 4 },
];

const SendButton = ({ invites, hotseatId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      hot_seat_id: hotseatId,
    };

    axios
      .post(`/send-hot-seat-invitations/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <Button
      content="Finalize Choices and Send Invitations"
      loading={loading}
      onClick={onClick}
      disabled={loading || invites.length === 0}
    />
  );
};

SendButton.defaultProps = {
  invites: [],
};

const UndoSelectionButton = ({ hotseatId, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  if (!hotseatId) return null;

  function onClick() {
    setLoading(true);

    const req = {
      id: hotseatId,
      application_id: null,
      selected_professional: null,
      status: 3,
    };

    axios
      .put(`/hot-seats/${hotseatId}/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <Button
      content="Undo Selection"
      basic
      loading={loading}
      onClick={onClick}
      disabled={loading}
    />
  );
};

const BulkSendButton = ({ invoiceIds, teamId, classificationId, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  function onClick() {
    setLoading(true);
    setError(null);

    const req = {
      classification_id: classificationId,
      invoice_ids: invoiceIds,
    };

    axios
      .post(`/pay-contractor-invoices/`, req)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        onSuccess();

        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  return (
    <div>
      {error && <Text.Error text={error} />}
      {success && <Text.Success text="Invites sent!" />}
      <Button
        primary
        content="Send Invitations"
        loading={loading}
        onClick={onClick}
        disabled={loading || invoiceIds.length === 0}
      />
    </div>
  );
};
