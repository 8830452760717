import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import * as Yup from "yup";
import { BasicForm, BasicTable } from "@components/shared";
import { Icon } from "semantic-ui-react";
import PropTypes from 'prop-types';

import { getRoleInfo } from "@util/roleInfo";

function RepresentativePaymentView({
  id,
  representativeName,
  representativeId,
  datePaid,
	datePaidFormatted,
  amountFormatted,
  postedToJp,
  postedToAch,
  achReference,
  notes,
  status,
  jobs,
 	selectedJobPaymentLinks,
 	unpaidJobPaymentLinks,
	jobPaymentLinks,
  onFormSubmit,
  onSuccess,
  dispatch,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const roleInfo = getRoleInfo(useSelector((state) => state));
	
	const filteredJobPaymentLinks = (unpaidJobPaymentLinks || []).filter(link => 
	  selectedJobPaymentLinks.includes(link.id)
	);
	
	const totalAmount = filteredJobPaymentLinks.reduce((total, currentLink) => {
	  return total + (currentLink.commission_in_pennies / 100);
	}, 0);


  const fields = [
    {
      grouped: true,
      fields: [
		    {
		      name: "date_paid",
		      initialValue: datePaid
		            ? new Date(datePaid)
		            : null,
		      label: "Date Paid (optional)",
		      datepicker: true,
		      required: false,
		      schema: () =>
		        Yup.string().nullable(),
		    },
				{
		      name: "amount_formatted",
		      initialValue: id ? amountFormatted : totalAmount.toFixed(2),
		      label: "Total Commission Amount",
		      required: true,
		      schema: () => Yup.number().typeError('Amount must be a numbers only.').positive("Negative numbers not allowed").required("Required"),
		    },
      ],
    },
  ]
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_view_only ? true : false,
    }));
		
	  let columns = [
			{ Header: "Job", accessor: "job.name", width: 400 },
	    {
	      Header: "Leap Job ID",
	      accessor: "job.leap_job_id",
				width: 200,
	      sortable: false,
	    },
			{ Header: "Job Total", accessor: "job.job_total_formatted", width: 100 },
			{ Header: "Total Cost", accessor: "job.total_cost_formatted", width: 100 },
			{ Header: "Profit %", accessor: "job.profit_margin_percentage", width: 100 },
	    { Header: "Override %", accessor: "override_percentage", width: 120 },
			{ Header: "Paid Lead", 
      accessor: (d) =>
        d.job.paid_lead ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
			id: "job_paid_lead",
			width: 100},
	    { Header: "Paid Lead %", accessor: "paid_lead_percentage", width: 120 },
	    { Header: "Split %", accessor: "split_percentage", width: 120 },
			{ Header: "Commission Ratio", accessor: "resulting_ratio_reason" },
	    { Header: "Commission", accessor: "commission_formatted", width: 150 },
	  ];

  return (
    <React.Fragment>
      <div style={{ margin: "30px 10px 10px 0" }}>
		  <BasicTable
		    fetch={false}
		    data={id ? jobPaymentLinks : filteredJobPaymentLinks}
		    loading={isLoading}
		    columns={columns}
		    entityName="Jobs"
		  />
      </div>
      <div>
        <strong>Daid Paid:</strong> {datePaidFormatted}<br />
				<strong>Total Commission Amount:</strong> ${amountFormatted}
      </div>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    representativeId: state.representative ? state.representative.id : null,
  };
}

export default connect(mapStateToProps)(RepresentativePaymentView);

RepresentativePaymentView.propTypes = {
  selectedJobPaymentLinks: PropTypes.array,
  unpaidJobPaymentLinks: PropTypes.array,
  jobPaymentLinks: PropTypes.array,
};

RepresentativePaymentView.defaultProps = {
  selectedJobPaymentLinks: [],
  unpaidJobPaymentLinks: [],
  jobPaymentLinks: [],
  datePaid: null,
  id: null,
  postedToJp: null,
  postedToAch: null,
  achReference: "",
  status: 0,
};
