import Clock from "./ClockIcon";
import EmailIcon from "./EmailIcon";
import Eye from "./EyeIcon";
import PhoneIcon from "./PhoneIcon";
import Practice from "./PracticeIcon";
import Remove from "./RemoveIcon";
import Star from "./StarIcon";
import User from "./UserIcon";

export { Clock, EmailIcon, Eye, PhoneIcon, Practice, Remove, Star, User };
