import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { RepresentativePaymentView } from "@components/RepresentativePayment";

export default function CommissionsDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [representativePayment, setRepresentativePayment] = useState(populateRepresentativePayment());

  const representativePaymentId = match.params.id;
	
	const managerId = useSelector((state) => state).representative.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/representative-payments/${representativePaymentId}/`)
      .then(({ data }) => {
        setLoading(false);
        setRepresentativePayment(populateRepresentativePayment(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
	
	const myCommission = representativePayment.representative.id === managerId
	const manageAccessAllowed = representativePayment.representative.manager_representative && (representativePayment.representative.manager_representative.manager.id === managerId);
	
	if (!manageAccessAllowed && !myCommission) {
	  return <ViewError text="That is not allowed." />;
	}

  if (error) {
    return <ViewError text={error} />;
  }

  if (!representativePayment) return null;

  return (
    <div>
      <PageTitle
        title={`${representativePayment.representative.full_name}`}
        subtitle="Commission"
      />
      <Wrapped>
        <RepresentativePaymentView
          id={representativePaymentId}
          datePaid={representativePayment.datePaid}
					datePaidFormatted={representativePayment.datePaidFormatted}
          postedToJp={representativePayment.postedToJp}
          postedToAch={representativePayment.postedToAch}
          achReference={representativePayment.achReference}
          jobPaymentLinks={representativePayment.jobPaymentLinks}
					amountFormatted={representativePayment.amountFormatted}
          status={representativePayment.status}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateRepresentativePayment(d = {}) {
  return {
    representative: d.representative || "",
		jobPaymentLinks: d.job_payment_links,
    datePaid: d.date_paid || "",
		datePaidFormatted: d.date_paid_formatted || "",
    postedToAch: d.posted_to_ach,
    achReference: d.ach_reference || "",
		amountFormatted: d.amount_formatted || "",
    status: d.status,
  };
}
