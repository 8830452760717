import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import * as Yup from "yup";
import { BasicForm, Button } from "@components/shared";
import { ManagerSelect } from "@components/Representative";
import { getRoleInfo } from "@util/roleInfo";

const STEP_MANAGER = "manager";
const STEP_ENTRY = "entry";

function RepresentativeManagerForm({
  id,
	representativeId,
  managerPointsPercentage,
	status,
  onFormSubmit,
  onSuccess,
  dispatch,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(id ? STEP_ENTRY : STEP_MANAGER);
  const [selectedManagerId, setSelectedManagerId] = useState(null);
  const roleInfo = getRoleInfo(useSelector((state) => state));
  
  function onManagerSelect() {
    setActiveStep(STEP_ENTRY);
  }
  
  function onManagerClear() {
    setActiveStep(STEP_MANAGER);
    setSelectedManagerId(null);
  }

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
			representative_id: representativeId,
      manager_id: selectedManagerId.manager.value,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/manager-links/`, req)
      .then(({ data }) => {
        return data.id;
      })
      .then(() => setLoading(false))
      .then(() => {
        toast.success("Manager Added To Representative!");
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/contractor-job-links/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Manager Updated for Representative!"))
      .then((newId) => {
        onSuccess(newId);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
  
  const statusOptions = [
    { text: "Active", value: 0 },
    { text: "Inactive", value: 1 },
  ];

  const fields = [
    {
      name: "manager_points_percentage",
      initialValue: managerPointsPercentage,
      label: "Manager Points %",
      required: true,
      schema: () =>
        Yup.number().typeError('Amount must be a numbers only.').required("Required"),
    },
    {
      name: "status",
      label: "Status",
      initialValue: status,
      select: true,
      options: statusOptions,
      required: true,
      schema: () => Yup.number().nullable().required("Required"),
    },
  ]
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_view_only ? true : false,
    }));

    return (
      <div>
        {!id && activeStep === STEP_MANAGER && (
          <React.Fragment>
            {selectedManagerId && (
              <div style={{ display: "flex", marginBottom: "10px" }}>
                  <strong>Contractor: {selectedManagerId.manager.name}</strong> 
                  <Button.Clear
                    person
                    onClick={onManagerClear}
                  />
              </div>
                )}
            <div>
              <ManagerSelect onChange={(e) => setSelectedManagerId(e)} />
            </div>
            <div>
              <Button.Basic
                primary
                text="Next Step"
                disabled={!selectedManagerId}
                onClick={onManagerSelect}
                style={{ margin: "10px 10px 10px 0" }}
              />
            </div>
          </React.Fragment>
        )}
        {activeStep === STEP_ENTRY && (
          <React.Fragment>
            <div>
              {selectedManagerId && (
                <div style={{ display: "flex", marginBottom: "10px" }}>
                    <strong>Contractor: {selectedManagerId.manager.name}</strong> 
                    <Button.Clear
                      person
                      onClick={onManagerClear}
                    />
                </div>
                  )}
                {!id && (
                  <Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_MANAGER)}
                  style={{ margin: "10px 10px 10px 0" }}
                  />
                )}
              <BasicForm
                error={error}
                fields={fields}
                loading={isLoading}
                onSubmit={onSubmit}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

function mapStateToProps(state) {
  return {
    managerId: state.manager ? state.manager.id : null,
  };
}

export default connect(mapStateToProps)(RepresentativeManagerForm);

RepresentativeManagerForm.defaultProps = {
  onSuccess: () => alert("On Success"),
  id: null,
  status: 0,
  managerPointsPercentage: "",
};
