import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import { Button, FilterSelect, PaginatedTable, BasicTable } from "@components/shared";

export default function Table({ contractorId, contractorInvoices, contractorView }) {
  const [activeFilter, setActiveFilter] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [updated, setUpdated] = useState(false);
  
  const invoiceTypeOptions = [
    { text: "Materials", value: 0 },
    { text: "Labor", value: 1 },
    { text: "Warrantee", value: 2 },
    { text: "Project Mangement", value: 3 },
    { text: "Marketing", value: 4 },
    { text: "Miscellaneous", value: 5 },
  ];

  const columns = [
    {
      Header: "",
      id: "view",
      width: 50,
      accessor: (d) => <Button.View url={`/contractor-invoices/${d.id}`} />,
    },
    {
      Header: "Contractor",
      accessor: "contractor.name",
      id: "contractor_name",
      sortable: false,
    },
    {
      Header: "Job",
      accessor: "job.name",
      id: "job_name",
      sortable: false,
    },
    {
      Header: "Invoice Type",
      accessor: "invoice_type_formatted",
      id: "invoice_type_formatted",
      sortable: false,
    },
    {
      Header: "Invoice Date",
      accessor: "invoice_date_formatted",
      id: "invoice_date_formatted",
      sortable: true,
      width: 120,
    },
    {
      Header: "Amount",
      accessor: "amount_formatted",
      id: "amount_formatted",
      sortable: false,
      width: 115,
    },
    {
      Header: "Paid",
      accessor: (d) =>
        d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
      id: "paid",
      sortable: true,
      
    },
    {
      Header: "Notes",
      accessor: "notes",
      id: "notes",
      sortable: false,
    },
  ]
  .filter(column => 
    !(contractorInvoices && column.id === "contractor_name"))
  .map((m) => ({
    ...m,
    filterable: false,
  })).filter((f) => 
    contractorView ? !["contractor_name"].includes(f.id) : true
  );

  const additionalPostData = {
    status: JSON.parse(activeFilter),
  };

  return (
    <React.Fragment>
      {!contractorInvoices && (
        <React.Fragment>
          <FilterSelect
            options={statusOptions}
            value={activeFilter}
            onChange={(e) => setActiveFilter(e)}
            entityName="Status"
          />
          <PaginatedTable
            columns={columns}
            additionalPostData={additionalPostData}
            entityName="Contractor Invoices"
            filter={activeFilter}
            filterOptions={filterOptions}
            keyName="data"
            refresh={refresh}
            url="/retrieve-contractor-job-links/"
          />
        </React.Fragment>
      )}
      {contractorInvoices && (
        <BasicTable
          columns={columns}
          data={contractorInvoices}
          entityName="Contractor Invoices for Payment"
        />
      )}

    </React.Fragment>
  );
}

Table.defaultProps = {};

const statusOptions = [
  { text: "All", value: `[true, false]` },
  { text: "Unpaid", value: false },
  { text: "Paid", value: true },
];

const filterOptions = [{ name: "name", obj: "self" }];
