import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { BasicGrid, Card, Button, EmptyList } from "@components/shared";
import { Table } from "@components/RepresentativeSalaryPayment";

import { getRoleInfo } from "@util/roleInfo";

export default function RepresentativeSalaryPayments({
  representativeId,
  onUpdate,
}) {
  const [representativeSalaryPayments, setRepresentativeSalaryPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchRepresentativeSalaryPayments();
  }, []);

  function fetchRepresentativeSalaryPayments() {
    const req = {
      filtered: [],
      page: 0,
      page_size: 500,
      sorted: [],
			status: [true, false],
      representative_id: representativeId,
    };

    axios
      .post(`/retrieve-representative-salary-payments/`, req)
      .then(({ data }) => {
        setRepresentativeSalaryPayments(data.data);
      })
      .catch((err) => {
        setLoading(false);
        setRepresentativeSalaryPayments([]);
      });
  }

  return (
    <div>
    <Table representativeView={true} representativeSalaryPayments={representativeSalaryPayments} />
    </div>
  );
}

RepresentativeSalaryPayments.defaultProps = {
  setRepresentativeSalaryPayments: [],
};
