import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import * as Yup from "yup";
import { BasicForm } from "@components/shared";

import { statusOptions } from "./status";

import { getRoleInfo } from "@util/roleInfo";

function ContractorForm({
  email,
  name,
  phone,
  addressOne,
  addressTwo,
  city,
  state,
  zipcode,
  notes,
  id,
  isProfile,
  status,
  contractorId,
  onSuccess,
  dispatch,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      status: 0,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/contractors/`, req)
      .then(({ data }) => {
        return data.id;
      })
      .then(() => setLoading(false))
      .then(() => {
        toast.success("Contractor Created!");
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/contractors/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Contractor Updated!"))
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  const fields = [
    {
      name: "name",
      initialValue: name,
      label: "Name/Company",
      required: true,
      schema: () =>
        Yup.string()
          .min(2, "Too Short!")
          .max(140, "Too Long!")
          .required("Required"),
    },
    {
      name: "email",
      initialValue: email,
      label: "Email",
      required: true,
      schema: () =>
        Yup.string().email("Not a valid email").required("Required"),
    },
    {
      name: "phone",
      initialValue: phone,
      label: "Phone",
      required: false,
      schema: () => Yup.string().min(2, "Too Short!"),
    },
    {
      name: "address_1",
      initialValue: addressOne,
      label: "Address 1",
      required: false,
      schema: () => Yup.string().min(2, "Too Short!"),
    },
    {
      name: "address_2",
      initialValue: addressTwo,
      label: "Address 2",
      required: false,
      schema: () => Yup.string().min(2, "Too Short!"),
    },
    {
      name: "city",
      initialValue: city,
      label: "City",
      required: false,
      schema: () => Yup.string().min(2, "Too Short!"),
    },
    {
      name: "state",
      initialValue: state,
      label: "State",
      required: false,
      schema: () => Yup.string().min(2, "Too Short!"),
    },
    {
      name: "zipcode",
      initialValue: zipcode,
      label: "Zipcode",
      required: false,
      schema: () => Yup.string().min(2, "Too Short!"),
    },
    {
      name: "notes",
      initialValue: notes,
      label: "Notes",
      required: false,
      schema: () => Yup.string().max(50, "Too Long!"),
    },
    {
      name: "status",
      initialValue: status,
      label: "Status",
      select: true,
      required: true,
      options: statusOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
  ]
    .filter((f) =>
      !id ? !["status"].includes(f.name) : true
    )
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_view_only ? true : false,
    }));

  return (
    <BasicForm
      error={error}
      fields={fields}
      loading={isLoading}
      onSubmit={onSubmit}
    />
  );
}

function mapStateToProps(state) {
  return {
    contractorId: state.contractor ? state.contractor.id : null,
  };
}

export default connect(mapStateToProps)(ContractorForm);

ContractorForm.defaultProps = {
  email: "",
  name: "",
  onSuccess: () => alert("On Success"),
  phone: "",
  status: "",
  addressOne: "",
  addressTwo: "",
  city: "",
  state: "",
  zipcode: "",
  notes: "",
};
