import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "semantic-ui-react";

export default function ManagerSelect({ onChange }) {
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    const req = {
      status: 0,
    };

    axios.post(`/lookup-managers/`, req).then(({ data }) => {
      setManagers(
        data.data.map((d) => ({
          key: d.id,
          text: d.full_name,
          value: d.id,
					managerpointspercentage: d.manager_points_percentage,
        }))
      );
      setLoading(false);
    });
  }

  const handleChange = (e, { value }) => {
    const selectedManager = managers.find(c => c.value === value);
    if (selectedManager) {
      onChange({
        manager: { name: selectedManager.text, 
									 value: selectedManager.value, 
									 managerpointspercentage: selectedManager.managerpointspercentage,
									}
      });
    }
  };

  return (
    <div>
      <Form>
        <Form.Select
          placeholder="Select a manager..."
          loading={loading}
          options={managers}
          search
          onChange={handleChange}
        />
      </Form>
    </div>
  );
}

ManagerSelect.defaultProps = {
  selected: [],
};