import React, { useState } from "react";

import { Button, FilterSelect, PaginatedTable, BasicTable } from "@components/shared";
import { Icon } from "semantic-ui-react";

export default function Table({ representativeId, jobPaymentLinks }) {
  const [activeFilter, setActiveFilter] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [updated, setUpdated] = useState(false);

  const columns = [
    {
      Header: "",
      id: "view",
      width: 50,
      accessor: (d) => <Button.View url={`/representative-jobs/${d.id}`} />,
    },
    {
      Header: "Job",
      accessor: "job.name",
      id: "job_name",
      sortable: false,
			width: 160,
    },
		{ 
			Header: "Job Total", 
			accessor: "job.job_total_formatted",
      id: "job_job_total_formatted",
      sortable: false,
			width: 120,
		},
		{ 
			Header: "Total Cost", 
			accessor: "job.total_cost_formatted",
      id: "job_total_cost_formatted",
      sortable: false,
			width: 120,
		},
    {
      Header: "Date Paid",
      accessor: "representative_payment.date_paid_formatted",
      id: "representative_payment_date_paid_formatted",
      sortable: false,
    },
    {
      Header: "Override %",
      accessor: "override_percentage",
      id: "override_percentage",
      sortable: false,
      width: 120,
    },
		{ 
			Header: "Paid Lead", 
		  accessor: (d) =>
		    d.job.paid_lead ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
			id: "job_paid_lead",
      sortable: false,
      width: 100,
		},
    {
      Header: "Paid Lead %",
      accessor: "paid_lead_percentage",
      id: "paid_lead_percentage",
      sortable: false,
      width: 120,
    },
    {
      Header: "Split %",
      accessor: "split_percentage",
      id: "split_percentage",
      sortable: false,
      width: 120,
    },
    {
      Header: "Commission Ratio",
      accessor: "commission_ratio_formatted",
      id: "commission_ratio_formatted",
      sortable: false,
      width: 150,
    },
		{ 
			Header: "Commission", 
			accessor: "commission_formatted",
			id: "commission_formatted",
      sortable: false,
      width: 120,
		 },
  ]
  .filter(column => 
    !(jobPaymentLinks && column.id === "representative_name"))
  .map((m) => ({
    ...m,
    sortable: false,
    filterable: false,
  }));

  const additionalPostData = {
    status: JSON.parse(activeFilter),
		representative_id: representativeId,
  };

  return (
    <React.Fragment>
      {!jobPaymentLinks && (
        <React.Fragment>
          <FilterSelect
            options={statusOptions}
            value={activeFilter}
            onChange={(e) => setActiveFilter(e)}
            entityName="Status"
          />
          <PaginatedTable
            columns={columns}
            additionalPostData={additionalPostData}
            entityName="Representative Jobs"
            filter={activeFilter}
            filterOptions={filterOptions}
            keyName="data"
            refresh={refresh}
            url="/representative-job-payment-links/"
          />
        </React.Fragment>
      )}
      {jobPaymentLinks && (
        <BasicTable
          columns={columns}
          data={contractorInvoices}
          entityName="Representative Jobs"
        />
      )}

    </React.Fragment>
  );
}

Table.defaultProps = {};

const statusOptions = [
	{ text: "All", value: `[true, false]` },
  { text: "Unpaid", value: false },
  { text: "Paid", value: true },
];

const filterOptions = [{ name: "name", obj: "self" }];
