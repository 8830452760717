import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import styled from "styled-components";

const Text = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
    fill: var(--warningColor);
  }
`;

export default function Warning({ text }) {
  return (
    <Text>
      <FaExclamationTriangle /> {text}
    </Text>
  );
}

Warning.defaultProps = {
  text: "Default Warning",
};
