import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RepresentativeCorrectionForm, Table } from "@components/RepresentativeCorrection";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";
import { getRoleInfo } from "@util/roleInfo";

export default function RepresentativeCorrectionsIndex() {
  const [isOpen, setOpen] = useState(false);
  
  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <div>
		  <Wrapped
		    title="Representative Corrections"
		    button={
		      (roleInfo.admin_full || roleInfo.admin_partial) && (
		        <Button.Toggle
		          closedText="Add Representative Correction"
		          onClick={() => setOpen(!isOpen)}
		          open={isOpen}
		        />
		      )
		    }
		  >
		    {isOpen ? <RepresentativeCorrectionForm onSuccess={() => setOpen(false)} /> : <Table representativeView={false} />}
		  </Wrapped>
      
    </div>
  );
}
