import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { BasicGrid, Card, Button, EmptyList } from "@components/shared";
import { Table } from "@components/RepresentativeDraw";

import { getRoleInfo } from "@util/roleInfo";

export default function RepresentativeDraws({
  representativeId,
  onUpdate,
}) {
  const [representativeDraws, setRepresentativeDraws] = useState([]);
  const [loading, setLoading] = useState(false);
  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchRepresentativeDraws();
  }, []);

  function fetchRepresentativeDraws() {
    const req = {
      filtered: [],
      page: 0,
      page_size: 500,
      sorted: [],
			status: [true, false],
      representative_id: representativeId,
    };

    axios
      .post(`/retrieve-representative-draws/`, req)
      .then(({ data }) => {
        setRepresentativeDraws(data.data);
      })
      .catch((err) => {
        setLoading(false);
        setRepresentativeDraws([]);
      });
  }

  return (
    <div>
    <Table representativeView={true} representativeDraws={representativeDraws} />
    </div>
  );
}

RepresentativeDraws.defaultProps = {
  setRepresentativeDraws: [],
};
