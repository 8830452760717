import axios from "axios";
import browserCrypto from "crypto-browserify";

import { apiKey, timestamp, secureHash } from "@util/authInfo";

function encryptPayload(payload) {
  if (process.env.ENV !== "production") return payload;

  const appSecret = process.env.APP_SECRET;
  const key = apiKey.substring(0, 16);
  const secret = Buffer.from(appSecret, "base64");
  const iv = key;
  const cipher = browserCrypto.createCipheriv(
    "aes-256-cbc",
    Buffer.from(secret, iv),
    iv
  );
  const encrypted = cipher.update(payload);
  const encryptedConcat = Buffer.concat([encrypted, cipher.final()]);
  return encryptedConcat.toString("base64");
}

function decryptPayload(payload) {
  if (process.env.ENV !== "production") return payload;

  const appSecret = process.env.APP_SECRET;
  const key = apiKey.substring(0, 16);
  const secret = Buffer.from(appSecret, "base64");
  const iv = key;
  const decipher = browserCrypto.createDecipheriv(
    "aes-256-cbc",
    Buffer.from(secret, iv),
    iv
  );

  const decrypted = decipher.update(
    payload.data.response[0].data,
    "base64",
    "utf8"
  );
  return {
    ...payload,
    data: {
      ...payload.data,
      response: [...JSON.parse(decrypted + decipher.final("utf8"))],
    },
  };
}

axios.defaults.baseURL = "/api";

axios.interceptors.request.use(
  (request) => {
    request.headers["api-key"] = apiKey;
    request.headers["timestamp"] = timestamp;
    request.headers["secure-hash"] = secureHash;

    if (
      requestWhitelist.includes(request.url) ||
      request.url.indexOf("/code/") > -1 ||
      request.url.indexOf("/set-session/") > -1 ||
      request.url.indexOf("/verify-code") > -1
    ) {
      request.headers["internal"] = true;
    }
    if (process.env.ENV !== "production") return request;
    if (request.data) {
      request.data = { data: encryptPayload(JSON.stringify(request.data)) };
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // * if response isn't set up with response codes
    if (response.data.response_code === undefined) {
      return response;
    }

    // return to login
    if (
      response.data.response_code === 2 &&
      response.data.response_message &&
      response.data.response_message === "Back to Login"
    ) {
      // window.location = `/admin`;
      return (window.location = `/admin`);
    }

    // ! throw the response message
    if (response.data.response_code !== 0) {
      if (response.data.response_code === 2 && response.data.response) {
        // return response;
        return response;
      }
      throw response.data.response_message;
    }

    if (!response.data.response.length) return response;

    return decryptPayload(response);

    // return response;
  },
  (error) => {
    let formattedError =
      typeof error !== "string"
        ? `There was a problem. Try again later.`
        : error;

    return Promise.reject(formattedError);
  }
);

export default axios;

const requestWhitelist = [
  `/admin-forgot`,
  `/admin-login`,
  `/forgot-password`,
  `/status`,
  `/user-login`,
  `/user-forgot`,
  `/resend-signup`,
  `/mfa-verification`,
  `/password`,
].map((w) => `${w}/`);
