import React, { useState } from "react";

import { Button, FilterSelect, PaginatedTable } from "@components/shared";
import { Icon } from "semantic-ui-react";
import { EditModal } from "@components/RepresentativeJobSelection";

export default function Table({ representativeView }) {
  const [activeFilter, setActiveFilter] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [updated, setUpdated] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [representative, setRepresentative] = useState(null);
	
	const handleOpenEditModal = (representative) => {
	  setRepresentative(representative);
	  setIsEditModalOpen(true);
	};

  const columns = [
		{
		  Header: "",
		  id: "edit",
		  width: 50,
		  accessor: (d) => (
		    <Button.View onClick={() => handleOpenEditModal({
		      representative: {
		        name: d.full_name, 
		        value: d.id, 
		        globalCommissionRatio: d.global_commission_ratio, 
		        representativeOverridePercentage: d.override_percentage,
		        representativePaidLeadPercentage: d.paid_lead_percentage
		      }
		    })} />
		  ),
		},
    {
      Header: "Representative",
      accessor: "full_name",
      id: "representative_name",
      sortable: false,
    },
    {
      Header: "Commissions",
      accessor: "unpaid_commissions_count",
      id: "unpaid_commissions_count",
      sortable: false,
    },
    {
      Header: "Points",
      accessor: "unpaid_points_count",
      id: "unpaid_points_count",
      sortable: false,
    },
    {
      Header: "Salaries",
      accessor: "unpaid_salaries_count",
      id: "unpaid_salaries_count",
      sortable: false,
    },
    {
      Header: "Knockings",
      accessor: "unpaid_knocking_count",
      id: "unpaid_knocking_count",
      sortable: false,
    },
    {
      Header: "Reimbursements",
      accessor: "unpaid_reimbursements_count",
      id: "unpaid_reimbursements_count",
      sortable: false,
    },
    {
      Header: "Draws",
      accessor: "unsettled_draws_count",
      id: "unsettled_draws_count",
      sortable: false,
    },
    {
      Header: "Bonuses",
      accessor: "unpaid_bonuses_count",
      id: "unpaid_bonuses_count",
      sortable: false,
    },
    {
      Header: "Corrections",
      accessor: "unpaid_corrections_count",
      id: "unpaid_corrections_count",
      sortable: false,
    },
  ].map((m) => ({
    ...m,
    filterable: false,
  })).filter((f) => 
    representativeView ? !["representative_name"].includes(f.id) : true
  );


  const additionalPostData = {
    status: JSON.parse(activeFilter),
  };

  return (
    <React.Fragment>
			{isEditModalOpen && <EditModal representative={representative} onClose={() => setIsEditModalOpen(false)} onSuccess={() => window.location.href = `/ach/`} />}
      <PaginatedTable
        columns={columns}
        additionalPostData={additionalPostData}
        entityName="Unpaid Representatives"
        filterOptions={filterOptions}
        keyName="data"
        refresh={refresh}
        url="/retrieve-unpaid-representatives/"
      />
    </React.Fragment>
  );
}

Table.defaultProps = {};

const filterOptions = [{ name: "name", obj: "self" }];
