import Dashboard from "./Dashboard";
import ContractorsDetail from "./ContractorsDetail";
import ContractorsIndex from "./ContractorsIndex";
import Profile from "./Profile";
import ReportsIndex from "./ReportsIndex";

export {
  Dashboard,
  ContractorsDetail,
  ContractorsIndex,
  Profile,
  ReportsIndex,
};
