import React, { useState, useEffect } from "react";
import axios from "axios";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { JobRepresentativeForm } from "@components/JobRepresentative";

export default function ContractorInvoicesDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jobPaymentLink, setJobPaymentLink] = useState(populateJobPaymentLink());

  const jobPaymentLinkId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/job-payment-links/${jobPaymentLinkId}/`)
      .then(({ data }) => {
        setLoading(false);
        setJobPaymentLink(populateJobPaymentLink(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!jobPaymentLink) return null;

  return (
    <div>
      <PageTitle
        title={`Commission settings for ${jobPaymentLink.representative.full_name}`}
        subtitle={`Commission on Job: ${jobPaymentLink.job.name}`}
      />
      <Wrapped>
        <JobRepresentativeForm
          id={jobPaymentLinkId}
          jobId={jobPaymentLink.job.id}
					jobType={jobPaymentLink.job.job_type}
					jobTypeName={jobPaymentLink.job.job_type_formatted}
          overridePercentage={jobPaymentLink.overridePercentage}
          paidLeadPercentage={jobPaymentLink.paidLeadPercentage}
          splitPercentage={jobPaymentLink.splitPercentage}
          commissionRatio={jobPaymentLink.commissionRatio}
					ratioOverride={jobPaymentLink.ratioOverride}
          paid={jobPaymentLink.paid}
          notes={jobPaymentLink.notes}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateJobPaymentLink(d = {}) {
  return {
    representative: d.representative || "",
    job: d.job || "",
    overridePercentage: d.override_percentage,
    paidLeadPercentage: d.paid_lead_percentage || "",
    splitPercentage: d.split_percentage || "",
    commissionRatio: d.commission_ratio,
		ratioOverride: d.ratio_override,
    paid: d.paid,
    notes: d.notes || "",
  };
}
