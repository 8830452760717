import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import reduceReducers from "reduce-reducers";
import initialState from "./initialState";

import adminReducer from "./admin";
import representativeReducer from "./representative";
import loadingReducer from "./loading";

const rootReducer = reduceReducers(
  initialState,
  adminReducer,
  representativeReducer,
  loadingReducer
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers()
  // composeEnhancers(
  //   applyMiddleware(
  //     createSentryMiddleware(Sentry),
  //     thunk
  //   ),
  // )
);
