import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Icon } from "semantic-ui-react";

import { Button, FilterSelect, BasicTable } from "@components/shared";

export default function Table( { jobId } ) {
  const [loading, setLoading] = useState(false);
  const [jobPaymentLinks, setJobPaymentLinks] = useState(null);

  useEffect(() => {
    fetchJobPaymentLinks();
  }, []);

  function fetchJobPaymentLinks() {
    const req = {
      filtered: [],
      page: 0,
      page_size: 500,
      sorted: [],
      job_id: +jobId,
      status: [true, false],
    };

    axios
      .post(`/retrieve-job-payment-links/`, req)
      .then(({ data }) => {
        setJobPaymentLinks(data.data);
      })
      .catch((err) => {
        setLoading(false);
        setJobPaymentLinks([]);
      });
  }

  const columns = [
    {
      Header: "",
      id: "view",
      width: 50,
      accessor: (d) => <Button.View url={`/job-representative/${d.id}`} />,
    },
    {
      Header: "Name",
      accessor: "representative.full_name",
      id: "representative_full_name",
      sortable: false,
    },
    {
      Header: "Override %",
      accessor: "override_percentage",
      id: "override_percentage",
      sortable: false,
    },
    {
      Header: "Paid Lead %",
      accessor: "paid_lead_percentage",
      id: "paid_lead_percentage",
      sortable: false,
    },
    {
      Header: "Split %",
      accessor: "split_percentage",
      id: "split_percentage",
      sortable: false,
    },
    {
      Header: "Commission Ratio",
      accessor: "commission_ratio_formatted",
      id: "commission_ratio_formatted",
      sortable: false,
    },
    {
      Header: "Paid",
      accessor: (d) =>
        d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
      id: "paid",
      sortable: true,
      
    },
    {
      Header: "Notes",
      accessor: "notes",
      id: "notes",
      sortable: false,
    },
  ];

  return (
    <React.Fragment>
      <BasicTable
        columns={columns}
        entityName="Representatives for Job"
        data={jobPaymentLinks}
      />
    </React.Fragment>
  );
}

const filterOptions = [
  { name: "first_name", obj: "self" },
  { name: "last_name", obj: "self" },
];
