import React, { useState } from "react";
import { JobForm, Table } from "@components/Job";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

export default function JobsIndex({}) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Jobs"
        button={
          <Button.Toggle
            closedText="Add Job"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        }
      >
        {isOpen ? <JobForm onSuccess={() => setOpen(false)} /> : <Table />}
      </Wrapped>
    </div>
  );
}
