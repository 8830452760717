import React, { useState } from "react";
import styled from "styled-components";
import { Form, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useSelector } from "react-redux";

import { Button, FilterSelect, PaginatedTable } from "@components/shared";
import { getRoleInfo } from "@util/roleInfo";
import { size, device } from "@util/breakpoints";

export default function ManagedJobTable({ isAdmin, representativeId }) {
  const [activeStatusFilter, setActiveStatusFilter] = useState(`[true, false]`);
  const [activeTypeFilter, setActiveTypeFilter] = useState(`[0, 1, 2]`);
  const [activeClassificationFilter, setActiveClassificationFilter] = useState(`[0, 1]`);
  const [searchValue, setSearchValue] = useState("");

  const roleInfo = getRoleInfo(useSelector((state) => state));
  const columns = [
    {
      Header: "Name",
      accessor: "name",
			width: 400,
      sortable: true,
    },
    {
      Header: "Leap Job ID",
      accessor: "leap_job_id",
			width: 150,
      sortable: true,
			width: 200,
    },
    {
      Header: "Job Total",
      accessor: "job_total_formatted",
      sortable: false,
			width: 120,
    },
    {
      Header: "Total Cost",
      accessor: "total_cost_formatted",
      sortable: false,
			width: 150,
    },
    {
      Header: "Profit %",
      accessor: "profit_margin_percentage",
      sortable: false,
			width: 120,
    },
    {
      Header: "Squares",
      accessor: "squares",
      sortable: false,
			width: 100,
    },
    {
      Header: "Price Per Square",
      accessor: "price_per_square_formatted",
      sortable: false,
			width: 150,
    },
    {
      Header: "Cost Per Square",
      accessor: "cost_per_square_formatted",
      sortable: false,
			width: 150,
    },
    {
      Header: "Type",
      accessor: "job_type_formatted",
      id: "job_type_formatted",
			width: 200,
      sortable: false,
    },
    {
      Header: "Classification",
      accessor: "job_classification_formatted",
      id: "job_classification_formatted",
			width: 200,
      sortable: false,
    },
  ]
  
  const additionalPostData = {
		representative_id: representativeId,
    status: JSON.parse(activeStatusFilter),
    type: JSON.parse(activeTypeFilter),
    classification: JSON.parse(activeClassificationFilter),
    value: searchValue.length >= 2 ? searchValue : null,
  };

  return (
    <React.Fragment>
      <FilterSelect
        options={typeOptions}
        value={activeTypeFilter}
        onChange={(e) => setActiveTypeFilter(e)}
        entityName="Type"
      />
      <FilterSelect
        options={classificationOptions}
        value={activeClassificationFilter}
        onChange={(e) => setActiveClassificationFilter(e)}
        entityName="Classification"
      />
      <Form style={{ marginBottom: "1em" }}>
        <Form.Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search jobs with above filter settings..."
          width={window.innerWidth >= size.laptop ? 4 : 16}
        />
      </Form>
      <PaginatedTable
        columns={columns}
        additionalPostData={additionalPostData}
        entityName="Jobs"
        filter={activeStatusFilter}
        filterOptions={filterOptions}
        keyName="data"
        url="/retrieve-jobs/"
      />
    </React.Fragment>
  );
}

const typeOptions = [
  { text: "All", value: `[0, 1, 2]` },
  { text: "Insurance", value: 0 },
  { text: "Full Roof Retail", value: 1 },
  { text: "Repair Retail", value: 2 },
];

const classificationOptions = [
  { text: "All", value: `[0, 1]` },
  { text: "Residential", value: 0 },
  { text: "Commercial", value: 1 },
];

const filterOptions = [
  { name: "name", obj: "self" },
];
