import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { BasicForm, Button } from "@components/shared";
import { RepresentativeSelect } from "@components/Representative";
import { getRoleInfo } from "@util/roleInfo";

const STEP_REPRESENTATIVE = "representer";
const STEP_ENTRY = "entry";

export default function JobRepresentativeForm({
  id,
  representativeFullName,
  jobId,
  jobName,
	jobType,
	jobTypeName,
  overridePercentage,
  paidLeadPercentage,
  splitPercentage,
  commissionRatio,
	ratioOverride,
  notes,
  onSuccess,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(id ? STEP_ENTRY : STEP_REPRESENTATIVE);
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const roleInfo = getRoleInfo(useSelector((state) => state));

  function onRepresentativeSelect() {
    setActiveStep(STEP_ENTRY);
  }
  
  function onRepresentativeClear() {
    setActiveStep(STEP_REPRESENTATIVE);
    setSelectedRepresentative(null);
  }
  

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      representative_id: selectedRepresentative.representative.value,
      job_id: jobId,
			override_percentage: selectedRepresentative.representative.representativeOverridePercentage,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/job-payment-links/`, req)
      .then(({ data }) => {
        return data.id;
      })
      .then(() => setLoading(false))
      .then(() => {
        toast.success("Job Representative Added!");
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/job-payment-links/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Job Representative Updated!"))
      .then((newId) => {
        onSuccess(newId);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
  
  const commissionRatioOptions = [
    { text: "60/40", value: 0 },
    { text: "50/50", value: 1 },
  ];

  const fields = [
    {
      name: "override_percentage",
      initialValue: selectedRepresentative ? selectedRepresentative.representative.representativeOverridePercentage : overridePercentage,
      label: id && selectedRepresentative && selectedRepresentative.representative.representativeOverridePercentage !== overridePercentage 
                    ? "Override % (differs from representative's global settings)"
                    : "Override %",
      required: true,
      schema: () =>
        Yup.string()
          .min(1, "Too Short!")
          .max(3, "Too Long!")
          .required("Required"),
    },
    {
      name: "paid_lead_percentage",
      initialValue: selectedRepresentative ? selectedRepresentative.representative.representativePaidLeadPercentage : paidLeadPercentage,
      label: id && selectedRepresentative && selectedRepresentative.representative.representativePaidLeadPercentage !== paidLeadPercentage 
                    ? "Paid Lead % (differs from representative's global settings)"
                    : "Paid Lead %",
      required: true,
      schema: () =>
        Yup.string()
          .min(1, "Too Short!")
          .max(3, "Too Long!")
          .required("Required"),
    },
    {
      name: "split_percentage",
      initialValue: splitPercentage,
      label: "Split %",
      required: true,
      schema: () =>
        Yup.string()
          .min(1, "Too Short!")
          .max(3, "Too Long!")
          .required("Required"),
    },
    {
      grouped: true,
      fields: [
		    {
		      name: "commission_ratio",
					label: selectedRepresentative && selectedRepresentative.representative.globalCommissionRatio !== 0 
					        ? "Commission Ratio (set from representative's global settings)"
					        : `Commission Ratio (set by Job Type: ${jobTypeName})`,
		      initialValue: selectedRepresentative && selectedRepresentative.representative.globalCommissionRatio !== 0 
		                    ? (selectedRepresentative.representative.globalCommissionRatio - 1) 
					: (!id && jobType === 0 ? 1 : commissionRatio),
		      select: true,
		      options: commissionRatioOptions,
		      required: true,
		      schema: () => Yup.number().nullable().required("Required"),
		    },
		    {
		      name: "ratio_override",
		      label: "Ratio Override",
		      initialValue: ratioOverride,
		      required: true,
		      radio: true,
		      trueText: "Yes",
		      falseText: "No",
		      schema: () => null,
		    },
      ],
    },
    {
      name: "notes",
      initialValue: notes,
      label: "Notes",
      required: false,
      schema: () => Yup.string().max(50, "Too Long!"),
    },
  ]
		.filter((f) => (!id ? f.name !== "override_percentage" : true))
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_view_only ? true : false,
    }));

  return (
    <div>
    {!id && activeStep === STEP_REPRESENTATIVE && (
      <React.Fragment>
        {selectedRepresentative && (
          <div style={{ display: "flex", marginBottom: "10px" }}>
              <strong>Representative: {selectedRepresentative.representative.name}</strong> 
              <Button.Clear
                person
                onClick={onRepresentativeClear}
              />
          </div>
            )}
        <div>
          <RepresentativeSelect onChange={(e) => setSelectedRepresentative(e)} />
        </div>
        <div>
          <Button.Basic
            primary
            text="Next Step"
            disabled={!selectedRepresentative}
            onClick={onRepresentativeSelect}
            style={{ margin: "10px 10px 10px 0" }}
          />
        </div>
      </React.Fragment>
    )}
    {activeStep === STEP_ENTRY && (
      <React.Fragment>
        <div>
          {selectedRepresentative && (
            <div style={{ display: "flex", marginBottom: "10px" }}>
                <strong>Representative: {selectedRepresentative.representative.name}</strong> 
                <Button.Clear
                  person
                  onClick={onRepresentativeClear}
                />
            </div>
              )}
            {!id && (
              <Button.Basic
              text="Back"
              onClick={() => setActiveStep(STEP_REPRESENTATIVE)}
              style={{ margin: "10px 10px 10px 0" }}
              />
            )}
          <BasicForm
            error={error}
            fields={fields}
            loading={isLoading}
            onSubmit={onSubmit}
            showButton={!id ? true : roleInfo.admin_full ? true : false}
          />
        </div>
      </React.Fragment>
    )}
    </div>
  );
}

JobRepresentativeForm.defaultProps = {
  id: null,
  representative: "",
  job: "",
  paidLeadPercentage: "",
  pointsPercentage: "",
  overridePercentage: "",
  splitPercentage: "100",
  commissionRatio: 0,
	ratioOverride: false,
  paid: "",
  notes: "",
  onSuccess: () => alert("On Success"),
};
