import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { RepresentativeDrawForm } from "@components/RepresentativeDraw";

export default function DrawsDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [representativeDraw, setRepresentativeDraw] = useState(populateRepresentativeDraw());

  const representativeDrawId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/representative-draws/${representativeDrawId}/`)
      .then(({ data }) => {
        setLoading(false);
        setRepresentativeDraw(populateRepresentativeDraw(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!representativeDraw) return null;

  return (
    <div>
      <PageTitle
        title={`${representativeDraw.representative.full_name}`}
        subtitle="Representative Draw"
      />
      <Wrapped>
				<p>
					<Link to="/representative-draws"><strong>Draws</strong></Link>
        </p>
        <RepresentativeDrawForm
          id={representativeDrawId}
          amountFormatted={representativeDraw.amountFormatted}
          paid={representativeDraw.paid}
          date={representativeDraw.date}
          postedToAch={representativeDraw.postedToAch}
          achReference={representativeDraw.achReference}
          notes={representativeDraw.notes}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateRepresentativeDraw(d = {}) {
  return {
    representative: d.representative || "",
    amountFormatted: d.amount_formatted || "",
    date: d.date || "",
    paid: d.paid,
    postedToAch: d.posted_to_ach,
    achReference: d.ach_reference || "",
    notes: d.notes || "",
  };
}
