import React from 'react';
import { FaEnvelope } from 'react-icons/fa';

export default function EmailIcon({ size }) {
  return <FaEnvelope size={size} />;
}

EmailIcon.defaultProps = {
  size: 18,
  color: 'red'
};
