import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import * as Yup from "yup";
import { BasicForm, Button, BasicTable } from "@components/shared";
import { RepresentativeSelect } from "@components/Representative";
import { getRoleInfo } from "@util/roleInfo";
import { Icon } from "semantic-ui-react";

const STEP_REPRESENTATIVE = "representative";
const STEP_ENTRY = "entry";

function RepresentativeDrawForm({
  id,
  date,
  amountFormatted,
  paid,
  postedToAch,
  achReference,
  notes,
	drawCollections,
  onFormSubmit,
  onSuccess,
  dispatch,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(id ? STEP_ENTRY : STEP_REPRESENTATIVE);
  const [selectedRepresentative, setSelectedRepresentative] = useState(null);
  const roleInfo = getRoleInfo(useSelector((state) => state));


  function onRepresentativeSelect() {
    setActiveStep(STEP_ENTRY);
  }
  
  function onRepresentativeClear() {
    setActiveStep(STEP_REPRESENTATIVE);
    setSelectedRepresentative(null);
  }

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      representative_id: selectedRepresentative.representative.value,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/representative-draws/`, req)
      .then(({ data }) => {
        return data.id;
      })
      .then(() => setLoading(false))
      .then(() => {
        toast.success("Representative Draw Created!");
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/representative-draws/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Representative Draw Updated!"))
      .then((newId) => {
        onSuccess(newId);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
  
  const statusOptions = [
    { text: "Pending", value: 0 },
    { text: "Complete", value: 1 },
  ];

  const fields = [
    {
      grouped: true,
      fields: [
        {
          name: "paid",
          label: "Paid",
          initialValue: paid,
          required: true,
          radio: true,
          trueText: "Yes",
          falseText: "No",
          schema: () => null,
        },
        {
          name: "date",
          initialValue: date
                ? new Date(date)
                : null,
          label: "Date Paid (optional)",
          datepicker: true,
          required: false,
          schema: () =>
            Yup.string().nullable(),
        },
      ],
    },
    {
      name: "amount_formatted",
      initialValue: amountFormatted,
      label: "Amount",
      required: true,
      schema: () => Yup.number().typeError('Amount must be a numbers only.').positive("Negative numbers not allowed").required("Required"),
    },
    {
      grouped: true,
      fields: [
        {
          name: "posted_to_ach",
          label: "Posted To ACH",
          initialValue: postedToAch,
          required: true,
          radio: true,
          trueText: "Yes",
          falseText: "No",
          schema: () => null,
        },
        {
          name: "ach_reference",
          initialValue: achReference,
          label: "ACH Reference",
          required: false,
          schema: () => Yup.string().max(20, "Too Long!"),
        },
      ],
    },
    {
      name: "notes",
      initialValue: notes,
      label: "Notes",
      required: false,
      schema: () => Yup.string().max(50, "Too Long!"),
    },
  ]
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_view_only ? true : false,
    }));
		
	  let drawCollectionsColumns = [
		  { Header: "Notes", accessor: "notes" },
			{ Header: "Date Applied", accessor: "date_applied", width: 120 },
			{
			  Header: "Amount",
			  accessor: "amount_formatted",
			  width: 120,
			 },
			{ Header: "Collected", 
		  accessor: (d) =>
		    d.ach_payment ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
			id: "ach_payment", width: 150
			},
			{ Header: "ACH Reference", accessor: "ach_reference", width: 200 },
	  ];

    return (
      <div>
        {!id && activeStep === STEP_REPRESENTATIVE && (
          <React.Fragment>
            {selectedRepresentative && (
              <div style={{ display: "flex", marginBottom: "10px" }}>
                  <strong>Representative: {selectedRepresentative.representative.name}</strong> 
                  <Button.Clear
                    person
                    onClick={onRepresentativeClear}
                  />
              </div>
                )}
            <div>
              <RepresentativeSelect onChange={(e) => setSelectedRepresentative(e)} />
            </div>
            <div>
              <Button.Basic
                primary
                text="Next Step"
                disabled={!selectedRepresentative}
                onClick={onRepresentativeSelect}
                style={{ margin: "10px 10px 10px 0" }}
              />
            </div>
          </React.Fragment>
        )}
        {activeStep === STEP_ENTRY && (
          <React.Fragment>
            <div>
              {selectedRepresentative && (
                <div style={{ display: "flex", marginBottom: "10px" }}>
                    <strong>Representative: {selectedRepresentative.representative.name}</strong> 
                    <Button.Clear
                      person
                      onClick={onRepresentativeClear}
                    />
                </div>
                  )}
                {!id && (
                  <Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_REPRESENTATIVE)}
                  style={{ margin: "10px 10px 10px 0" }}
                  />
                )}
              <BasicForm
                error={error}
                fields={fields}
                loading={isLoading}
                onSubmit={onSubmit}
              />
							<div style={{ margin: "30px 10px 10px 0" }}>
								<h2>Draw Collections</h2>
							  <BasicTable
							    fetch={false}
							    data={drawCollections}
							    loading={isLoading}
							    columns={drawCollectionsColumns}
							    entityName="Draw Collections"
							  />
					    </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

function mapStateToProps(state) {
  return {
    representativeId: state.representative ? state.representative.id : null,
  };
}

export default connect(mapStateToProps)(RepresentativeDrawForm);

RepresentativeDrawForm.defaultProps = {
  onSuccess: () => alert("On Success"),
  id: null,
  paid: null,
  date: null,
  id: null,
  postedToAch: null,
	drawCollections: [],
  achReference: "",
};