import React, { useState, useEffect } from "react";
import axios from "axios";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { JobKnockingForm } from "@components/JobKnocking";

export default function JobKnockingsDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jobKnockingLink, setJobKnockingLink] = useState(populateJobKnockingLink());

  const jobKnockingLinkId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/job-knocking-links/${jobKnockingLinkId}/`)
      .then(({ data }) => {
        setLoading(false);
        setJobKnockingLink(populateJobKnockingLink(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!jobKnockingLink) return null;

  return (
    <div>
      <PageTitle
        title={`Commission settings for ${jobKnockingLink.representative.full_name}`}
        subtitle={`Commission on Job: ${jobKnockingLink.job.name}`}
      />
      <Wrapped>
        <JobKnockingForm
          id={jobKnockingLinkId}
          jobId={jobKnockingLink.job.id}
					jobType={jobKnockingLink.job.job_type}
					jobTypeName={jobKnockingLink.job.job_type_formatted}
          percentage={jobKnockingLink.percentage}
          splitPercentage={jobKnockingLink.splitPercentage}
          paid={jobKnockingLink.paid}
          notes={jobKnockingLink.notes}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateJobKnockingLink(d = {}) {
  return {
    representative: d.representative || "",
    job: d.job || "",
    percentage: d.percentage,
    splitPercentage: d.split_percentage || "",
    paid: d.paid,
    notes: d.notes || "",
  };
}
