import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Button, Form, Icon } from "semantic-ui-react";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { BasicTable } from "@components/shared";

export default function DrawCollections({
  actionsDisabled,
  editing,
  representativeId,
  selected,
  onChange,
  fetchData,
	selectedUnsettledDrawCollections,
	setSelectedUnsettledDrawCollections,
	unsettledDrawCollections, 
	setUnsettledDrawCollections,
}) {
  const [loading, setLoading] = useState(false);

	useEffect(() => {
	  fetchInvoices();
	}, []);

  function fetchInvoices() {
    const req = {
      representative_id: representativeId,
      status: 0,
			ach_payment_id: false,
    };

    setLoading(true);

    axios
      .post(`/retrieve-draw-collections/`, req)
      .then(({ data }) => {
				setUnsettledDrawCollections(data.data);
      })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }

  function onAddLinkToPaymentClick(drawToAdd) {
		setSelectedUnsettledDrawCollections(prevLinks => [...prevLinks, drawToAdd]);
  }
	
	function onRemoveLinkToPaymentClick(drawToRemove) {
	  setSelectedUnsettledDrawCollections(prevLinks =>
	    prevLinks.filter(draw => draw !== drawToRemove)
	  );
	}
	
	const updateMyData = (rowIndex, columnId, value) => {
	  setUnsettledDrawCollections(unsettledDrawCollections => unsettledDrawCollections.map((row, index) => {
	    if (index === rowIndex) {
	      return {
	        ...row,
	        [columnId]: value
	      };
	    }
	    return row;
	  }));
	};
	
  let columns = useMemo(() => [
    {
      Header: "",
      width: 35,
      id: "inv",
      accessor: (d) => {
				const selected = selectedUnsettledDrawCollections.includes(d.id);
 
        if (selected) {
            return (
              <Button
                icon="check"
                color="green"
                style={{ transform: "scale(0.7)" }}
                circular
                loading={loading}
                size="mini"
                title="Remove"
                onClick={() => onRemoveLinkToPaymentClick(d.id)}
              />
            );
        }

        if (!selected) {
          return (
            <Button
              icon="plus"
              style={{ transform: "scale(0.7)" }}
              circular
              basic
              loading={loading}
              size="mini"
              title="Add"
              onClick={() => onAddLinkToPaymentClick(d.id)}
            />
          );
        }

        return (
          <Form.Checkbox
            checked={selected.includes(d.id)}
            onChange={() => onChange(d.id, d)}
          />
        );
      },
    },
		{ Header: "Date Applied", accessor: "date_applied_formatted", width: 120 },
		{
		  Header: "Amount",
		  accessor: "amount_formatted",
		  width: 120,
		  Cell: ({ value }) => {
		    let numericValue = parseFloat(value.replace(/,/g, ''));
		    numericValue = Math.abs(numericValue) * -1;
		    const formattedValue = numericValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		    return <span>{formattedValue}</span>;
		  }
		},
		{
		  Header: "Amount To Collect",
		  accessor: "amount_to_collect",
		  width: 300,
		  Cell: ({ value, row, column }) => {
		    const initialValue = parseFloat(value.replace(/,/g, '')).toFixed(2);
		    const [inputValue, setInputValue] = useState(initialValue);

		    useEffect(() => {
		      setInputValue(parseFloat(value.replace(/,/g, '')).toFixed(2));
		    }, [value]); // Listen to external value changes

		    const handleInputChange = (e) => {
		      setInputValue(e.target.value.replace(/[^0-9.-]/g, ''));
		    };

		    const handleBlur = () => {
		      const numericValue = parseFloat(inputValue) || 0;
		      const formattedValue = numericValue.toFixed(2);
		      if (row._index !== undefined) {
		        updateMyData(row._index, column.id, formattedValue); // Make sure column.id is 'amount_to_collect'
		      } else {
		        console.error('Row index is undefined');
		      }
		    };

		    return (
		      <input
		        value={inputValue}
		        onChange={handleInputChange}
		        onBlur={handleBlur}
		        style={{ width: '100%' }}
		      />
		    );
		  }
		},
  ], [updateMyData]);

  return (
    <div style={{ marginTop: "15px" }}>
      <BasicTable
        fetch={false}
        data={unsettledDrawCollections}
        loading={loading}
        columns={columns}
        entityName="Draw Collections"
      />
    </div>
  );
}

DrawCollections.defaultProps = {
  actionsDisabled: false,
  editing: false,
  selected: [],
};
