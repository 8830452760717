import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Provider } from "react-redux";

import { store } from "./duck";
import PayrollApp from "./PayrollApp";

import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./style/main.scss";
import axiosDefaults from '@util/axiosDefaults';

class ReactApp extends React.Component {
  state = {};

  render() {
    return (
      <Provider store={store}>
        <PayrollApp style={{ height: "100%" }} />
      </Provider>
    );
  }
}

ReactDOM.render(
  <ReactApp style={{ height: "100%" }} />,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept();
}
