import React from "react";
import Loader from "react-loader-spinner";

const loaderConfig = {
  type: "ThreeDots",
  height: 15,
  width: 15,
  color: "#000",
  style: {
    marginLeft: "5px",
  },
};

export default function InlineLoader() {
  return <Loader {...loaderConfig} />;
}
