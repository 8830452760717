import React from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";

const loaderConfig = {
  type: "ThreeDots",
  height: 10,
  width: 20,
  color: "#fff",
};

const Button = styled.button`
  background: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  padding: 0;
  border: none;
  opacity: ${(props) => props.opacity};

  & + & {
    margin-left: 12px;
  }
`;

export default function IconButton({
  icon,
  opacity,
  onClick,
  title,
  disabled,
  loading,
}) {
  return (
    <Button
      onClick={onClick}
      title={title}
      opacity={opacity}
      disabled={disabled}
    >
      {loading ? (
        <Loader {...loaderConfig} />
      ) : (
        <React.Fragment>{icon}</React.Fragment>
      )}
    </Button>
  );
}

IconButton.defaultProps = {
  disabled: false,
  opacity: 1,
  title: "",
  icon: "phone",
  loading: false,
};
