import React from "react";
import styled from "styled-components";

export default function OptionDescriptionList({ items }) {
  if (!items.length) return null;

  return (
    <StyledList>
      {items.map((item) => (
        <li key={item.key}>{item.text}</li>
      ))}
    </StyledList>
  );
}

OptionDescriptionList.defaultProps = {
  items: [],
};

const StyledList = styled.ul`
  margin: 5px 0 0 0;
  padding: 0 0 0 20px;

  li + li {
    margin-top: 5px;
  }
`;
