import React from "react";
import { FaPlusCircle, FaPencilAlt } from "react-icons/fa";
import styled from "styled-components";

const Button = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  display: flex;
  padding: 0;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "cursor")};

  svg {
    font-size: 24px;
    fill: ${(props) =>
      props.open
        ? "var(--negativeColor)"
        : `var(--${
            props.secondary ? "secondaryColor" : "buttonBackgroundColor"
          })`};
    transform: ${(props) => (props.open ? "rotate(45deg)" : "none")};
  }
`;

export default function ToggleButton({
  edit,
  open,
  disabled,
  onClick,
  secondary,
}) {
  return (
    <Button
      onClick={onClick}
      open={open}
      secondary={secondary}
      disabled={disabled}
    >
      {edit ? <FaPencilAlt /> : <FaPlusCircle />}
    </Button>
  );
}

ToggleButton.defaultProps = {
  disabled: false,
  edit: false,
  open: false,
  secondary: false,
};
