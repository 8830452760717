import React, { useState } from "react";
import { connect } from "react-redux";
import { ContractorManagement } from "@components/Contractor";

function ContractorsIndex() {
  const [isOpen, setOpen] = useState(false);

  return <ContractorManagement />;
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ContractorsIndex);
