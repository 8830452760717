import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Form, Icon } from "semantic-ui-react";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { BasicTable } from "@components/shared";

export default function Invoices({
  actionsDisabled,
  classificationId,
  editing,
  contractorId,
  hotSeatStatus,
  meetings,
  invites,
  teamId,
  selected,
  showWinnerSelect,
  onChange,
  fetchData,
  onMarkWinnerClick,
}) {
  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPeople();
  }, [classificationId, JSON.stringify(invites)]);

  function fetchPeople() {
    if ([3, 4, 5, 6].includes(hotSeatStatus)) {
      return setPeople(
        invites.map((m) => ({
          ...m,
          requested_at_formatted: m.waitlist_request
            ? m.waitlist_request.requested_at_formatted
            : null,
          hot_seat_invitation_count: m.waitlist_request
            ? m.waitlist_request.hot_seat_invitation_count
            : null,
          hot_seat_application: m.professional.hot_seat_applications.find(
            (f) => f.team_id === teamId
          ),
        }))
      );
    }
    const req = {
      contractor_id: contractorId,
      paid: false,
    };

    setLoading(true);

    axios
      .post(`/contractor-invoices/`, req)
      .then(({ data }) => {
      })
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }

  function onInviteRemoveClick(inviteId) {
    const req = {
      hot_seat_invitation_id: inviteId,
    };

    setLoading(true);

    axios
      .post(`/hot-seats/${inviteId}/delete/`, req)
      .then(() => fetchData())
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        fetchData();
      });
  }

  function onInviteAddClick(waitlistRequestId) {
    const req = {
      hot_seat_id: hotSeatId,
      waitlist_request_id: waitlistRequestId,
    };

    axios.post(`/hot-seat-invitation/`, req).then(() => fetchData());
  }

  let columns = [
    {
      Header: "",
      width: 35,
      show: hotSeatStatus === 2,
      id: "inv",
      accessor: (d) => {
        const inv = invites.find((f) => f.waitlist_request.id === d.id);

        if (inv) {
          switch (inv.waitlist_request.status) {
            case 0:
              // return null;
              return (
                <Button
                  icon="check"
                  color="green"
                  style={{ transform: "scale(0.7)" }}
                  circular
                  loading={loading}
                  size="mini"
                  title="Remove"
                  onClick={() => onInviteRemoveClick(inv.id)}
                />
              );
            case 1:
              return <FaThumbsUp title="Accepted" />;
            case 2:
              return <FaThumbsDown title="Declined" />;
            default:
              return "n/a";
          }
        }

        if (!inv && editing) {
          return (
            <Button
              icon="plus"
              style={{ transform: "scale(0.7)" }}
              circular
              basic
              loading={loading}
              size="mini"
              title="Add"
              onClick={() => onInviteAddClick(d.id)}
            />
          );
        }

        return (
          <Form.Checkbox
            checked={selected.includes(d.id)}
            onChange={() => onChange(d.id, d)}
          />
        );
      },
    },
    {
      Header: "Select",
      id: "winner",
      width: 55,
      accessor: (d) =>
        d.hot_seat_application && d.hot_seat_application.team_id === teamId ? (
          true ? (
            <Button
              circular
              icon="star"
              size="mini"
              title="Mark winner"
              onClick={() =>
                onMarkWinnerClick(d.professional.id, d.hot_seat_application.id)
              }
            />
          ) : (
            ""
          )
        ) : (
          ""
        ),
      show: showWinnerSelect,
    },
    { Header: "Invoice Date", accessor: "invoice_date_formatted" },
    { Header: "Invoice Number", accessor: "invoice_number" },
    { Header: "Amount", accessor: "amount_formatted", width: 140 },
    { Header: "Paid", accessor: (d) =>
        d.paid ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />, width: 140 },
    { Header: "Notes", accessor: "notes" },
  ];

  return (
    <div style={{ marginTop: "15px" }}>
      <BasicTable
        fetch={false}
        data={people}
        loading={loading}
        columns={columns}
        entityName="Invoices"
      />
    </div>
  );
}

Invoices.defaultProps = {
  actionsDisabled: false,
  classificationId: null,
  editing: false,
  hotSeatId: null,
  invites: [],
  meetings: [],
  selected: [],
  showWinnerSelect: false,
  teamId: null,
};
