import ColorScheme from "color-scheme";

export function getColors(hue) {
  const scheme = new ColorScheme();
  scheme.from_hue(hue).scheme("tetrade").variation("pastel");
  return scheme.colors().map((m) => `#${m}`);
}

export function getRgbaColors(hue, alpha = 0.55) {
  const colors = getColors(hue);

  return colors.map((m) => {
    const rgb = hexToRgb(m);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
  });
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
