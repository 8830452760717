import React from "react";
import { AiFillAlert } from "react-icons/ai";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 40px;
  height: 100vh;

  svg {
    font-size: 80px;
    color: var(--negativeColor);
  }

  h1 {
    margin: 10px 0;
  }
`;

export default function ViewError({ text }) {
  return (
    <Wrapper>
      <AiFillAlert />
      <h1>Uh Oh!</h1>
      <p>{text}</p>
    </Wrapper>
  );
}

ViewError.defaultProps = {
  text: "Try again later",
};
