import React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  border: 1px solid #eee;
  padding: 10px;
  background: rgba(#000, 0.025);

  svg {
    color: ${(props) => (props.negative ? "var(--negativeColor)" : "inherit")};
  }

  .icon {
    margin-right: 12px;
  }
`;

const iconStyle = {
  size: 20,
};

export default function Message({ negative, text }) {
  return (
    <Wrapper negative={negative}>
      <div className="icon">
        {negative && <FaExclamationCircle {...iconStyle} />}
      </div>
      <p>{text}</p>
    </Wrapper>
  );
}

Message.defaultProps = {
  negative: false,
  text: "Message",
};
