import React, { useState } from "react";
import { RepresentativeReimbursementForm, Table } from "@components/RepresentativeReimbursement";
import { EditModal } from "@components/RepresentativeInvoiceSelection";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

export default function RepresentativeReimbursementsIndex() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Representative Reimbursements"
        button={
          <EditModal onSuccess={(id) => window.location.href = `/representative-reimbursements/${id}`} />
        }
      >
        {isOpen ? <RepresentativeReimbursementForm onSuccess={() => setOpen(false)} /> : <Table representativeView={false} />}
      </Wrapped>
      
    </div>
  );
}
