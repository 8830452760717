import React, { useState } from "react";
import { Table } from "@components/UnreimbursedRepresentative";
import { EditModal } from "@components/RepresentativeInvoiceSelection";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

export default function UnreimbursedRepresentativesIndex() {

  return (
    <div>
      <Wrapped
        title="Unreimbursed Representatives"
      >
        <Table representativeView={false} />
      </Wrapped>
      
    </div>
  );
}
