import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";
import { getRoleInfo } from "@util/roleInfo";

import { Table } from "@components/ManagedRepresentatives";

export default function ManagedRepresentativesIndex({ history }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
	const [manager, setManager] = useState(populateManager());
  const roleInfo = getRoleInfo(useSelector((state) => state));
	
  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/status/`)
      .then(({ data }) => {
        setLoading(false);
        setManager(populateManager(data.representative));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  return (
    <div>
      <Wrapped
        title="Managed Representatives"
      >
        <Table managedRepresentatives={manager.managedRepresentatives} managerView={true} />
      </Wrapped>
    </div>
  );
}

function populateManager(d = {}) {
  return {
		managedRepresentatives: d.managed_representatives,
  };
}
