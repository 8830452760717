import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Icon } from "semantic-ui-react";

import { Button, FilterSelect, BasicTable } from "@components/shared";

export default function Table( { jobId } ) {
  const [loading, setLoading] = useState(false);
  const [jobKnockingLinks, setJobKnockingLinks] = useState(null);

  useEffect(() => {
    fetchJobKnockingLinks();
  }, []);

  function fetchJobKnockingLinks() {
    const req = {
      filtered: [],
      page: 0,
      page_size: 500,
      sorted: [],
      job_id: +jobId,
      status: [true, false],
    };

    axios
      .post(`/retrieve-job-knocking-links/`, req)
      .then(({ data }) => {
        setJobKnockingLinks(data.data);
      })
      .catch((err) => {
        setLoading(false);
        setJobKnockingLinks([]);
      });
  }

  const columns = [
    {
      Header: "",
      id: "view",
      width: 50,
      accessor: (d) => <Button.View url={`/job-knocking/${d.id}`} />,
    },
    {
      Header: "Name",
      accessor: "representative.full_name",
      id: "representative_full_name",
      sortable: false,
    },
    {
      Header: "Commission %",
      accessor: "percentage",
      id: "percentage",
      sortable: false,
    },
    {
      Header: "Split %",
      accessor: "split_percentage",
      id: "split_percentage",
      sortable: false,
    },
    {
      Header: "Notes",
      accessor: "notes",
      id: "notes",
      sortable: false,
    },
  ];

  return (
    <React.Fragment>
      <BasicTable
        columns={columns}
        entityName="Representatives for Knocking"
        data={jobKnockingLinks}
      />
    </React.Fragment>
  );
}

const filterOptions = [
  { name: "first_name", obj: "self" },
  { name: "last_name", obj: "self" },
];
