import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

import { BasicGrid } from "@components/shared";
import cssvar from "@util/cssvar";

const pieOptions = {
  responsive: true,
  cutoutPercentage: 30,
  type: "doughnut",
  // maintainAspectRatio: false,
  aspectRatio: 2,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        font: {
          family: "Open Sans",
        },
      },
    },
  },
};

const pieData = {
  labels: ["Red", "Blue", "Yellow"],
  datasets: [
    {
      label: "My First Datasets",
      data: [300, 50, 100],
      backgroundColor: [
        "rgb(255, 99, 132)",
        "rgb(54, 162, 235)",
        "rgb(255, 205, 86)",
      ],
      hoverOffset: 4,
    },
  ],
};

const options = {
  responsive: true,
  scales: {
    x: {
      ticks: {
        font: {
          family: "Open Sans",
        },
      },
    },
    y: {
      ticks: {
        font: {
          family: "Open Sans",
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        font: {
          family: "Open Sans",
        },
      },
    },
    title: {
      display: false,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const data = {
  labels,
  datasets: [
    {
      label: "With Cheese",
      data: [50, 90, 100],
      backgroundColor: cssvar("--primaryColor"),
    },
    {
      label: "With Hot Sauce",
      data: [20, 10, 98],
      backgroundColor: cssvar("--secondaryColor"),
    },
  ],
};

export default function Dashboard() {
  return <p>coming soon...</p>;

  return (
    <BasicGrid columns={2}>
      <div>
        <h4>Burritos</h4>
        <Bar options={options} data={data} />
      </div>
      <div>
        <h4>Tacos</h4>
        <Bar options={options} data={data} />
      </div>
      <div>
        <h4>Quesadillas</h4>
        <Bar options={options} data={data} />
      </div>
      <div style={{ height: "300px" }}>
        <h4>Pie</h4>
        <Pie options={pieOptions} data={pieData} />
      </div>
    </BasicGrid>
  );
}
