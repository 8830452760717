import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "semantic-ui-react";

export default function RepresentativeSelect({ onChange }) {
  const [representatives, setRepresentatives] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    const req = {
      status: 0,
    };

    axios.post(`/lookup-representatives/`, req).then(({ data }) => {
      setRepresentatives(
        data.data.map((d) => ({
          key: d.id,
          text: d.full_name,
          value: d.id,
          globalCommissionRatio: d.global_commission_ratio,
					overridePercentage: d.override_percentage,
					paidLeadPercentage: d.paid_lead_percentage,
					weeklySalaryInPennies: d.weekly_salary_in_pennies,
        }))
      );
      setLoading(false);
    });
  }

  const handleChange = (e, { value }) => {
    const selectedRepresentative = representatives.find(c => c.value === value);
    if (selectedRepresentative) {
      onChange({
        representative: { name: selectedRepresentative.text, 
													value: selectedRepresentative.value, 
													globalCommissionRatio: selectedRepresentative.globalCommissionRatio, 
													representativeOverridePercentage: selectedRepresentative.overridePercentage,
					 								representativePaidLeadPercentage: selectedRepresentative.paidLeadPercentage,
													representativeWeeklySalaryInPennies: selectedRepresentative.weeklySalaryInPennies
												}
      });
    }
  };

  return (
    <div>
      <Form>
        <Form.Select
          placeholder="Select a representative..."
          loading={loading}
          options={representatives}
          search
          onChange={handleChange}
        />
      </Form>
    </div>
  );
}

RepresentativeSelect.defaultProps = {
  selected: [],
};