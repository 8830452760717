import React from "react";
import { FaEye } from "react-icons/fa";

export default function EyeIcon({ size }) {
  return <FaEye size={size} />;
}

EyeIcon.defaultProps = {
  size: 18,
};
