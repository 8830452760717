import React, { useState, useEffect } from "react";
import axios from "axios";
import { Icon } from "semantic-ui-react";
import { Card } from "@components/shared";

export default function RepresentativeBreakdown({
  representativeId,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [representativeBreakdown, setRepresentativeBreakdown] = useState(populateRepresentative());
	
  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/representative-breakdown/${representativeId}/`)
      .then(({ data }) => {
        setLoading(false);
        setRepresentativeBreakdown(populateRepresentative(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
  
  return (
    <React.Fragment>
			<Card.Wrapper>
			  <Card.Meta
			    title={`${representativeBreakdown.fullName}`}
			    stats={[
			      { title: "Phone", value: `${representativeBreakdown.phone}` },
						{ title: "Email", value: `${representativeBreakdown.email}` },
						{ title: "Your Manager Points %", value: `${representativeBreakdown.managerPoints}%` },
						{ title: "Override %", value: `${representativeBreakdown.overridePercentage}%` },
			      { title: "Paid Lead %", value: `${representativeBreakdown.paidLeadPercentage}%` },
			      { title: "Base Comission Ratio", value: `${representativeBreakdown.globalCommissionRatioFormatted}` },
						{ title: "Weekly Salary", value: `$${representativeBreakdown.weeklySalaryFormatted}` },
			    ]}
			    hasActions={false}
			  />
			</Card.Wrapper>
    </React.Fragment>
  );
}

function populateRepresentative(d = {}) {
  return {
		managerLink: d.manager_representative || "",
		managedRepresentatives: d.managed_representatives,
		prefix: d.prefix || "",
    firstName: d.first_name || "",
    lastName: d.last_name || "",
    suffix: d.suffix || "",
    fullName: d.full_name || "",
		companyName: d.company_name || "",
    email: d.email || "",
    phone: d.phone || "",
    addressOne: d.address_1 || "",
    addressTwo: d.address_2 || "",
    city: d.city || "",
    state: d.state || "",
    overridePercentage: d.override_percentage,
    paidLeadPercentage: d.paid_lead_percentage,
    globalCommissionRatioFormatted: d.global_commission_ratio_formatted,
    manager: d.manager,
		managerPoints: d.manager_points,
		managerRepresentative: d.manager_representative,
    managerPointsPercentage: d.manager_points_percentage,
    salary: d.salary,
    weeklySalaryFormatted: d.weekly_salary_formatted || "0",
    zipcode: d.zipcode || "",
    status: d.status,
    statusFormatted: d.status_formatted,
    notes: d.notes || "",
  };
}