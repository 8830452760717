import React from "react";

import { Card } from "@components/shared";

export default function PatientDisplay({
  addedBy,
  birthday,
  ethnicityFormatted,
  firstName,
  genderFormatted,
  lastName,
  patientIdent,
}) {
  return (
    <Card.Wrapper>
      <Card.Meta
        title={`${firstName} ${lastName}`}
        stats={[
          { title: "Patient ID", value: patientIdent },
          { title: "birthdate", value: birthday },
          { title: "ethnicity", value: ethnicityFormatted },
          { title: "gender", value: genderFormatted },
        ]}
        hasActions={false}
      />
    </Card.Wrapper>
  );
}

PatientDisplay.defaultProps = {
  addedBy: null,
  birthday: "",
  ethnicityFormatted: "",
  firstName: "",
  genderFormatted: "",
  lastName: "",
  patientIdent: "",
};
