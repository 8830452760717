import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { BasicGrid, Card, Button, EmptyList } from "@components/shared";
import { Table } from "@components/RepresentativeJob";

import { getRoleInfo } from "@util/roleInfo";

export default function RepresentativeJobManagement({
  representativeId,
  onUpdate,
}) {
  const [jobPaymentLinks, setJobPaymentLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <div>
      <Table representativeId={representativeId} />
    </div>
  );
}

RepresentativeJobManagement.defaultProps = {
  jobPaymentLinks: [],
};
