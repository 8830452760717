import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getUuid } from "@util/getUuid";

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
`;

const LinkWrapper = styled(Link)`
  /* color: ${(props) => (props.active ? "white" : "var(--primaryColor)")}; */
  font-weight: bold;
  /* border: 3px solid var(--primaryColor); */
  /* background-color: ${(props) =>
    props.active ? "var(--primaryColorLight)" : "none"}; */
  /* border-radius: 4px; */
  margin-right: 10px;
  /* padding: 3px 10px; */
  color: inherit;
  border-bottom: ${(props) =>
    props.active
      ? "4px solid var(--primaryColorLight)"
      : "4px solid transparent"};
  /* padding-left: 10px; */
  padding-bottom: 5px;

  & + & {
    margin-left: 1em;
  }

  &:hover {
    /* opacity: ${(props) => (props.active ? "1" : ".5")};
    background-color: ${(props) =>
      props.active ? "" : "var(--primaryColorLight)"};
    color: white; */
  }

  // &:first-of-type {
  //   padding-left: 0;
  // }
`;

export default function Subnav({ links }) {
  if (!links.length) return null;

  return (
    <Wrapper>
      {links.map((l) => (
        <LinkWrapper
          key={getUuid()}
          to={l.to}
          active={l.to === window.location.pathname}
        >
          {l.text}
        </LinkWrapper>
      ))}
    </Wrapper>
  );
}

Subnav.defaultProps = {
  links: [],
};
