import React from "react";
import styled from "styled-components";

import { getUuid } from "@util/getUuid";

export default function StatisticalOverview({ data }) {
  if (!data) return null;

  const blData = data.bl;
  const visitKeys = Object.keys(data).filter((f) => f !== "bl");

  const rows = [
    {
      text: "Change in IOP",
      values: visitKeys.map((m) => data[m].change_in_iop),
    },
    {
      text: "Change in Meds",
      values: visitKeys.map((m) => data[m].change_in_meds),
    },
    {
      text: "IOP 18mm or below",
      values: [
        blData.iop_at_or_below_18,
        ...visitKeys.map((m) => data[m].iop_18mm_or_below),
      ],
    },
    {
      text: "IOP 18mm or below %",
      values: [
        `${blData.iop_at_or_below_18_percent}%`,
        ...visitKeys.map((m) => `${data[m].iop_18mm_or_below_percent}%`),
      ],
    },
    {
      text: "IOP 21mm or below",
      values: [
        blData.iop_at_or_below_21,
        ...visitKeys.map((m) => data[m].iop_21mm_or_below),
      ],
    },
    {
      text: "IOP 21mm or below %",
      values: [
        `${blData.iop_at_or_below_21_percent}%`,
        ...visitKeys.map((m) => `${data[m].iop_21mm_or_below_percent}%`),
      ],
    },
    {
      text: "Mean IOP",
      values: [blData.mean_iop, ...visitKeys.map((m) => data[m].mean_iop)],
    },
    {
      text: "Mean Meds",
      values: [blData.mean_meds, ...visitKeys.map((m) => data[m].mean_meds)],
    },
    {
      text: "Meds Reduced",
      values: visitKeys.map((m) => data[m].meds_reduced),
    },
    {
      text: "Meds Reduced %",
      values: visitKeys.map((m) => `${data[m].meds_reduced_percent}%`),
    },
    {
      text: "Meds Reduced and 18",
      values: visitKeys.map((m) => data[m].meds_reduced_and_18),
    },
    {
      text: "Meds Reduced and 18 %",
      values: visitKeys.map((m) => `${data[m].meds_reduced_and_18_percent}%`),
    },
    {
      text: "Meds Reduced and 21",
      values: visitKeys.map((m) => data[m].meds_reduced_and_21),
    },
    {
      text: "Meds Reduced and 21 %",
      values: visitKeys.map((m) => `${data[m].meds_reduced_and_21_percent}%`),
    },
    {
      text: "Standard Deviation of IOP",
      values: [
        blData.iop_sd,
        ...visitKeys.map((m) => data[m].standard_deviation_of_iop),
      ],
    },
    {
      text: "Standard Deviation of Meds",
      values: [
        blData.meds_sd,
        ...visitKeys.map((m) => data[m].standard_deviation_of_meds),
      ],
    },
    {
      text: "Zero Meds",
      values: [blData.zero_meds, ...visitKeys.map((m) => data[m].zero_meds)],
    },
    {
      text: "Zero Meds and 18",
      values: [
        blData.zero_meds_percent,
        ...visitKeys.map((m) => data[m].zero_meds_and_18),
      ],
    },
    {
      text: "Zero Meds and 18 %",
      values: visitKeys.map((m) => `${data[m].zero_meds_and_18_percent}%`),
    },
    {
      text: "Zero Meds and 21",
      values: visitKeys.map((m) => data[m].zero_meds_and_21),
    },
    {
      text: "Zero Meds and 21 %",
      values: visitKeys.map((m) => `${data[m].zero_meds_and_21_percent}%`),
    },
    {
      text: "Zero Meds %",
      values: visitKeys.map((m) => `${data[m].zero_meds_percent}%`),
    },
  ].map((m) => ({
    ...m,
    key: getUuid(),
    values:
      m.values.length !== visitKeys.length + 1 ? ["-", ...m.values] : m.values,
  }));

  return (
    <StyledTable>
      <thead>
        <th></th>
        <th>Pre-Op</th>
        {visitKeys.map((m) => (
          <th key={getUuid()}>{m}</th>
        ))}
      </thead>
      <tbody>
        {rows.map((r) => (
          <tr key={r.key}>
            <td>{r.text}</td>
            {r.values.map((v) => (
              <td key={getUuid()}>{v}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

const StyledTable = styled.table`
  border: 1px solid grey;
  border-collapse: collapse;
  width: 100%;

  th {
    color: #777;
    background: rgba(0, 0, 0, 0.1);
  }

  th,
  td {
    padding: 0.5em;
    border: 1px solid lightgrey;
  }
`;
