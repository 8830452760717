import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Wrapped } from "@components/Layouts";

import { BasicGrid, Card, Button, EmptyList } from "@components/shared";
import { JobKnockingForm, Table } from "@components/JobKnocking";

import { getRoleInfo } from "@util/roleInfo";

export default function JobKnockingManagement({
  jobId,
  jobName,
	jobType,
	jobTypeName,
	jobPaymentLinks,
  onUpdate,
}) {
  const [loading, setLoading] = useState(false);
	const [isOpen, setOpen] = useState(false);
  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <Wrapped
      title="Job Knockings"
      button={
        !roleInfo.admin_view_only && (
          <Button.Toggle
            closedText="Job Knockings"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        )
      }
    >
      {isOpen ? (
        <JobKnockingForm
          jobId={jobId}
          jobName={jobName}
					jobType={jobType}
					jobTypeName={jobTypeName}
          onSuccess={() => setOpen(false)}
        />
      ) : (
        <Table jobId={jobId} />
      )}
    </Wrapped>
  );
}

JobKnockingManagement.defaultProps = {
  invoices: [],
};
