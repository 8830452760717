import React from "react";
import { FaBuilding } from "react-icons/fa";

export default function PracticeIcon({ size }) {
  return <FaBuilding size={size} />;
}

PracticeIcon.defaultProps = {
  size: 18,
};
