import React, { Component, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import { Button, Message, ReqResponseMessage } from "@components/shared";

export default class Login extends Component {
  state = {
    email: "",
    error: null,
    loading: false,
    password: "",
    remember: false,
  };

  email = React.createRef();
  pw = React.createRef();

  componentDidMount = () => {
    const email = localStorage.getItem("email") || "";

    if (email) {
      this.setState({ email, remember: true });
      setTimeout(() => {
        this.pw.current.focus();
      }, 100);
    } else {
      this.setState({ email: "", remember: false });
      setTimeout(() => {
        this.email.current.focus();
      }, 100);
    }
  };

  handleChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, error: null });

  onRememberCheck = (e) => {
    if (e.target.checked) {
      localStorage.setItem("email", this.state.email);
      this.setState({ remember: true });
    } else {
      localStorage.removeItem("email");
      this.setState({ remember: false });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    const postData =
      this.props.entity === "Representative"
        ? {
            email: this.state.email,
            password: this.state.password,
          }
        : {
            email: this.state.email,
            admin: true,
            password: this.state.password,
          };

    this.setState({ loading: true });

    let postUrl = `/admin-login/`;

    if (this.props.entity === "Representative") {
      postUrl = `/representative-login/`;
    }

  axios
    .post(postUrl, postData)
    .then(({ data }) => {
      // this.props.dispatch({ type: SET_ADMIN, payload: data })
      if (this.props.entity === "Representative") {
        return (window.location = `/jobs`);
      }

      return (window.location = `/ach`);
    })
    .catch((err) => {
      this.setState({
        loading: false,
        reqResponse: {
          content: err,
          header: "There was a problem",
          success: false,
        },
      });
    });
  };
    
  render() {
    const { entity } = this.props;
    const { error } = this.state;

    if (localStorage.getItem("representative")) {
      title = `Welcome back, ${JSON.parse(localStorage.getItem("representative")).name}!`;
    }

    return (
      <div>
        <h1>{this.props.title}</h1>
        {this.state.reqResponse ? (
          <ReqResponseMessage
            content={this.state.reqResponse.content}
            header={this.state.reqResponse.header}
            success={this.state.reqResponse.success}
          />
        ) : null}
        <form className="auth-form">
          <input
            ref={this.email}
            placeholder="Email"
            type="text"
            name="email"
            id="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <input
            ref={this.pw}
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleChange}
          />
          {error && (
            <Fade bottom>
              <Message negative text={error} />
            </Fade>
          )}
          <Button.Basic
            disabled={
              !this.state.email || !this.state.password || this.state.loading
                ? true
                : false
            }
            onClick={this.onSubmit}
            primary
            id="submit"
            type="submit"
            loading={this.state.loading}
            text="Login"
          />
        </form>
        <div className="auth-other">
          <Link
            style={{ marginBottom: "1em" }}
            to={
              entity === "Representative" ? `/forgot-password` : `/admin-forgot-password`
            }
          >
            Forgot Password?
          </Link>
          <div className="form-group">
            <input
              type="checkbox"
              onChange={this.onRememberCheck}
              checked={this.state.remember}
            />
            <label>Remember Me</label>
          </div>
        </div>
      </div>
    );
  }
}

Login.defaultProps = {
  title: "Login",
};
