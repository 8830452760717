import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { Button, Text } from "@components/shared";

import { NhsLookup, PatientDisplay, PatientForm, PatientStager } from "./";

export default function PatientCreate({ practiceId, onSuccess }) {
  const [patientIdent, setPatientIdent] = useState("");
  const [create, setCreate] = useState(false);
  const [patient, setPatient] = useState(null);
  const [patientConfirm, setPatientConfirm] = useState(false);
  const [error, setError] = useState(null);

  const userInfo = useSelector((state) => state.user);

  const onConfirmClick = () => {
    setError(null);

    const req = {
      patient_id: patient.id,
    };

    if (practiceId) {
      req.practice_id = practiceId;
    }

    axios
      .post(`/patient-add-to-practice/`, req)
      .then(() => {
        // setPatientConfirm(true);
        onSuccess(patient.id);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const BackButton = () => (
    <Button.Basic
      onClick={() => {
        setNhsNumber("");
        setError(null);
        setCreate(false);
        setPatient(null);
        setPatientConfirm(false);
      }}
      style={{ marginTop: "1em" }}
      text="Back to Patient ID"
    />
  );

  const PatientInfo = () =>
    patient ? (
      <PatientDisplay
        addedBy={patient.added_by}
        birthday={patient.birth_year}
        ethnicityFormatted={patient.ethnicity_formatted}
        firstName={patient.first_name}
        genderFormatted={patient.gender_formatted}
        lastName={patient.last_name}
        patientIdent={patient.patient_ident}
      />
    ) : null;

  return (
    <div>
      {!create && !patient && (
        <NhsLookup
          onLookupSuccess={(foundPatient) => setPatient(foundPatient)}
          onLookupFail={(patientIdent) => {
            setPatientIdent(patientIdent);
            setCreate(true);
          }}
        />
      )}
      {patient && (
        <React.Fragment>
          {!patientConfirm && (
            <div>
              <PatientInfo />
              <p style={{ marginTop: "1em" }}>
                <b>Is this the person you are looking for?</b>
              </p>
              {error && <Text.Error text={error} />}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1em",
                }}
              >
                <Button.Basic
                  text="No"
                  onClick={() => {
                    setPatient(null);
                    setError(null);
                  }}
                />
                <Button.Basic
                  primary
                  style={{ marginLeft: "8px" }}
                  text="Yes"
                  onClick={onConfirmClick}
                />
              </div>
              <BackButton />
            </div>
          )}
          {patientConfirm && (
            <div>
              <PatientInfo />
              <div>
                <BackButton />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      {create && patientIdent && (
        <div>
          {!userInfo.pre_approval_required ? (
            <React.Fragment>
              <PatientForm
                practiceId={practiceId}
                patientIdent={patientIdent}
                onSuccess={onSuccess}
              />
              <BackButton />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <PatientStager patientIdent={patientIdent} />
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}
