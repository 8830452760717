import React, { useState, useEffect } from "react";
import axios from "axios";
import { Icon } from "semantic-ui-react";
import { Card } from "@components/shared";

export default function ManagerInfo({
	managerLink,
}) {
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  return (
    <React.Fragment>
      <h2>Manager</h2>
      {managerLink ? (
        <Card.Wrapper key="manager">
          <Card.Meta
            title={`${managerLink.manager.full_name}`}
            stats={[
              { title: "Manger Points %", value: `${managerLink.manager_points_percentage}%` },
            ]}
            hasActions={false}
          />
        </Card.Wrapper>
      ) : (
        <div><p>No manager is assigned to this representative.</p></div>
      )}
    </React.Fragment>
  );
}

// function populateJobBreakdown(d = {}) {
//   return {
//     name: d.name || "",
//     leapJobId: d.leap_job_id || "",
//     jobTypeFormatted: d.job_type_formatted,
//     jobClassificationFormatted: d.job_classification_formatted,
//     paidLead: d.paid_lead,
//     noBonus: d.no_bonus,
//     invoiceNumber: d.invoice_number || "",
//     totalCostFormatted: d.total_cost_formatted || "",
//     jobTotalFormatted: d.job_total_formatted || "",
//     squares: d.squares || "",
//     paid: d.paid,
//     datePaidFormatted: d.date_paid_formatted || "",
//     postedToAch: d.posted_to_ach,
//     achReference: d.ach_reference || "",
//     status: d.status,
//     notes: d.notes || "",
//     jobPaymentLinks: d.job_payment_links || [],
//     jobReimbursementLinks: d.job_reimbursement_links || [],
//     contractorJobLinks: d.contractor_job_links || [],
//   };
// }
