import React, { useState } from "react";
import { RepresentativePaymentForm, Table } from "@components/RepresentativePayment";
import { EditModal } from "@components/RepresentativeJobSelection";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

export default function RepresentativePaymentsIndex() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Commission Payments"
      >
        <Table representativeView={false} />
      </Wrapped>
      
    </div>
  );
}
