import React from "react";
import { FaUser } from "react-icons/fa";

export default function UserIcon({ size }) {
  return <FaUser size={size} />;
}

UserIcon.defaultProps = {
  size: 18,
};
