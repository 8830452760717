import React, { useState } from "react";
import { useSelector } from "react-redux";

import { JobCreate, Table } from "@components/Job";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

import { getRoleInfo } from "@util/roleInfo";

export default function JobsIndex({ history }) {
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <div>
      <Wrapped
        title="Jobs"
        button={
          roleInfo.user_doctor || roleInfo.user_proxy ? (
            <Button.Toggle
              closedText="Add Jobs"
              onClick={() => setOpen(!isOpen)}
              open={isOpen}
            />
          ) : null
        }
      >
        {isOpen ? (
          <JobCreate
            onSuccess={(newPatientId) => {
              setOpen(false);
              history.push(`/jobs/${newJobId}`);
            }}
          />
        ) : (
          <Table />
        )}
      </Wrapped>
    </div>
  );
}
