import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import styled from "styled-components";

const Text = styled.p`
  display: flex;
  align-items: center;

  svg {
    color: var(--positiveColor);
    margin-right: 4px;
  }
`;

export default function Success({ text }) {
  return (
    <Text>
      <FaCheckCircle /> {text}
    </Text>
  );
}

Success.defaultProps = {
  text: "Success!",
};
