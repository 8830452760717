import React, { useState } from "react";
import { useSelector } from "react-redux";

import { RepresentativeTable } from "@components/Job";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

import { getRoleInfo } from "@util/roleInfo";

export default function AssignedJobsIndex({ history }) {
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <div>
      <Wrapped
        title="Jobs"
      >
        <RepresentativeTable representativeView={false} />
      </Wrapped>
      
    </div>
  );
}
