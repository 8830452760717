import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";

export default function RemoveIcon(props) {
  return <FaRegTrashAlt size={props.size} {...props} />;
}

RemoveIcon.defaultProps = {
  size: 18,
};
