import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Modal } from "semantic-ui-react";
import { Wrapped } from "@components/Layouts";
import { AdminForm } from "@components/Admin";
import { ChangePassword } from "@components/Profile";
import { Button } from "@components/shared";

export default function Profile() {
  const [profile, setProfile] = useState(populateProfile());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/admin-profile/`)
      .then(({ data }) => {
        setProfile(populateProfile(data));
        setLoading(false);
      })
      .catch((err) => {});
  }

  return (
    <React.Fragment>
      <Wrapped title="Profile">
        <AdminForm
          id={profile.id}
          email={profile.email}
          phone={profile.phone}
          prefix={profile.prefix}
          firstName={profile.firstName}
          lastName={profile.lastName}
          suffix={profile.suffix}
          isProfile
          onSuccess={fetchData}
        />
      </Wrapped>
      <Wrapped>
        <ChangePw id={profile.id} />
      </Wrapped>
    </React.Fragment>
  );
}

function populateProfile(d) {
  let data = { ...d };
  return {
    id: data.id,
    prefix: data.prefix || "",
    firstName: data.first_name || "",
    lastName: data.last_name || "",
    suffix: data.suffix || "",
    email: data.email || "",
    phone: data.phone || "",
    status: data.status,
    access: data.access,
  };
}

const ChangePw = ({ id }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Button.Basic text="Change Password" onClick={() => setOpen(true)} />
      <Modal open={isOpen} onClose={onClose} size="tiny">
        <Modal.Content>
          <ChangePassword
            adminId={id}
            onSuccess={() => {
              toast.success("Password updated!");
              onClose();
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button.Basic text="Close" onClick={onClose} />
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};
