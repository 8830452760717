import React, { useState } from "react";
import { Table } from "@components/UnpaidRepresentative";
import { AchTable } from "@components/AchPayment";
import { EditModal } from "@components/RepresentativeJobSelection";
import { Wrapped } from "@components/Layouts";
import { Button, PageTitle, ViewError } from "@components/shared";
import { Switch, Route } from "react-router-dom";

export default function UnpaidRepresentativesIndex({ isAdmin, match, history }) {

  return (
<div>
  <PageTitle
    title="ACH"
    subtitle="ACH Banking Transactions"
    links={[
      { text: "Queue", to: match.url },
      { text: "Payments", to: `${match.url}/payments` },
    ]
      .filter((f) => (!isAdmin ? !f.admin : true))
      .map((l, i) => ({ ...l, key: i + 1 }))}
  />
  <Switch>
    <Route path={match.path} exact>
      <React.Fragment>
    <div>
      <Wrapped
        title="ACH Queue"
      >
        <Table representativeView={false} />
      </Wrapped>
    </div>
      </React.Fragment>
    </Route>
    <Route path={`${match.path}/payments`}>
      <Wrapped>
          <AchTable representativeView={false} />
      </Wrapped>
    </Route>
  </Switch>
</div>
  );
}
