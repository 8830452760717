import React from "react";
import styled from "styled-components";
import { cssVar } from "polished";

import logo from "@assets/images/roofing-done-wright-logo.png";

import { device } from "@util/breakpoints";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
  }

  h1 {
    font-size: 3.5em;
    margin-bottom: 0.65em;
    color: ${() => cssVar("--primaryColorDark")};
    line-height: 1.05;
  }

  p {
    font-size: 1.25em;
  }

  .auth-left {
    width: 100%;
    background: #f1f1f1;
    display: flex;
    // justify-content: center;
    align-items: center;
    /* order: 1; */
    padding: 20px;

    @media ${device.laptop} {
      width: 40%;
      padding: 0;
    }
  }

  .wrapper {
    width: 95%;
    margin: 0 auto;

    @media ${device.laptop} {
      width: 85%;
    }
  }

  .auth-right {
    background: ${() => cssVar("--navBackgroundColor")};
    height: auto;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* order: 0; */

    @media ${device.laptop} {
      width: 60%;
      height: 100%;
    }

    img {
      width: 350px;
    }
  }

  .auth-form {
    input {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid #eee;
      display: block;
      width: 100%;
      outline: none;
      padding: 10px 10px;
      font-size: 1.25em;
      color: #000;

      &:focus {
        border-color: ${() => cssVar("--primaryColor")};
        transition: all 0.2s ease-in;
      }

      &:not(:first-of-type) {
        margin-top: 20px;
      }
    }

    button {
      margin-top: 1em;
    }
  }

  .auth-other {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .form-group {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    a {
      color: ${() => cssVar("--primaryTextColor")};

      + a {
        margin-left: 20px;
      }
    }

    input {
      margin-right: 5px;
    }
  }
`;

export default function AuthWrapper(props) {
  return (
    <Wrapper>
      <div className="auth-left">
        <div className="wrapper">{props.children}</div>
      </div>
      <div className="auth-right">
        <img src={logo} alt="Roofing Done Wright Payroll App" />
      </div>
    </Wrapper>
  );
}
