import React, { useState, useEffect } from "react";
import axios from "axios";
import { Label, Icon, Modal, Form } from "semantic-ui-react";
import { Button } from "@components/shared";
import Invoices from "./Invoices";
import { RepresentativeSelect } from "@components/Representative";
import { MemberCard, Tidbit, Text } from "@components/shared";
import { RepresentativeReimbursementForm } from "@components/RepresentativeReimbursement";

const STEP_REPRESENTATIVE = "representative";
const STEP_INVOICES = "invoices";
const STEP_PAYMENT = "payment";

export default function EditModal({ id, editable, representative, onUpdate, onSuccess, onClose }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRepresentativeId, setSelectedRepresentativeId] = useState(null);
  const [activeStep, setActiveStep] = useState(STEP_REPRESENTATIVE);
	const [selectedJobReimbursementLinks, setSelectedJobReimbursementLinks] = useState([]);
	const [unpaidJobReimbursementLinks, setUnpaidJobReimbursementLinks] = useState([]);
  
  const [statusValue, setStatusValue] = useState(null);
  const [error, setError] = useState(null);
  const [altFlow, setAltFlow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    // fetchData();
    setError(null);
    setShowDelete(false);
		onRepresentativeProvided();
  }, [isOpen]);
	
	function onRepresentativeProvided() {
	  if (representative) {
			setOpen(true);
	    setSelectedRepresentativeId(representative);
	    setActiveStep(STEP_INVOICES);
	  }
	}

  function onRepresentativeSelect() {
    setActiveStep(STEP_INVOICES);
  }
  
  function onRepresentativeClear() {
    setActiveStep(STEP_REPRESENTATIVE);
    setSelectedRepresentativeId(null);
  }


  return (
    <div>
			{!representative && (
			  <Button.Toggle
			    closedText="Select Representative and Invoices"
			    onClick={() => setOpen(!isOpen)}
			    open={isOpen}
			  />
			)}
      <Modal open={isOpen} onClose={() => setOpen(false)} size="fullscreen">
        <Modal.Header style={{ display: "flex", alignItems: "center" }}>
          Representative Reimbursements
          {selectedRepresentativeId && (
            <React.Fragment>
              {" for "}
              {selectedRepresentativeId.representative.name}
              <Button.Clear
                person
                onClick={onRepresentativeClear}
                style={{ margin: "0 0 10px 0" }}
              />
            </React.Fragment>
          )}
        </Modal.Header>
        <Modal.Content>
          {activeStep === STEP_REPRESENTATIVE && (
            <React.Fragment>
              <div>
                <RepresentativeSelect onChange={(e) => setSelectedRepresentativeId(e)} />
              </div>
              <div>
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={(!selectedRepresentativeId)}
                  onClick={onRepresentativeSelect}
                  style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_INVOICES && (
            <React.Fragment>
              <div>
            		<Invoices
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedJobReimbursementLinks={selectedJobReimbursementLinks}
			           setSelectedJobReimbursementLinks={setSelectedJobReimbursementLinks}
								 unpaidJobReimbursementLinks={unpaidJobReimbursementLinks}
								 setUnpaidJobReimbursementLinks={setUnpaidJobReimbursementLinks}
								  />
              </div>
              <div>
					 			{!representative && (
	                <Button.Basic
	                  text="Back"
	                  onClick={() => setActiveStep(STEP_REPRESENTATIVE)}
	                  style={{ margin: "10px 10px 0 0" }}
	                />
					 			)}
                <Button.Basic
                  primary
                  text="Next Step"
                  disabled={!selectedRepresentativeId || selectedJobReimbursementLinks.length === 0}
                  onClick={() => setActiveStep(STEP_PAYMENT)}
									style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
          {activeStep === STEP_PAYMENT && (
            <React.Fragment>
              <div>
								<RepresentativeReimbursementForm
								 representativeId={selectedRepresentativeId.representative.value}
								 selectedJobReimbursementLinks={selectedJobReimbursementLinks}
								 unpaidJobReimbursementLinks={unpaidJobReimbursementLinks}
								 onSuccess={onSuccess}
								 />
                <Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_INVOICES)}
                  style={{ margin: "10px 10px 10px 0" }}
                />
              </div>
            </React.Fragment>
          )}
					{representative && (
		        <Button.Basic
		          text="Close"
		          onClick={onClose}
		          style={{ margin: "10px 10px 10px 0" }}
		        />
					)}
        </Modal.Content>
      </Modal>
    </div>
  );
}

EditModal.defaultProps = {
  editable: false,
};