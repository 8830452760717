import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector, connect } from "react-redux";
import * as Yup from "yup";
import { BasicForm, Button } from "@components/shared";
import { RepresentativeSelect } from "@components/Representative";
import { JobSelect } from "@components/Job";
import { getRoleInfo } from "@util/roleInfo";

const STEP_CONTRACTOR = "contractor";
const STEP_JOB = "job";
const STEP_ENTRY = "entry";

function RepresentativeJobForm({
  id,
  invoiceType,
  invoiceNumber,
  invoiceDate,
  amountFormatted,
  notes,
  onFormSubmit,
  onSuccess,
  dispatch,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(id ? STEP_ENTRY : STEP_CONTRACTOR);
  const [selectedContractorId, setSelectedContractorId] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const roleInfo = getRoleInfo(useSelector((state) => state));


  function onContractorSelect() {
    setActiveStep(STEP_JOB);
  }
  
  function onContractorClear() {
    setActiveStep(STEP_CONTRACTOR);
    setSelectedContractorId(null);
  }
  
  function onJobSelect() {
    setActiveStep(STEP_ENTRY);
  }
  
  function onJobClear() {
    setActiveStep(STEP_JOB);
    setSelectedJobId(null);
  }

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      contractor_id: selectedContractorId.contractor.value,
      job_id: selectedJobId.job.value,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/contractor-job-links/`, req)
      .then(({ data }) => {
        return data.id;
      })
      .then(() => setLoading(false))
      .then(() => {
        toast.success("Contractor Invoice Created!");
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/contractor-job-links/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Contractor Invoice Updated!"))
      .then((newId) => {
        onSuccess(newId);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }
  
  const statusOptions = [
    { text: "Pending", value: 0 },
    { text: "Complete", value: 1 },
  ];
  
  const invoiceTypeOptions = [
    { text: "Materials", value: 0 },
    { text: "Labor", value: 1 },
    { text: "Warrantee", value: 2 },
    { text: "Project Mangement", value: 3 },
    { text: "Marketing", value: 4 },
    { text: "Miscellaneous", value: 5 },
  ];

  const fields = [
    {
      name: "invoice_type",
      label: "Invoice Type",
      initialValue: invoiceType,
      select: true,
      options: invoiceTypeOptions,
      required: true,
      schema: () => Yup.number().nullable().required("Required"),
    },
    {
      name: "invoice_number",
      initialValue: invoiceNumber,
      label: "Invoice",
      required: false,
      schema: () => Yup.string().max(10, "Too Long!"),
    },
    {
      name: "invoice_date",
      initialValue: invoiceDate
            ? new Date(invoiceDate)
            : null,
      label: "Invoice Date (optional)",
      datepicker: true,
      required: false,
      schema: () =>
        Yup.string().nullable(),
    },
    {
      name: "amount_formatted",
      initialValue: amountFormatted,
      label: "Amount",
      required: true,
      schema: () => Yup.number().typeError('Amount must be a numbers only.').positive("Negative numbers not allowed").required("Required"),
    },
    {
      name: "notes",
      initialValue: notes,
      label: "Notes",
      required: false,
      schema: () => Yup.string().max(50, "Too Long!"),
    },
  ]
    .map((m) => ({
      ...m,
      readOnly: id && roleInfo.admin_view_only ? true : false,
    }));

    return (
      <div>
        {!id && activeStep === STEP_CONTRACTOR && (
          <React.Fragment>
            {selectedContractorId && (
              <div style={{ display: "flex", marginBottom: "10px" }}>
                  <strong>Contractor: {selectedContractorId.contractor.name}</strong> 
                  <Button.Clear
                    person
                    onClick={onContractorClear}
                  />
              </div>
                )}
            <div>
              <ContractorSelect onChange={(e) => setSelectedContractorId(e)} />
            </div>
            <div>
              <Button.Basic
                primary
                text="Next Step"
                disabled={!selectedContractorId}
                onClick={onContractorSelect}
                style={{ margin: "10px 10px 10px 0" }}
              />
            </div>
          </React.Fragment>
        )}
        {!id && activeStep === STEP_JOB && (
          <React.Fragment>
            {selectedContractorId && (
              <div style={{ display: "flex", marginBottom: "10px" }}>
                  <strong>Contractor: {selectedContractorId.contractor.name}</strong> 
                  <Button.Clear
                    person
                    onClick={onContractorClear}
                  />
              </div>
                )}
            {selectedJobId && (
              <div style={{ display: "flex", marginBottom: "10px" }}>
                  <strong>Job: {selectedJobId.job.name}</strong> 
                  <Button.Clear
                    person
                    onClick={onJobClear}
                  />
              </div>
                )}
            <div>
              <JobSelect onChange={(e) => setSelectedJobId(e)} />
            </div>
            <div>
              <Button.Basic
                primary
                text="Next Step"
                disabled={!selectedJobId}
                onClick={onJobSelect}
                style={{ margin: "10px 10px 10px 0" }}
              />
            </div>
          </React.Fragment>
        )}
        {activeStep === STEP_ENTRY && (
          <React.Fragment>
            <div>
              {selectedContractorId && (
                <div style={{ display: "flex", marginBottom: "10px" }}>
                    <strong>Contractor: {selectedContractorId.contractor.name}</strong> 
                    <Button.Clear
                      person
                      onClick={onContractorClear}
                    />
                </div>
                  )}
              {selectedJobId && (
                <div style={{ display: "flex", marginBottom: "10px" }}>
                    <strong>Job: {selectedJobId.job.name}</strong> 
                    <Button.Clear
                      person
                      onClick={onJobClear}
                    />
                </div>
                )}
                {!id && (
                  <Button.Basic
                  text="Back"
                  onClick={() => setActiveStep(STEP_CONTRACTOR)}
                  style={{ margin: "10px 10px 10px 0" }}
                  />
                )}
              <BasicForm
                error={error}
                fields={fields}
                loading={isLoading}
                onSubmit={onSubmit}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

function mapStateToProps(state) {
  return {
    contractorId: state.contractor ? state.contractor.id : null,
  };
}

export default connect(mapStateToProps)(RepresentativeJobForm);

RepresentativeJobForm.defaultProps = {
  onSuccess: () => alert("On Success"),
  id: null,
  invoiceType: null,
  invoiceNumber: "",
  invoiceDate: null,
  amountFormatted: "",
};
