import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { RepresentativeSalaryPaymentForm } from "@components/RepresentativeSalaryPayment";

export default function RepresentativeSalaryPaymentsDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [representativeSalaryPayment, setRepresentativeSalaryPayment] = useState(populateRepresentativeSalaryPayment());

  const representativeSalaryPaymentId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/representative-salary-payments/${representativeSalaryPaymentId}/`)
      .then(({ data }) => {
        setLoading(false);
        setRepresentativeSalaryPayment(populateRepresentativeSalaryPayment(data));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!representativeSalaryPayment) return null;

  return (
    <div>
      <PageTitle
        title={`${representativeSalaryPayment.representative.full_name}`}
        subtitle="Representative Salary Payment"
      />
      <Wrapped>
				<p>
					<Link to="/salary-payments"><strong>Saleries</strong></Link>
        </p>
        <RepresentativeSalaryPaymentForm
          id={representativeSalaryPaymentId}
          amountFormatted={representativeSalaryPayment.amountFormatted}
          paid={representativeSalaryPayment.paid}
          date={representativeSalaryPayment.date}
					periodBegin={representativeSalaryPayment.periodBegin}
					periodEnd={representativeSalaryPayment.periodEnd}
          postedToAch={representativeSalaryPayment.postedToAch}
          achReference={representativeSalaryPayment.achReference}
          notes={representativeSalaryPayment.notes}
          onSuccess={fetchData}
        />
      </Wrapped>
    </div>
  );
}

function populateRepresentativeSalaryPayment(d = {}) {
  return {
    representative: d.representative || "",
    amountFormatted: d.amount_formatted || "",
    date: d.date || "",
		periodBegin: d.period_begin || "",
		periodEnd: d.period_end || "",
    paid: d.paid,
    postedToAch: d.posted_to_ach,
    achReference: d.ach_reference || "",
    notes: d.notes || "",
  };
}
