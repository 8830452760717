import React from "react";
import styled from "styled-components";
import { cssVar, lighten } from "polished";
import { FiInbox } from "react-icons/fi";

const Wrapper = styled.div`
  background: ${() => lighten(0.01, "#eee")};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  flex-direction: column;
  border: ${`2px solid ${lighten(0.91, "#000")}`};

  p {
    /* color: #eee; */
    font-weight: 600;
    font-size: 1.45em;
  }

  svg {
    font-size: 46px;
    margin-bottom: 5px;
    color: ${() => cssVar("--primaryColorLight")};
  }
`;

export default function EmptyList({ text, children }) {
  return (
    <Wrapper>
      <FiInbox />
      <p>{text}</p>
      {children && <div>{children}</div>}
    </Wrapper>
  );
}

EmptyList.defaultProps = {
  text: "Empty",
};
