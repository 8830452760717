import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { BasicForm } from "@components/shared";
import countryOptions from "@util/countryOptions";
import { phoneValidation } from "@util/validation/phone";

export default function RepresentativeProfileForm({
  id,
  siteId,
  prefix,
  firstName,
  lastName,
  suffix,
	companyName,
  email,
  phone,
  addressOne,
  addressTwo,
  city,
  state,
  zipcode,
  overridePercentage,
  paidLeadPercentage,
  globalCommissionRatio,
  manager,
  managerPointsPercentage,
  salary,
  weeklySalaryFormatted,
  notes,
  status,
  onSuccess,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const statusOptions = [
    { text: "Active", value: 0 },
    { text: "Inactive", value: 1 },
    { text: "Removed", value: 2 },
  ];

  const globalCommissionRatioOptions = [
    { text: "Calculated By Job", value: 0 },
    { text: "60/40 Fixed For All Jobs", value: 1 },
    { text: "50/50 Fixed For All Jobs", value: 2 },
  ];

  function onSubmit(formData) {
    if (id) return onUpdate(formData);
  }

  function onUpdate(formData) {
    const postData = {
      ...formData,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/representatives/${id}/`, postData)
      .then(() => setLoading(false))
      .then(() => toast.success("Representative Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err.ui_message);
      });
  }

  const fields = [
    {
      grouped: true,
      fields: [
        {
          name: "prefix",
          label: "Prefix",
          initialValue: prefix,
          required: false,
          schema: () => Yup.string().min(2, "Too Short!").max(4, "Too Long!"),
        },
        {
          name: "first_name",
          label: "First Name",
          initialValue: firstName,
          required: true,
          schema: () => Yup.string().required("Required"),
        },
        {
          name: "last_name",
          label: "Last Name",
          initialValue: lastName,
          required: true,
          schema: () => Yup.string().required("Required"),
        },
        {
          name: "suffix",
          label: "Suffix",
          initialValue: suffix,
          required: false,
          schema: () => Yup.string().min(2, "Too Short!").max(10, "Too Long!"),
        },
      ],
    },
    {
      name: "company_name",
      label: "Company Name",
      initialValue: companyName,
      required: false,
      schema: () => Yup.string(),
    },
    {
      name: "phone",
      label: "Phone",
      initialValue: phone,
      required: true,
      schema: () =>
        Yup.string()
          .matches(phoneValidation, "Not a valid phone")
          .required("Required"),
    },
    {
      label: "Address Line 1",
      initialValue: addressOne,
      name: "address_1",
      required: false,
      schema: () =>
        Yup.string()
          .min(2, "Too Short!")
          .max(120, "Too Long!"),
    },
    {
      label: "Address Line 2",
      initialValue: addressTwo,
      name: "address_2",
      required: false,
      schema: () => Yup.string().min(2, "Too Short!").max(100, "Too Long!"),
    },
    {
      grouped: true,
      fields: [
        {
          label: "City",
          initialValue: city,
          name: "city",
          required: false,
          schema: () =>
            Yup.string()
              .min(2, "Too Short!")
              .max(60, "Too Long!"),
        },
        {
          label: "State",
          initialValue: state,
          name: "state",
          required: false,
          schema: () =>
            Yup.string()
              .min(2, "Too Short!")
              .max(50, "Too Long!"),        },
        {
          label: "Zip Code",
          initialValue: zipcode,
          name: "zipcode",
          required: false,
          schema: () =>
            Yup.string()
              .min(2, "Too Short!")
              .max(12, "Too Long!"),
        },
      ],
    },
  ];

  return (
    <div>
      <BasicForm
        error={error}
        fields={fields}
        loading={isLoading}
        onSubmit={onSubmit}
      />
    </div>
  );
}

RepresentativeProfileForm.defaultProps = {
  city: "",
  id: null,
  phone: "",
  prefix: "",
  firstName: "",
  lastName: "",
  suffix: "",
	companyName: "",
  addressOne: "",
  addressTwo: "",
  city: "",
  state: "",
  zipcode: "",
  paidLeadPercentage: "",
  globalCommissionRatio: 0,
  manager: null,
  managerPointsPercentage: 0,
  overridePercentage: "",
  salary: null,
  weeklySalaryFormatted: 0,
  onSuccess: () => alert("On Success"),
};
