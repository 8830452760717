import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Wrapped } from "@components/Layouts";

import { BasicGrid, Card, Button, EmptyList } from "@components/shared";
import { JobRepresentativeForm, Table } from "@components/JobRepresentative";

import { getRoleInfo } from "@util/roleInfo";

export default function JobRepresentativeManagement({
  jobId,
  jobName,
	jobType,
	jobTypeName,
	jobPaymentLinks,
  onUpdate,
}) {
  const [loading, setLoading] = useState(false);
	const [isOpen, setOpen] = useState(false);
  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <Wrapped
      title="Job Representatives"
      button={
        !roleInfo.admin_view_only && (
          <Button.Toggle
            closedText="Job Representatives"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        )
      }
    >
      {isOpen ? (
        <JobRepresentativeForm
          jobId={jobId}
          jobName={jobName}
					jobType={jobType}
					jobTypeName={jobTypeName}
          onSuccess={() => setOpen(false)}
        />
      ) : (
        <Table jobId={jobId} />
      )}
    </Wrapped>
  );
}

JobRepresentativeManagement.defaultProps = {
  invoices: [],
};
