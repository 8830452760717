import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled(Link)`
  color: var(--primaryColor);
`;

export default function LinkButton({ to, text, target }) {
  return (
    <Button to={to} target={target}>
      {text}
    </Button>
  );
}

LinkButton.defaultProps = {
  target: "self",
  text: "Link",
  to: "/",
};
