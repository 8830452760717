import React, { useState } from "react";
import { AdminForm, Table } from "@components/Admin";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

export default function AdminIndex() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Admins"
        button={
          <Button.Toggle
            closedText="Add Admin"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        }
      >
        {isOpen ? <AdminForm onSuccess={() => setOpen(false)} /> : <Table />}
      </Wrapped>
    </div>
  );
}
