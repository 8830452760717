import React from "react";
import { Form } from "semantic-ui-react";

export const reportTypes = {
  demographics: 1,
  glaucoma_diagnosis: 2,
  previous_ocular_surgery: 3,
  procedure_breakdown: 4,
  post_op_complications: 5,
  mean_iop_and_meds: 6,
  statistical_overview: 7,
};

const reportTypeArray = [
  { text: "Patient Demographics", value: reportTypes.demographics },
  { text: "Glaucoma Diagnosis", value: reportTypes.glaucoma_diagnosis },
  {
    text: "Previous Ocular Surgery",
    value: reportTypes.previous_ocular_surgery,
  },
  { text: "Procedure Breakdown", value: reportTypes.procedure_breakdown },
  { text: "Post-Op Complications", value: reportTypes.post_op_complications },
  { text: "Mean IOP & Meds", value: reportTypes.mean_iop_and_meds },
  { text: "Statistical Overview", value: reportTypes.statistical_overview },
].map((m) => ({ ...m, key: m.value }));

export default function ReportSelect({ onChange, value }) {
  return (
    <Form>
      <Form.Select
        placeholder="Select one..."
        search
        options={reportTypeArray}
        onChange={(e, d) => {
          if (value === d.value) return;

          onChange(d.value);
        }}
        value={value}
      />
    </Form>
  );
}
