import React from 'react';
import PropTypes from 'prop-types';
import {
  Message,
} from 'semantic-ui-react';

export const ReqResponseMessage = (props) => {
  return (
    <Message
      content={props.content}
      error={!props.success}
      header={props.header}
      icon={props.success ? 'check' : 'exclamation'}
      positive={props.success}
    />
  );
}

ReqResponseMessage.defaultProps = {
  content: 'Try again later',
  header: 'There was a problem',
}

ReqResponseMessage.propTypes = {
  content: PropTypes.string,
  header: PropTypes.string,
  success: PropTypes.bool.isRequired,
}
