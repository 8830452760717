import React from "react";
import styled from "styled-components";

import { size, device } from "@util/breakpoints";

const Grid = styled.div`
  display: grid;
  gap: ${(props) => props.gap};
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  align-items: start;

  & + & {
    margin-top: 1.5em;
  }

  @media (max-width: ${size.tablet}) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
    grid-template-columns: ${(props) =>
      `repeat(${props.columns >= 2 ? 2 : 1}, 1fr)`};
  }
`;

export default function BasicGrid({ children, columns, gap, style }) {
  if (!children) return null;
  return (
    <Grid gap={gap} columns={columns} style={{ ...style }}>
      {children}
    </Grid>
  );
}

BasicGrid.defaultProps = {
  columns: 3,
  gap: "15px",
  style: {},
};
