import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { BasicGrid, Card, Button, EmptyList } from "@components/shared";
import { ContractorPaymentForm, Table } from "@components/ContractorPayment";

import { getRoleInfo } from "@util/roleInfo";

export default function ContractorPaymentManagement({
  contractorId,
  onUpdate,
  contractorView,
}) {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchPayments();
  }, []);

  function fetchPayments() {
    const req = {
      filtered: [],
      page: 0,
      page_size: 500,
      sorted: [],
      status: 1,
      contractor_id: contractorId,
    };

    axios
      .post(`/contractor-payments/`, req)
      .then(({ data }) => {
        setPayments(data.data);
      })
      .catch((err) => {
        setLoading(false);
        setPayments([]);
      });
  }

  return (
    <div>
      <Table contractorView={true} />
    </div>
  );
}

ContractorPaymentManagement.defaultProps = {
  payments: [],
};
