import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function PieChart({ data }) {
  return <Pie options={options} data={data} />;
}

const options = {
  responsive: true,
  cutoutPercentage: 30,
  type: "doughnut",
  // maintainAspectRatio: false,
  aspectRatio: 2,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};
