import React, { useState } from "react";
import { connect, useSelector } from "react-redux";

import { ContractorForm, Table } from "@components/Contractor";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

import { getRoleInfo } from "@util/roleInfo";

function ContractorManagement({ practiceId, sessionPracticeId }) {
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <Wrapped
      title="Contractors"
      button={
        !roleInfo.admin_view_only && (
          <Button.Toggle
            closedText="Add Contractors"
            onClick={() => setOpen(!isOpen)}
            open={isOpen}
          />
        )
      }
    >
      {isOpen ? (
        <ContractorForm
          onSuccess={() => setOpen(false)}
        />
      ) : (
        <Table />
      )}
    </Wrapped>
  );
}

ContractorManagement.defaultProps = {
  practiceId: null,
};

function mapStateToProps(state) {
  return {
    sessionPracticeId: state.user ? state.user.session_practice_id : null,
  };
}

export default connect(mapStateToProps)(ContractorManagement);
