import React, { useState } from "react";
import { ContractorPaymentForm, Table } from "@components/ContractorPayment";
import { EditModal } from "@components/ContractorInvoiceSelection";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

export default function ContractorPaymentsIndex() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Contractor Payments"
        button={
          <EditModal />
        }
      >
        {isOpen ? <ContractorPaymentForm onSuccess={() => setOpen(false)} /> : <Table />}
      </Wrapped>
      
    </div>
  );
}
