import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { ContractorForm, ContractorInvoiceManagement, ContractorPaymentManagement } from "@components/Contractor";

export default function ContractorDetail({ match, isAdmin }) {
  const [contractor, setContractor] = useState(populateContractor());
  const [error, setError] = useState(null);
  const id = match.params.id;

  useEffect(() => {
    fetchData();
  }, [id]);

  function fetchData() {
    axios
      .get(`/contractors/${id}/`)
      .then(({ data }) => {
        setContractor(populateContractor(data));
      })
      .catch((err) => {
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  return (
    <div>
      <PageTitle
        title={`${contractor.name}`}
        subtitle={"Contractor"}
        links={
          isAdmin
            ? [
                { text: "Detail", to: match.url },
                { text: "Invoices", to: `${match.url}/invoices` },
                { text: "Payments", to: `${match.url}/payments` },
              ]
            : []
        }
      />
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <Wrapped>
              <ContractorForm
                id={id}
                email={contractor.email}
                name={contractor.name}
                phone={contractor.phone}
                addressOne={contractor.addressOne}
                addressTwo={contractor.addressTwo}
                city={contractor.city}
                state={contractor.state}
                zipcode={contractor.zipcode}
                notes={contractor.notes}
                status={contractor.status}
                onSuccess={fetchData}
              />
            </Wrapped>
          </React.Fragment>
        </Route>
        {isAdmin && (
          <Route path={`${match.path}/invoices`}>
            <Wrapped>
              <ContractorInvoiceManagement
                contractorId={id}
                contractorJobs={contractor.jobs}
                onUpdate={fetchData}
              />
            </Wrapped>
          </Route>
        )}
        {isAdmin && (
          <Route path={`${match.path}/payments`}>
            <Wrapped>
              <ContractorPaymentManagement
                contractorId={id}
                contractorPayments={contractor.contractor_payments}
                onUpdate={fetchData}
              />
            </Wrapped>
          </Route>
        )}
      </Switch>
    </div>
  );
}

function populateContractor(d) {
  let data = { ...d };
  return {
    name: data.name || "",
    email: data.email || "",
    phone: data.phone || "",
    addressOne: data.address_1 || "",
    addressTwo: data.address_2 || "",
    city: data.city || "",
    state: data.state || "",
    zipcode: data.zipcode || "",
    status: data.status,
    notes: data.notes,
  };
}
