import React, { useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import axios from "axios";

import { BasicForm } from "@components/shared";
import { phoneValidation } from "@util/validation/phone";

import { statusOptions, accessOptions } from "./status";

function AdminForm({
  dispatch,
  email,
  prefix,
  suffix,
  firstName,
  id,
  isProfile,
  lastName,
  loggedInAdminId,
  mfa,
  onSuccess,
  phone,
  status,
	access,
  title,
  variety,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onSubmit(postData) {
    setLoading(true);
    setError(null);

    if (id) {
      return onUpdate(postData);
    }

    onCreate(postData);
  }

  function onUpdate(formData) {
    const req = { ...formData };

    axios
      .put(`/admins/${id}/`, {
        ...formData,
        admin_id: id,
      })
      .then(({ data }) => {
        setLoading(false);
      })
      .then(() => {
        toast.success("Admin Updated!");
      })
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onCreate(formData) {
    axios
      .post(`/admins/`, formData)
      .then(() => {
        setLoading(false);
      })
      .then(() => {
        toast.success("Admin Created!");
      })
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  const fields = [
    {
      grouped: true,
      fields: [
        {
          name: "prefix",
          label: "Prefix",
          initialValue: prefix,
          required: false,
          schema: () => Yup.string().min(2, "Too Short!").max(10, "Too Long!"),
        },
        {
          name: "first_name",
          label: "First Name",
          initialValue: firstName,
          required: true,
          schema: () => Yup.string().required("Required"),
        },
        {
          name: "last_name",
          label: "Last Name",
          initialValue: lastName,
          required: true,
          schema: () => Yup.string().required("Required"),
        },
        {
          name: "suffix",
          label: "Suffix",
          initialValue: suffix,
          required: false,
          schema: () => Yup.string().min(2, "Too Short!").max(10, "Too Long!"),
        },
      ],
    },
    {
      name: "email",
      label: "Login Email",
      initialValue: email,
      required: true,
      schema: () =>
        Yup.string().email("Not a valid email").required("Required"),
    },
    {
      name: "phone",
      label: "Phone",
      initialValue: phone,
      required: false,
      schema: () =>
        Yup.string()
          .matches(phoneValidation, "Not a valid phone"),
    },
    {
      name: "status",
      label: "Status",
      initialValue: status,
      select: true,
      options: statusOptions,
      required: true,
      schema: () => Yup.number().nullable().required("Required"),
    },
    {
      name: "access",
      label: "Access",
      initialValue: access,
      select: true,
      options: accessOptions,
      required: true,
      schema: () => Yup.number().nullable().required("Required"),
    },
  ]
    .filter((f) =>
      isProfile || loggedInAdminId == id
        ? !["status", "access"].includes(f.name)
        : true
    );

  return (
    <BasicForm
      buttonText="Save"
      fields={fields}
      error={error}
      loading={isLoading}
      onSubmit={onSubmit}
    />
  );
}

AdminForm.defaultProps = {
  id: null,
  email: "",
  prefix: "",
  firstName: "",
  lastName: "",
  suffix: "",
  phone: "",
  isProfile: false,
  status,
  access: null,
};

function mapStateToProps(state) {
  return {
    loggedInAdminId: state.admin.id,
  };
}

export default connect(mapStateToProps)(AdminForm);
