import React, { useState } from "react";

import { Button, FilterSelect, PaginatedTable } from "@components/shared";
import { Icon } from "semantic-ui-react";

export default function ManagedCommissionTable({ representativeView, representativeId }) {
  const [activeFilter, setActiveFilter] = useState(`[0,1]`);
  const [refresh, setRefresh] = useState(false);
  const [updated, setUpdated] = useState(false);

  const columns = [
    {
      Header: "",
      id: "view",
      width: 50,
      accessor: (d) => <Button.View url={`/commissions/${d.id}`} />,
    },
    {
      Header: "Representative",
      accessor: "representative.full_name",
      id: "representative_name",
      sortable: false,
    },
    {
      Header: "Date Paid",
      accessor: "date_paid_formatted",
      id: "date_paid_formatted",
      sortable: true,
      width: 120,
    },
    {
      Header: "Amount",
      accessor: "amount_formatted",
      id: "amount_formatted",
      sortable: true,
      width: 120,
    },
    {
      Header: "Posted to ACH",
      accessor: (d) =>
        d.posted_to_ach ? <Icon color="green" name="check" /> : <Icon color="red" name="x" />,
      id: "posted_to_ach",
      sortable: false,
      width: 125,
    },
    {
      Header: "ACH Reference",
      accessor: "ach_reference",
      id: "ach_reference",
      sortable: false,
      width: 140,
    },
    {
      Header: "Notes",
      accessor: "notes",
      id: "notes",
      sortable: false,
      width: 140,
    },
    {
      Header: "Status",
      accessor: "status_formatted",
      id: "status_formatted",
      sortable: false,
      width: 125,
    },
  ].map((m) => ({
    ...m,
    filterable: false,
  })).filter((f) => 
    representativeView ? !["representative_name"].includes(f.id) : true
  );


  const additionalPostData = {
		representative_id: representativeId,
    status: JSON.parse(activeFilter),
  };

  return (
    <React.Fragment>
      <FilterSelect
        options={statusOptions}
        value={activeFilter}
        onChange={(e) => setActiveFilter(e)}
        entityName="Status"
      />
      <PaginatedTable
        columns={columns}
        additionalPostData={additionalPostData}
        entityName="Representative Payments"
        filter={activeFilter}
        filterOptions={filterOptions}
        keyName="data"
        refresh={refresh}
        url="/retrieve-representative-payments/"
      />
    </React.Fragment>
  );
}

ManagedCommissionTable.defaultProps = {};

const statusOptions = [
	{ text: "All", value: `[0,1]` },
  { text: "Pending", value: 0 },
  { text: "Complete", value: 1 },
];

const filterOptions = [{ name: "name", obj: "self" }];
