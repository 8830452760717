import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { size, device } from "@util/breakpoints";

import { Button, FilterSelect, PaginatedTable } from "@components/shared";

const statusOptions = [
  { text: "Active", value: 0 },
  { text: "Inactive", value: 1 },
  { text: "Removed", value: 2 },
];

const globalCommissionRatioOptions = [
  { text: "Calculated By Job", value: 0 },
  { text: "60/40 Fixed For All Jobs", value: 1 },
  { text: "50/50 Fixed For All Jobs", value: 2 },
];

const columns = [
  {
    Header: "",
    id: "view",
    width: 50,
    accessor: (d) => <Button.View url={`/representatives/${d.id}`} />,
  },
  {
    Header: "First Name",
    accessor: "first_name",
    id: "first_name",
		width: 130,
    sortable: true,
  },
  {
    Header: "Last Name",
    accessor: "last_name",
    id: "last_name",
		width: 130,
    sortable: true,
  },
  {
    Header: "Company",
    accessor: "company_name",
    sortable: true,
  },
  {
    Header: "Phone",
    accessor: "phone",
    id: "phone",
		width: 130,
    sortable: false,
  },
  {
    Header: "Email",
    accessor: "email",
    id: "email",
    sortable: true,
  },
  {
    Header: "Status",
    accessor: "status_formatted",
    id: "status_formatted",
		width: 70,
    sortable: false,
  },
  {
    Header: "Manager",
    accessor: "manager_representative.manager.full_name",
    id: "manager",
    sortable: false,
  },
];

export default function Table() {
  const [activeFilter, setActiveFilter] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  return (
    <React.Fragment>
      <FilterSelect
        options={dropdownOptions}
        value={activeFilter}
        onChange={(e) => setActiveFilter(e)}
        entityName="Representatives"
      />
      <Form style={{ marginBottom: "1em" }}>
        <Form.Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search representatives with above filter setting..."
          width={window.innerWidth >= size.laptop ? 4 : 16}
        />
      </Form>
      <PaginatedTable
        columns={columns}
        additionalPostData={{
          status: JSON.parse(activeFilter),
          value: searchValue.length >= 2 ? searchValue : null,
        }}
        entityName="Representatives"
        filter={activeFilter}
        filterOptions={filterOptions}
        keyName="data"
        url="/retrieve-representatives/"
      />
    </React.Fragment>
  );
}

const dropdownOptions = [
  { text: "All", value: JSON.stringify(statusOptions.map((s) => s.value)) },
  ...statusOptions,
];

const filterOptions = [
  { name: "first_name", obj: "self" },
  { name: "last_name", obj: "self" },
];
