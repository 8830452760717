import React, { useState } from "react";

import { Button, FilterSelect, BasicTable } from "@components/shared";
import { Icon } from "semantic-ui-react";

export default function Table( { managedRepresentatives } ) {
  const [refresh, setRefresh] = useState(false);
  const [updated, setUpdated] = useState(false);

  const columns = [
	  {
	    Header: "First Name",
	    accessor: "first_name",
	    id: "first_name",
			width: 130,
	    sortable: true,
	  },
	  {
	    Header: "Last Name",
	    accessor: "last_name",
	    id: "last_name",
			width: 130,
	    sortable: true,
	  },
	  {
	    Header: "Company",
	    accessor: "company_name",
	    sortable: true,
	  },
	  {
	    Header: "Phone",
	    accessor: "phone",
	    id: "phone",
			width: 130,
	    sortable: false,
	  },
	  {
	    Header: "Email",
	    accessor: "email",
	    id: "email",
	    sortable: true,
	  },
	  {
	    Header: "Status",
	    accessor: "status_formatted",
	    id: "status_formatted",
			width: 70,
	    sortable: false,
	  },
  ].map((m) => ({
    ...m,
    filterable: false,
  }));

  return (
		<React.Fragment>
		  <BasicTable
		    columns={columns}
		    entityName="Managed Representatives"
		    data={managedRepresentatives}
		  />
		</React.Fragment>
  );
}

Table.defaultProps = {};
