import React from "react";
import { BarChart } from "./";
import { getRgbaColors } from "@util/colors";

import { BasicGrid } from "@components/shared";

export default function MeanIopMeds({ data }) {
  if (!data) return null;

  const labels = ["Pre-Op", ...data.post_op_visits.map((m) => m.variety)];

  const iopData = {
    labels,
    datasets: [
      {
        data: [
          data.avg_pre_op_iop,
          ...data.post_op_visits.map((m) => m.average_iop),
        ],
        backgroundColor: getRgbaColors(15),
      },
    ],
  };

  const medData = {
    labels,
    datasets: [
      {
        data: [
          data.avg_pre_op_meds,
          ...data.post_op_visits.map((m) => m.average_meds),
        ],
        backgroundColor: getRgbaColors(15),
      },
    ],
  };

  return (
    <div>
      <BasicGrid columns={2}>
        <div>
          <h4>IOP</h4>
          <BarChart data={iopData} />
        </div>
        <div>
          <h4>Meds</h4>
          <BarChart data={medData} />
        </div>
      </BasicGrid>
    </div>
  );
}
