import React, { useState } from "react";
import { AchPaymentReport } from "@components/Representative";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";
import { useSelector } from "react-redux";

export default function PaymentsIndex() {
  const [isOpen, setOpen] = useState(false);
	
	const representativeId = useSelector((state) => state).representative.id;

  return (
    <div>
      <Wrapped
        title="Reports"
      >
        <AchPaymentReport representativeId={representativeId} />
      </Wrapped>
      
    </div>
  );
}
